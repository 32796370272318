import React, { useMemo } from 'react'
import { Menu } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import {
  DatabaseOutlined,
  BoxPlotOutlined,
  OrderedListOutlined,
  FundProjectionScreenOutlined,
  EyeOutlined,
  BuildOutlined
} from '@ant-design/icons'
import { sendAmpEvent } from '../utility/analytics'
import { find, isNil } from 'lodash'
import { useAppSelector } from '../state/hooks'

const PATH_TO_KEYS = {
  '/main': 'home',
  '/report': 'report',
  '/monitor': 'monitor',
  '/basestations': 'basestations',
  '/admin': 'admin',
  '/analytics': 'analytics',
}

function Nav ({ logout, isAuthenticated, }: { logout: () => void, isAuthenticated: boolean}) {
  const history = useHistory()
  const location = useLocation()
  const user = useAppSelector(state => state.mainReducer.user || {})
  const selectedKey = useMemo(() => {
    return find(PATH_TO_KEYS, (key, path) => {
      return location.pathname.startsWith(path)
    }) || 'home'
  }, [location.pathname,])

  const handleClick = (e: any) => {
    const { key, } = e
    switch (key) {
      case 'logout':
        logout()
        break
      case 'analytics':
        history.push('/analytics')
        break
      case 'home':
        history.push('/main')
        break
      case 'report':
        history.push('/report')
        break
      case 'monitor':
        history.push('/monitor')
        break
      case 'basestations':
        history.push('/basestations')
        break
      case 'admin':
        history.push('/admin')
        break
      default:
        break
    }
    sendAmpEvent('navbar', { destination: key, })
  }

  if (!isAuthenticated) return null
  if (user.role === 'monitor' || isNil(user.role)) {
    return (
      <Menu onClick={handleClick} selectedKeys={[selectedKey,]} mode="horizontal">
      <Menu.Item key="monitor" icon={<EyeOutlined />}>
        Monitor
      </Menu.Item>
      <Menu.Item style={{ float: 'right', }} key="logout">
        Logout
      </Menu.Item>
    </Menu>
    )
  }

  return (
    <Menu onClick={handleClick} selectedKeys={[selectedKey,]} mode="horizontal">
      <Menu.Item key="home" icon={<OrderedListOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="basestations" icon={<DatabaseOutlined />}>
        Basestations
      </Menu.Item>
      <Menu.Item key="analytics" icon={<BoxPlotOutlined />}>
        Analytics
      </Menu.Item>
      <Menu.Item key="report" icon={<FundProjectionScreenOutlined />}>
        Report
      </Menu.Item>
      <Menu.Item key="monitor" icon={<EyeOutlined />}>
        Monitor
      </Menu.Item>
      <Menu.Item key="admin" icon={<BuildOutlined />}>
        Admin
      </Menu.Item>
      <Menu.Item style={{ float: 'right', }} key="logout">
        Logout
      </Menu.Item>
    </Menu>
  )
}

export default Nav
