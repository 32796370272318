import React, { useMemo } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import Loading from '../components/Loading'
import Unauthorized from '../components/Unauthorized'

const ProtectedRoute = ({
  component,
  path,
  otherCheck = true,
}: {
  component: any
  path: string
  otherCheck?: boolean
}) => {
  const { isAuthenticated, isLoading, user, } = useAuth0()

  const hasAccess = ['admin', 'monitor',].includes(
    user?.['https://inspiren/role']
  )
  const isMonitor = useMemo(() => user?.['https://inspiren/role'] === 'monitor', [user,])
  if (isMonitor && !path.includes('monitor')) return <Redirect to="/monitor" />
  if (isLoading) return <Loading type='bars'/>
  if (isAuthenticated && !isLoading && otherCheck && hasAccess) {
    return (
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <div></div>,
        })}
        path={path}
      />
    )
  }

  if (!hasAccess) return <Unauthorized />

  if (!otherCheck) return <Redirect to="/monitor" />

  return <Redirect to="/login" />
}

export default ProtectedRoute
