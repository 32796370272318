
export const mapStateToProps = (state: any) => ({
  orgs: state.mainReducer.orgs || [],
  dates: state.mainReducer.analyticsDates || [],
  report: (state.mainReducer.reportData && state.mainReducer.reportData.notifications) || [],
  roomData: (state.mainReducer.reportData && state.mainReducer.reportData.roomHash) || [],
  loading: state.mainReducer.reportingLoading,
  selectedOrg: state.mainReducer.selectedOrg,
  staff: state.mainReducer.staffAnalytics,
  pagination: state.mainReducer.reportPagination,
  filtering: state.mainReducer.reportFiltering,
  sorting: state.mainReducer.reportSorting,
  timelinePics: state.mainReducer.timelinePics,
  timelineLoading: state.mainReducer.timelineLoading,
  units: state.mainReducer.units,
  reportingQueryError: state.mainReducer.reportingQueryError,
})
