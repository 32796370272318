import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Space, Modal, Input, Menu, Dropdown, Button, Card, Image } from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import OrgSelector from '../../components/OrgSelector'
import { getReport, updateAnalyticsDates, updateOrg, updateNotifDesc, updateTimeline, setReportPagination, setReportFiltering, setReportSorting, fetchUnits } from '../../state/actions'
import { mapStateToProps } from './mapStateToProps'
import { ColumnsType } from 'antd/es/table'
import { CSVLink } from 'react-csv'
import { convertUnixToIso, extractUnitFromRoom } from '../../utility/helpers'
import Highlighter from 'react-highlight-words'
import { UnitExpiration } from '../../components/UnitExpiration'
import DisplayError from '../../components/DisplayError'
const { TextArea, } = Input

function Report ({ store, match, loading, staff, roomData, report, orgs, pagination, filtering, sorting, dates, dispatch, history, selectedOrg, roomHash, timelinePics, timelineLoading, units,reportingQueryError, }: any) {
  const { getAccessTokenSilently, } = useAuth0()
  let searchInput
  // eslint-disable-next-line
  const [searchText, setSearchText,] = useState('')
  const [searchedColumn, setSearchedColumn,] = useState('')
  const [visible, setVisible,] = useState(false)
  const [currentComment, typeComment,] = useState('')

  const [selectedNotif, selectNotif,] = useState<any>(null)

  const [staffEntry, updateStaffEntry,] = useState(null)

  const [modal, toggle,] = useState(false)

  const updateTable = (pagination: any, filters: any, sorters: any) => {
    // dispatch action to set filtering
    dispatch(setReportFiltering({
      name: filters.name,
      type: filters.type,
      resolvedByName: filters.resolvedByName,
      fr: filters.fr,
    }))
    // dispatch action to set sorting
    dispatch(setReportSorting({
      column: sorters.column,
      columnKey: sorters.columnKey,
      field: sorters.field,
      order: sorters.order,
    }))
  }

  const selectDate = (dates: any) => {
    return dispatch(updateAnalyticsDates(dates))
  }

  const submit = () => {
    if (!selectedOrg || !dates) return
    dispatch(getReport({ getToken: getAccessTokenSilently, org: selectedOrg, start: dates[0], end: dates[1], }))
  }

  const selectOrg = (key: string) => {
    return dispatch(updateOrg(key))
  }

  const edit = (notif: any) => {
    selectNotif(notif)
    const comment = (notif && notif.comment) || ''
    const staffEntry = (notif && notif.staff_entry) || ''
    updateStaffEntry(staffEntry)
    typeComment(comment)
    toggle(true)
  }

  const changeComment = (e: any) => {
    const { target, } = e
    const { value, } = target

    return typeComment(value)
  }

  const submitDesc = () => {
    if (currentComment || staffEntry) {
      dispatch(updateNotifDesc({
        getToken: getAccessTokenSilently,
        id: convertUnixToIso(selectedNotif.id),
        room: selectedNotif.room,
        comment: currentComment,
        staff_entry: staffEntry,
      }))
    }
    toggle(false)
  }

  const reviewNotification = async (notif: any) => {
    dispatch(updateTimeline({
      getToken: getAccessTokenSilently,
      notif,
    }))
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true, })
    setSearchText('')
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8, }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value,] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90, }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: text => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0, }} searchWords={[searchText,]} autoEscape textToHighlight={text ? text.toString() : ''} />) : (text),
  })
  const columns: ColumnsType<any> = [
    {
      title: 'Room',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name < b.name ? -1 : 1,
      sortOrder: sorting && sorting.columnKey === 'name' && sorting.order || null,
      filteredValue: filtering && filtering.name || null,
      onFilter: (value, record) => !value ? record.name : record.name.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Unit',
      width: 100,
      dataIndex: 'room',
      key: 'room',
      render: (text) => {
        const { name, } = extractUnitFromRoom(text)
        return (<div>{name}</div>)
      },
    },
    {
      title: 'Event',
      dataIndex: 'type',
      key: 'type',
      width: 125,
      filters: [
        { text: 'Fall', value: 'Fall', },
        { text: 'Leaving Bed', value: 'Leaving Bed', },
        { text: 'Out of Chair', value: 'Out of Chair', },
      ],
      filteredValue: filtering && filtering.type || null,
      onFilter: (value, record) => record.type.includes(value),
    },
    {
      title: 'Occurred',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: m => moment(m).format('MMM D YY, h:mm:ss a'),
      sorter: (a, b) => Date.parse(a.createdAt) < Date.parse(b.createdAt) ? -1 : 1,
      sortOrder: sorting && sorting.columnKey === 'createdAt' && sorting.order || null,
    },
    {
      title: 'Resolved',
      dataIndex: 'resolvedAt',
      key: 'resolvedAt',
      width: 200,
      render: m => moment(m).format('MMM D YY, h:mm:ss a'),
      sorter: (a, b) => {
        if (!a.resolvedAt) {
          return 1
        }
        if (!b.resolvedAt) {
          return -1
        }
        return Date.parse(a.resolvedAt) < Date.parse(b.resolvedAt) ? -1 : 1
      },
      sortOrder: sorting && sorting.columnKey === 'resolvedAt' && sorting.order || null,
    },
    {
      title: 'Resolved By',
      dataIndex: 'resolvedByName',
      key: 'resolvedByName',
      width: 125,
      filteredValue: filtering && filtering.resolvedByName || null,
      onFilter: (value, record) => !value ? record.resolvedByName : record.resolvedByName.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('resolvedByName'),
    },
    {
      title: 'Promoted On',
      dataIndex: 'promotedOn',
      key: 'promotedOn',
      width: 175,
      render: m => moment(m).format('MMM D YY, h:mm:ss a'),
      sorter: (a, b) => a.promotedOn < b.promotedOn ? -1 : 1,
      sortOrder: sorting && sorting.columnKey === 'promotedOn' && sorting.order || null,
    },
    {
      title: 'TTR (s)',
      dataIndex: 'ttr',
      key: 'ttr',
      width: 75,
      sorter: (a, b) => a.ttr < b.ttr ? -1 : b.ttr < a.ttr ? 1 : 0,
      sortOrder: sorting && sorting.columnKey === 'ttr' && sorting.order || null,
    },
    {
      title: 'TTEB (s)',
      dataIndex: 'tteb',
      key: 'tteb',
      width: 50,
      render: (text, record) => <div>{record.tteb || 'None'}</div>,
      sorter: (a, b, dir) => {
        if (!a.tteb) {
          return dir === 'ascend' ? 1 : -1
        } else if (!b.tteb) {
          return dir === 'ascend' ? -1 : 1
        } else if (a.tteb === b.tteb) {
          return 0
        }
        return a.tteb - b.tteb
      },
      sortOrder: sorting && sorting.columnKey === 'tteb' && sorting.order || null,
    },
    {
      title: 'TTRP (s)',
      dataIndex: 'ttrp',
      key: 'ttrp',
      width: 75,
      sorter: (a, b) => {
        if (!a.ttrp) return 1
        if (!b.ttrp) return -1
        if (a.ttrp < b.ttrp) return -1
        if (b.ttrp < a.ttrp) return 1

        return 0
      },
      sortOrder: sorting && sorting.columnKey === 'ttrp' && sorting.order || null,
    },
    {
      title: 'Fall Risk',
      dataIndex: 'fr',
      key: 'fr',
      width: 100,
      filters: [
        { text: 'high', value: 'high', },
        { text: 'medium', value: 'medium', },
        { text: 'low', value: 'low', },
        { text: 'off', value: 'off', },

      ],
      filteredValue: filtering && filtering.fr || null,
      onFilter: (value, record) => record?.fr?.includes(value),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 200,
      render: (value, record) => record.comment || '',
    },
    {
      title: 'Image',
      dataIndex: 'img',
      key: 'img',
      width: 200,
      render: (value, record) => {
        return <Button disabled={record.disable} loading={timelineLoading} type="primary" onClick={async () => {
          reviewNotification(record)
          setVisible(true)
        } }>show image preview</Button>
      },
    },

    {
      title: '',
      key: 'operation',
      width: 50,
      render: (value, record) => (<div>
          <Button style={{ width: 60, marginBottom: 8, }} onClick={() => edit(record)}>edit</Button>
          <Button disabled={record.disable} style={{ width: 60, }} onClick={() => reviewNotification(record)}><Link to="/main/review">view</Link></Button>
          </div>
      ),
    },
  ]

  const generateCSVExport = (exclude: any) => {
    if (!report || !columns) return
    const relevantFields = columns.filter((c: any) => !exclude.includes(c.title) && c.dataIndex)
    const titles = relevantFields.map((c: any) => c.title)
    const titleProps = relevantFields.map((c: any) => c.dataIndex)

    const data = report.map((n: any) => {
      const arr: any = []
      titleProps.forEach(prop => {
        if (n[prop]) arr.push(n[prop])
        else arr.push('')
      })
      return arr
    })

    return [titles, ...data,]
  }

  const csvData = generateCSVExport(['Image',])

  /**
     * TODO: Revisit generate doc if you want to build out the vector PDF
  */

  // const generateDoc = (include: any) => {
  //   let totalWidth = 0
  //   columns.filter((c: any) => include.includes(c.title)).forEach(c => {
  //     totalWidth += Number(c.width)
  //   })

  //   const header = (
  //   <View style={styles.header}>
  //     {columns.map((col:any) => {
  //     return (
  //       <View style={[styles.tableCol, { width: `${Math.round(col.width / totalWidth * 100)}%` }]}>
  //         <Text style={styles.headerCell}>{col.title}</Text>
  //       </View>
  //     )})}
  //   </View>
  //   )
  //
  //   return (
  //     <Document>
  //       <Page orientation={'landscape'}>
  //       <View style={styles.table}>
  //         {header}
  //         <View style={styles.tableRow}>
  //           <View style={styles.tableCol}>
  //             <Text style={styles.tableCell}>React-PDF</Text>
  //           </View>
  //           <View style={styles.tableCol}>
  //             <Text style={styles.tableCell}>3 User </Text>
  //           </View>
  //           <View style={styles.tableCol}>
  //             <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
  //           </View>
  //           <View style={styles.tableCol}>
  //             <Text style={styles.tableCell}>5€</Text>
  //           </View>
  //         </View>
  //       </View>
  //       </Page>
  //     </Document>
  //   )

  // }

  const staffEntryMenu = (
    <Menu onClick={(e: any) => updateStaffEntry(e.key)}>
      <Menu.Item key="A few seconds">
        A few seconds
      </Menu.Item>
      <Menu.Item key="One minute">
        One minute
      </Menu.Item>
      <Menu.Item key="Three minutes">
        Three minutes
      </Menu.Item>
      <Menu.Item key="Five minutes">
        Five minutes
      </Menu.Item>
      <Menu.Item key="Over Five minutes">
        Over Five minutes
      </Menu.Item>
    </Menu>
  )

  const roomSummary = roomData.map((r: any) => {
    return (
      <div key={r} className='room-grid-container'>
      <Card.Grid className='room-grid-first-item'>{r.room.split('-').pop()}</Card.Grid>
      <Card.Grid className='room-grid-second-item'>{`${r.events} events, average resolution time of ${Math.round(r.avgTTR)} seconds`}</Card.Grid>
      </div>
    )
  })
  // creating a state to handle when the user clicks on the next page
  // just going to change current page
  const handlePageChange = (page,pageSize) => {
    // take in the page and set the current to the page
    // also have to dispatch to update the redux store
    dispatch(setReportPagination({
      current: page,
      pageSize: pageSize,
    }))
  }
  useEffect(() => {
    dispatch(fetchUnits({ getToken: getAccessTokenSilently, }))
  }, [])
  return (
    <div className='Home-container'>

      <Space direction='vertical' size="middle">

      <Space direction='vertical' size={16}>

<OrgSelector
  orgs={orgs}
  selectedOrg={selectedOrg}
  dates={dates}
  selectOrg={selectOrg}
  selectDate={selectDate}
  submit={submit}
/>
{ !loading && !reportingQueryError && report && report.length > 0 && <CSVLink data={csvData || []} filename={'AUGi-report.csv'}>Export CSV</CSVLink> }
</Space>

        { loading
          ? <Loading type='bars'/>
          : !report
              ? null
              : reportingQueryError
                ? <DisplayError />
                : <>

            <div style={{ width: '50%', margin: 'auto', padding: 20, borderWidth: '1px', borderColor: 'black', }}>
            <Card title="Summary" >
              {roomSummary}
            </Card>
          </div>
            <div style={{ padding: 20, pageBreakInside: 'avoid', }}>
            <Space direction="vertical" size={16}>
            {units ? <div><UnitExpiration units={units} selectedOrg={selectedOrg} /></div> : null}
              <Table columns={columns} dataSource={report || []} onChange={updateTable} scroll={{ x: 'max-content', }}
              pagination={{
                current: pagination && pagination.current,
                pageSize: pagination && pagination.pageSize,
                onChange: handlePageChange,
              }}
              />
            </Space>
            <Modal title="Update Description" visible={modal} onOk={submitDesc} onCancel={() => toggle(false)}>
              <p>Comment</p>
              <TextArea
                rows={4}
                showCount
                maxLength={250}
                value={currentComment}
                onChange={changeComment}
              />
              <p>Approx. Staff Entry</p>
              <Dropdown overlay={staffEntryMenu}>
              <Button>
                { staffEntry || 'Add Staff Delay' } <DownOutlined />
              </Button>
              </Dropdown>
            </Modal>
            </div>
          </>
        }
      {
        visible && !timelineLoading
          ? <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis), }}>
          {
            timelinePics?.map((i) => <Image src={i?.imgUrl} key={i?.time}/>)
          }
        </Image.PreviewGroup>
          : null
      }
            </Space>

    </div>
  )
}

export default connect(mapStateToProps)(Report)
