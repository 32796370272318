import React from 'react'
import { Menu } from 'antd'
const ClearMenu = ({ clearRegion, region, }) => {
  return (
    <Menu
      onClick={clearRegion}
      items={[
        {
          label: `Clear ${region}`,
          key: region,
        },
        {
          label: 'Clear All',
          key: 'all',
        },
      ]}
    />
  )
}
export default ClearMenu
