import React from 'react'
import colors from '../styles/colors'
import { ClipLoader, ScaleLoader } from 'react-spinners'

interface Props {
  type: 'spin' | 'bars',
  color?: string,
  height?: string,
  width?: string,
}

function Loading ({ type, color = `${colors.darkGray}`, height = '100vh', width = '100%', }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${width}`,
        height: `${height}`,
      }}
    >
      {type === 'spin' && <ClipLoader color={color} loading/>}
      {type === 'bars' && <ScaleLoader color={color} loading />}
    </div>
  )
}

export default Loading
