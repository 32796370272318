import React from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from './mapStateToProps'

function Notification ({ dispatch, bases = [], beacons = [], orgs = [], orgUsers = [], orgUsersLoading, }: any) {
  return (
    <>
        <div style={{ padding: 20, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', }}>
          <em style={{ fontSize: 20, marginBottom: 8, }}>Notification</em>
        </div>
    </>
  )
}

export default connect(mapStateToProps)(Notification)
