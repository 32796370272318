import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Cascader, DatePicker, Space, Table, Input } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { setHomeFiltering, setHomePagination, setHomeSorting } from '../state/actions'
import Loading from './Loading'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import DisplayError from './DisplayError'
dayjs.extend(customParseFormat)
// import { convertIsoToUnix } from '../utility/helpers'
const { RangePicker, } = DatePicker
function NotifForm ({ state, dateRange, requestNotifications, orgs, reviewNotification, selectRoom, selectedRoom, updateDateRange, notifications, homeSorting, homeFiltering, homePagination, dispatch, loading = false, homeUnit, homeQueryError, }: any) {
  const [searchText, setSearchText,] = useState('')
  const [searchedColumn, setSearchedColumn,] = useState('')
  // eslint-disable-next-line arrow-body-style
  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < dayjs().add(-homeUnit.eventExp, 'd')
  }
  let searchInput
  const selectOrg = async (org: any) => {
    if (org && org[1]) selectRoom(org)
    updateDateRange(null)
    // get the unit of the room
  }
  const selectDate = (dates: any) => {
    updateDateRange(dates)
  }
  const submit = async () => {
    if (!selectedRoom || !dateRange) return
    await requestNotifications(selectedRoom[1], dateRange)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters({ confirm: true, })
    setSearchText('')
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8, }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value,] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90, }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: text => {
      if (!text) {
        return 'None'
      }
      const highlight = dataIndex === 'labeledByName'
      const email = text.split('-')
      if (searchedColumn === dataIndex) {
        return <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0, }} searchWords={[searchText,]} autoEscape textToHighlight={highlight ? text ? text.toString() : '' : email[2] ? email[2].toString() : '' } />
      } else {
        return highlight ? text : email[2]
      }
    },
  })
  const updateTable = (pagination: any, filters: any, sorters: any) => {
    dispatch(setHomeFiltering({
      type: filters.type,
      resolvedBy: filters.resolvedBy,
      labeledByName: filters.labeledByName,
      label: filters.label,
      level: filters.level,
      fr: filters.fr,
    }))
    dispatch(setHomeSorting({
      column: sorters.column,
      columnKey: sorters.columnKey,
      field: sorters.field,
      order: sorters.order,
    }))
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Room',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Created At',
      width: 200,
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left',
      sorter: (a, b) => a.id < b.id ? -1 : b.id < a.id ? 1 : 0,
      sortOrder: homeSorting && homeSorting.columnKey === 'createdAt' && homeSorting.order || null,
    },
    {
      title: 'Promoted On',
      width: 200,
      dataIndex: 'promotedOn',
      key: 'promotedOn',
      sorter: (a, b) => a.promotedOn < b.promotedOn ? -1 : b.promotedOn < a.promotedOn ? 1 : 0,
      sortOrder: homeSorting && homeSorting.columnKey === 'promotedOn' && homeSorting.order || null,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      width: 150,
      filters: [
        { text: '0', value: '0', },
        { text: '1', value: '1', },
      ],
      filteredValue: homeFiltering && homeFiltering.level || null,
      onFilter: (value, record) => (record.level).toString().includes(value),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      filters: [
        { text: 'fall', value: 'fall', },
        { text: 'out_of_bed', value: 'out_of_bed', },
        { text: 'out_of_chair', value: 'out_of_chair', },
      ],
      render: text => {
        if (!text) {
          return 'None'
        }
        return text
      },
      filteredValue: homeFiltering && homeFiltering.type || null,
      onFilter: (value, record) => {
        if (!record.type) {
          return null
        }
        return record.type.includes(value)
      },
    },
    {
      title: 'Resolved By',
      dataIndex: 'resolvedBy',
      key: 'resolvedBy',
      width: 150,
      // render: (value,record) => {
      //   if (!record.resolvedBy) return 'None'
      //   const email = record.resolvedBy.split('-')
      //   return email[2]
      // },
      filteredValue: homeFiltering && homeFiltering.resolvedBy || null,
      onFilter: (value, record) => !value ? record.resolvedBy : record.resolvedBy.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('resolvedBy'),
    },
    {
      title: 'Resolved At',
      dataIndex: 'resolvedAt',
      key: 'resolvedAt',
      width: 300,
      render: text => {
        if (!text) {
          return 'None'
        }
        return text
      },
      sorter: (a, b, dir) => {
        if (!a.resolvedAt) {
          return dir === 'ascend' ? 1 : -1
        } else if (!b.resolvedAt) {
          return dir === 'ascend' ? -1 : 1
        } else if (a.resolvedAt === b.resolvedAt) {
          return 0
        }
        return Date.parse(a.resolvedAt) - Date.parse(b.resolvedAt)
      },
      sortOrder: homeSorting && homeSorting.columnKey === 'resolvedAt' && homeSorting.order || null,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 150,
      filters: [
        { text: 'False Positive', value: 'falsePositive', },
        { text: 'False Negative', value: 'falseNegative', },
        { text: 'True Positive', value: 'truePositive', },
        { text: 'True Negative', value: 'trueNegative', },
        { text: 'None', value: 'none', },
      ],
      filteredValue: homeFiltering && homeFiltering.label || null,
      onFilter: (value, record) => record.label.includes(value),
    },
    {
      title: 'Labeled By',
      dataIndex: 'labeledByName',
      key: 'labeledByName',
      width: 150,
      filteredValue: homeFiltering && homeFiltering.labeledByName || null,
      onFilter: (value, record) => !value ? record.labeledByName : record.labeledByName ? record.labeledByName.toLowerCase().includes(value.toString().toLowerCase()) : null,
      ...getColumnSearchProps('labeledByName'),
    },

    {
      title: 'Fall Risk',
      dataIndex: 'fr',
      key: 'fr',
      width: 100,
      render: text => {
        if (!text) {
          return 'None'
        }
        return text
      },
      filters: [
        { text: 'high', value: 'high', },
        { text: 'medium', value: 'medium', },
        { text: 'low', value: 'low', },
        { text: 'off', value: 'off', },
      ],
      filteredValue: homeFiltering && homeFiltering.fr || null,
      onFilter: (value, record) => {
        if (!record.fr) {
          return null
        }
        return record.fr.includes(value)
      },
    },
    {
      title: 'Deep Dive',
      key: 'operation',
      width: 100,
      render: (value, record) => {
        const day1 = dayjs(record.createdAt)
        const day2 = dayjs()
        return (<Button disabled={day1.diff(day2, 'day', true) < -homeUnit?.imageExp} onClick={() => reviewNotification(selectedRoom, record)} type="primary"><Link to="/main/review">review</Link></Button>)
      },
    },
  ]
  const handlePageChange = (page,pageSize) => {
    dispatch(setHomePagination({
      current: page,
      pageSize: pageSize,
    }))
  }
  // useEffect(() => {
  //   if (!homeFiltering) {
  //     dispatch(setHomeFiltering({
  //       type: null,
  //       label: null,
  //       fr: null,
  //     }))
  //   }
  //   if (!homeSorting) {
  //     dispatch(setHomeSorting({
  //       columnKey: 'createdAt',
  //       field: 'createdAt',
  //     }))
  //   }
  //   if (!homePagination) {
  //     dispatch(setHomePagination({
  //       current: 1,
  //       pageSize: 10,
  //     }))
  //   }
  // },[])

  return (
        <div key={state}>
        <Space direction="vertical" size={16}>
          <Cascader defaultValue={selectedRoom} value={selectedRoom} options={orgs} placeholder={'Select Room'} onChange={selectOrg}/>
          {homeUnit ? <div> {homeUnit?.imageExp ? <div>{`Images expire after ${homeUnit?.imageExp} days`}</div> : <div>Images have no expiration</div>}{homeUnit?.imageExp ? <div>{`Events expire after ${homeUnit?.eventExp} days`}</div> : <div>Events have no expiration</div>}</div> : null}
          <RangePicker disabledDate={disabledDate}
          defaultValue={dateRange} onChange={selectDate} showTime={true} value={dateRange}/>
          <Button type="primary" htmlType="submit" loading={loading} onClick={submit} disabled={!selectedRoom || !dateRange}>
            Submit
          </Button>
          { !loading && !homeQueryError
            ? <Table columns={columns} dataSource={notifications || []} onChange={updateTable}
          pagination={{
            current: homePagination && homePagination.current,
            pageSize: homePagination && homePagination.pageSize,
            onChange: handlePageChange,
          }}/>
            : homeQueryError ? <DisplayError/> : <Loading type='bars'/> }
        </Space>
    </div>
  )
}

export default NotifForm
