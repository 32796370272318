import amplitude from 'amplitude-js'

export const initAmp = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE)
}

export const setAmpUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmpUserId = userId => {
  amplitude.getInstance().setUserId(userId)
}

export const setAmpUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties)
}

export const sendAmpEvent = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, { ...eventProperties, env: process.env.REACT_APP_API, })
}
