import React, { useCallback, useMemo, useState } from 'react'
import { Button, Form, message, Modal, Select, Space, Switch } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { runRoutine } from '../data-access/runRoutine'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchUnits } from '../../../state/actions'
import { isEmpty } from 'lodash'
import { stopRoutine } from '../data-access/stopRoutine'

interface Props {
  unit: string
  activeRoutine: string
  runInLoop: boolean
}

const ROUTINE_OPTIONS = [
  { value: 'random', label: 'Random', },
  { value: 'patientFalls', label: 'Falls', },
  { value: 'patientInBedCheckedByStaff', label: 'In Bed Checked By Staff', },
  { value: 'patientOutOfBed', label: 'Out Of Bed', },
  { value: 'patientOutOfBedFrequently', label: 'Out Of Bed Frequently', },
  { value: 'patientSleepy', label: 'Sleepy', },
]

export const VBaseRunRoutineButton = ({ unit, activeRoutine, runInLoop, }: Props) => {
  const [processing, setProcessing,] = useState(false)
  const [open, setOpen,] = useState(false)
  const rooms = useAppSelector(state => state.mainReducer.rooms || [])
  const [form,] = Form.useForm()
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const dispatch = useAppDispatch()

  const virtualBaseStationOptions = useMemo(() => {
    return [
      { label: 'All', value: '--all--', },
      ...rooms.filter(room => room.virtual).map(vbase => ({ label: vbase.name, value: vbase.mainId, })),
    ]
  }, [rooms,])

  const handleRoutineChange = useCallback((value) => {
    form.setFieldValue('routine', value)
  }, [form,])

  const handleRoomChange = useCallback((value) => {
    form.setFieldValue('room', value)
  }, [form,])

  const handleRunInLoopChange = useCallback((value) => {
    form.setFieldValue('runInLoop', value)
  }, [form,])

  const handleRunRoutine = useCallback((values) => {
    const { routine, room, runInLoop, } = values
    setProcessing(true)
    runRoutine(getToken, unit, routine, runInLoop, room === '--all--' ? undefined : room).then(() => {
      message.success('Routine started')
      setProcessing(false)
      setOpen(false)
      dispatch(fetchUnits({ getToken, }))
    }).catch(err => {
      setProcessing(false)
      console.error(err)
      message.error('Failed to run routine')
    })
  }, [unit,])

  const handleStopRoutine = useCallback(() => {
    setProcessing(true)
    stopRoutine(getToken, unit).then(() => {
      message.success('Routine stopped')
      setProcessing(false)
      dispatch(fetchUnits({ getToken, }))
    }).catch(err => {
      setProcessing(false)
      console.error(err)
      message.error('Failed to stop routine')
    })
  }, [unit,])

  const handleFormSubmit = useCallback(() => {
    form.submit()
  }, [form,])

  return <>
    <Space>
      { !activeRoutine && <Button
        type="default"
        onClick={() => setOpen(true)}
        disabled={!isEmpty(activeRoutine)}
      >Run routine</Button> }
      { activeRoutine && runInLoop && <Button
        type="default"
        onClick={handleStopRoutine}
        disabled={!isEmpty(activeRoutine)}
      >Stop routine</Button> }
      { activeRoutine && <span>Playing routine</span> }
    </Space>
    <Modal
      title="Select routine to run"
      visible={open}
      onOk={handleFormSubmit}
      onCancel={() => setOpen(false)}
      confirmLoading={processing}
    >
      <Form
        name="basic"
        labelCol={{ span: 8, }}
        wrapperCol={{ span: 16, }}
        form={form}
        onFinish={handleRunRoutine}
        autoComplete="off"
        initialValues={{ routine: 'random', room: '--all--', runInLoop: false, }}
      >
        <Form.Item
          label="Routine"
          name="routine"
          rules={[{ required: true, message: 'Please select a routine', },]}
        >
          <Select
            style={{ width: '100%', }}
            options={ROUTINE_OPTIONS}
            onChange={handleRoutineChange}
          />
        </Form.Item>
        <Form.Item
          label="Room"
          name="room"
          rules={[{ required: true, message: 'Please select a room', },]}
        >
          <Select
            style={{ width: '100%', }}
            options={virtualBaseStationOptions}
            onChange={handleRoomChange}
          />
        </Form.Item>
        <Form.Item
          label="Run In Loop"
          name="runInLoop"
        >
          <Switch onChange={handleRunInLoopChange} />
        </Form.Item>
      </Form>
    </Modal>
  </>
}
