import moment from 'moment'
import dayjs from 'dayjs'
// TODO: Reduce number of moment calls
export const getCounterSince = unixNum => {
  const now = moment()
  const momentNum = moment.unix(unixNum)
  const result = now.diff(momentNum, 'seconds')
  const counter = moment.utc(result * 1000).format('HH:mm:ss')
  return counter
}
export const formatTimeString = (seconds: number) => {
  if (seconds === 0) return ''
  const tense = seconds > 0 ? 'before' : 'after'
  const abs = Math.abs(seconds)

  if (abs < 60) {
    return `${abs} seconds ${tense}`
  }

  if (abs >= 60) {
    const min = Math.floor(abs / 60)
    const remainder = abs - (min * 60)

    return `${min} min, ${remainder} seconds ${tense}`
  }
}

export const convertUnixToIso = (t: any): string => {
  if (isNaN(t)) return t

  return new Date(t * 1000).toISOString()
}

export const convertIsoToUnix = (t: any): number => {
  if (!isNaN(t)) return t

  return Math.floor(new Date(t).getTime() / 1000)
}

export const extractImgTimestamp = uri => {
  const timestampInfo = uri ? uri.split('-base')[0] && uri.split('-base')[0] : null
  const millisTimestamp = timestampInfo && timestampInfo.split('/') && timestampInfo.split('/')[9]

  const unixTimestamp = Math.round(millisTimestamp / 1000)

  return unixTimestamp
}
export const extractUnitFromRoom = room => {
  const unit = room.split('-')
  const floor = unit[1] + '-' + unit[2] + '-' + unit[3]
  const name = unit[4]
  return { floor, name, }
}
export const unixDiff = (start, end) => {
  const seconds = end - start
  const suffix = seconds > 0 ? 'after' : 'before'

  if (Math.abs(seconds) < 60) return `${Math.abs(seconds)} seconds ${suffix}`

  const adjSeconds = Math.abs(seconds % 60)
  const minutes = Math.floor(Math.abs(seconds / 60)) % 60

  return `${minutes} minutes, ${adjSeconds} seconds ${suffix}`
}

export const generatePassword = (letters = 12, nums = 2, alpha = 1, any = 1) => {
  const chars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', // letters
    '0123456789', // numbers
    '!?@#$%^*()', // alpha
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', // any
  ]

  return [letters, nums, alpha, any,].map(function (len, i) {
    return Array(len).fill(chars[i]).map(function (x) {
      return x[Math.floor(Math.random() * x.length)]
    }).join('')
  }).concat().join('').split('').sort(function () {
    return 0.5 - Math.random()
  }).join('')
}

export const emailCheck = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const delay = ms => {
  return new Promise(resolve => {
    setTimeout(() => { resolve('') }, ms)
  })
}
export const compareOrgRooms = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
}
export const isAfterExp = (notifId, exp) => {
  if (Number(exp) === 0) return false

  const today = dayjs()
  return dayjs(notifId).diff(today, 'day', true) < -exp
}
