import { Api } from '../../../utility/client'
import { getJwtToken } from '../../../utility'

export const bumpUpTestingSessionTime = async (getToken, unitId: string) => {
  const jwt = await getJwtToken(getToken)

  await Api.patch(
    '/v1/vbase/bump_up_time',
    { unitId, },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
}
