import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Image, Result } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import { mapNotifToText } from '../../rules'
import { updateTraining, labelPotential } from '../../state/actions'
import { mapStateToProps } from './mapStateToProps'

function Train ({ store, match, type, train, loading, orgs, dates, selectedOrg, complete, dispatch, history, }: any) {
  const { getAccessTokenSilently, user, } = useAuth0()

  const [page, next,] = useState(0)

  const requestMore = (loading = false) => {
    dispatch(updateTraining({ getToken: getAccessTokenSilently, type, start: dates[0], end: dates[1], page, org: selectedOrg, loading, }))
    next(page + 1)
  }

  useEffect(() => {
    if (type && dates && (!train || !train.length)) {
      requestMore(true)
    }
    if (train.length < 6 && !complete) {
      requestMore()
    }
  }, [type, train.length,])

  return (
    <>
      <div style={{ fontSize: 22, fontWeight: 600, }}>Potential {mapNotifToText(type)}s</div>
      { loading
        ? <Loading type='bars'/>
        : (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', }}>
        {train.map((e: any) => {
          const { time, room, } = e
          const params = { time, room, email: user?.email, getToken: getAccessTokenSilently, }
          return (
            <div key={time} style={{ width: 400, margin: 12, marginBottom: 50, display: 'flex', flexDirection: 'column', }}>
              <Image src={e.imgUrl} width={400} />
              <div>Location: {e.newLoc}</div>
              <div>Pose: {e.newPose}</div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', }}>
                <Button onClick={() => dispatch(labelPotential({ ...params, label: 'trueNegative', }))} style={{ marginBottom: 4, marginTop: 4, }} type="primary">True Negative (does not qualify as {type})</Button>
                <Button onClick={() => dispatch(labelPotential({ ...params, label: 'falseNegative', }))} type="primary" danger>False Negative (does qualify as {type})</Button>
            </div>
            </div>
          )
        })}
        {}
      </div>
          )
      }
      {
        complete && train && !train.length
          ? <Result
        status="success"
        title="Successfully Trained Negatives!"
        subTitle="Go back and update date range to train more"
      />
          : null
      }
    </>
  )
}

export default connect(mapStateToProps)(Train)
