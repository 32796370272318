import React from 'react'
import { Result } from 'antd'
const DisplayError = () => (
  <Result
    status="500"
    title="500"
    subTitle="Sorry, something went wrong."
  />
)
export default DisplayError
