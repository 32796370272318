import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, List, Card, Button, Modal, Input, Space, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import { updateBases, loadOrgUsers, updateBeaconAssignment } from '../../state/actions'
import { mapStateToProps } from './mapStateToProps'
import Highlighter from 'react-highlight-words'

const { Meta, } = Card
const { Option, } = Select
function Basestations ({ dispatch, bases = [], beacons = [], orgs = [], orgUsers = [], orgUsersLoading, }: any) {
  const { getAccessTokenSilently: getToken, } = useAuth0()
  // const [count, setCount] = useState(10)
  const [sorters, updateSorters,] = useState<any>(null)
  const [selectedBeacon, selectBeacon,] = useState<any>(null)
  const [selectedOrg, selectOrg,] = useState<any>(null)
  const [selectedOrgUser, selectOrgUser,] = useState<any>(null)
  const [modal, toggle,] = useState(false)
  const [filters, updateFilters,] = useState<any>(null)
  const [searchText, setSearchText,] = useState('')
  const [searchedColumn, setSearchedColumn,] = useState('')
  let searchInput
  // const selectedEmail = selectedOrgUser && selectedOrgUser.mainId && selectedOrgUser.mainId.split('-') && selectedOrgUser.mainId.split('-')[2]
  const updateTable = (pagination: any, filters: any, sorters: any) => {
    updateFilters(filters)
    updateSorters(sorters)
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true, })
    setSearchText('')
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8, }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value,] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90, }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: (text) => {
      if (!text) {
        return 'None'
      }
      if (searchedColumn === dataIndex) {
        return <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0, }} searchWords={[searchText,]} autoEscape textToHighlight={text ? text.toString() : ''} />
      } else {
        return text
      }
    },
  })

  const edit = (beacon: any) => {
    selectBeacon(beacon)
    const [, org,] = beacon.assignedTo && beacon.assignedTo.split('-') || []
    selectOrg(org)
    toggle(true)
    dispatch(loadOrgUsers({ getToken, org, }))
  }

  const updateOrg = (org: string) => {
    dispatch(loadOrgUsers({ getToken, org, }))
    selectOrg(org)
  }

  const submitBeaconAssignment = () => {
    if (!selectedOrgUser || !selectedBeacon) return
    dispatch(updateBeaconAssignment({ getToken, beacon: selectedBeacon.id, assignedTo: selectedOrgUser.mainId, firstName: selectedOrgUser.firstName, }))
    toggle(false)
  }

  // const orgsMenu = (
  //     <Menu onClick={(e: any) => updateOrg(e.key)}>
  //       {
  //         orgs.map(o => {
  //           return (
  //             <Menu.Item key={o.id}>
  //               {o.id}
  //             </Menu.Item>
  //           )
  //         })
  //       }
  //     </Menu>
  // )
  const orgIds = orgs.map((i) => {
    return i.id
  })
  const orgUserIds = orgUsers.map((i) => {
    return {
      mainId: i.mainId,
      firstName: i.firstName,
      email: i.email,
    }
  })
  // const orgUsersMenu = (
  //     <Menu onClick={(e: any) => {
  //       const [mainId, firstName,] = e.key && e.key.split('###')
  //       selectOrgUser({ mainId, firstName, })
  //     }}>
  //       {
  //         orgUsers.map(u => {
  //           return (
  //             <Menu.Item key={`${u.mainId}###${u.firstName}`}>
  //               {u.email}
  //             </Menu.Item>
  //           )
  //         })
  //       }
  //     </Menu>
  // )
  const handleOrgUserChange = (value) => {
    const [mainId, firstName,] = value && value.split('###')
    selectOrgUser({ mainId, firstName, })
  }
  const handleOrgChange = (value) => {
    updateOrg(value)
  }
  const columns: ColumnsType<any> = [
    {
      title: 'Beacon',
      // width: 100,
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id < b.id ? -1 : 1,
      sortOrder: sorters && sorters.columnKey === 'id' && sorters.order || null,
      fixed: 'left',
      filteredValue: filters && filters.id || null,
      onFilter: (value, record) => !value ? record.id : record.id.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('id'),
    },
    {
      title: 'assigned',
      dataIndex: 'email',
      key: 'email',
      // width: 150,
      sorter: (a, b, dir) => {
        if (!a.email) {
          return dir === 'ascend' ? 1 : -1
        } else if (!b.email) {
          return dir === 'ascend' ? -1 : 1
        } else if (a.email === b.email) {
          return 0
        }
        return a.email < b.email ? -1 : 1
      },
      sortOrder: sorters && sorters.columnKey === 'email' && sorters.order || null,
      filteredValue: filters && filters.email || null,
      onFilter: (value, record) => value === 'assigned' ? record.email : record.email ? record.email.toLowerCase().includes(value.toString().toLowerCase()) : null,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Last Seen',
      // width: 200,
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      fixed: 'left',
      sorter: (a, b) => a.lastSeen < b.lastSeen ? -1 : 1,
      sortOrder: sorters && sorters.columnKey === 'lastSeen' && sorters.order || null,
    },
    {
      title: 'Room',
      dataIndex: 'rm',
      key: 'rm',
      filters: [
        { text: 'VALLEY', value: 'VALLEY', },
        { text: 'MGH', value: 'MGH', },
        { text: 'PALM', value: 'PALM', },
        { text: 'GEORGETOWN', value: 'GEORGETOWN', },
        { text: 'SCC', value: 'SCC', },
        { text: 'EC', value: 'EC', },
        { text: 'MW', value: 'MW', },
        { text: 'None', value: 'None', },
      ],
      filteredValue: filters && filters.rm || null,
      onFilter: (value, record) => value === 'None' ? !record.rm : record.rm && record.rm.includes(value),
      // width: 200,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: (value, record) => (<div>
          <Button style={{ width: 60, marginBottom: 8, }} onClick={() => edit(record)}>edit</Button>
          </div>
      ),
    },
  ]

  // const onLoadMore = () => {
  //   setCount(count + 10)
  // }

  useEffect(() => {
    async function initialize () {
      dispatch(updateBases({ getToken, }))
    }
    initialize()
  }, [])

  if (bases.length === 0) return <Loading type='bars'/>
  return (
    <>
        <div style={{ padding: 20, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', }}>
          <em style={{ fontSize: 20, marginBottom: 8, }}>Basestations</em>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 3,
              lg: 4,
              xl: 5,
              xxl: 6,
              column: 4,
            }}
            dataSource={bases}
            size={'large'}
            renderItem={(item: any) => (
              <List.Item>
                  <Card style={{ width: 250, }}>
                    <Meta title={item.name} description={item.mainId} avatar={<div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: item.offline ? 'lightpink' : 'lightgreen', }}></div>}/>

                  </Card>
              </List.Item>
            )}
          />
          <em style={{ fontSize: 20, marginBottom: 8, marginTop: 8, }}>Beacons</em>
          <Table style={{ width: '100%', paddingLeft: 24, }} columns={columns} dataSource={beacons} onChange={updateTable}/>
          <Modal title={`Assign Beacon - ${selectedBeacon && selectedBeacon.id}`} visible={modal} onOk={submitBeaconAssignment} onCancel={() => toggle(false)}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', }}>
                {/* <Dropdown overlay={orgsMenu}>
                  <Button>
                    { selectedOrg || 'Update Org' } <DownOutlined />
                  </Button>
                </Dropdown> */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <div>{ selectedOrg ? `Beacons Current Assigned Org - ${selectedOrg}` : 'Beacons Current Assigned Org - None'}</div>
                  <div style={{ display: 'flex', marginTop: 4, justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ marginRight: 11, }}>
                      <span>Org -</span>
                    </div>
                  <Select
    showSearch
    placeholder="Search Organization"
    onChange={handleOrgChange}
    style={{ width: 300, }}
    value={selectedOrg}
    >
    {
    orgIds.map((x) => {
      return (
        <Option key={x} value={x}>{x}</Option>
      )
    })
  }
  </Select>
                  </div>
                  <div style={{ display: 'flex', marginTop: 4, justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ marginRight: 4, }}>
                      <span>User -</span>
                    </div>
  <Select
    showSearch
    style={{ width: 300, }}
    placeholder="Search User"
  onChange={handleOrgUserChange}
  >
    {
    orgUserIds.map((x) => {
      return (
        <Option key={`${x.mainId}###${x.firstName}`} value={`${x.mainId}###${x.firstName}`}>{`${x.firstName} ${x.email}`}</Option>
      )
    })
  }
  </Select>
  </div>
                </div>
                {/* <Dropdown overlay={orgUsersMenu} placement="bottomLeft">
                  <Button loading={orgUsersLoading}>
                    { selectedEmail || 'Select User' } <DownOutlined />
                  </Button>
                </Dropdown> */}
              </div>
            </Modal>
        </div>
    </>
  )
}

export default connect(mapStateToProps)(Basestations)
