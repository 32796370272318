import React, { useMemo } from 'react'
import { Table } from 'antd'
const columns = [
  {
    title: 'Unit',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (text, record) => <div>{text ? `${text}` : `${record.name}`}</div>,
  },
  {
    title: 'Image Expiration',
    dataIndex: 'imageExp',
    key: 'imageExp',
    render: (text) => {
      return (text ? <div>{text}</div> : 'No Expiration')
    },
  },
  {
    title: 'Event Expiration',
    dataIndex: 'eventExp',
    key: 'eventExp',
    render: (text) => {
      return (text ? <div>{text}</div> : 'No Expiration')
    },
  },
]
export const UnitExpiration = ({ units = [], selectedOrg = '', }) => {
  const selectedUnits = useMemo(() => {
    return units.find(u => (u.label).split(' ')[0] === selectedOrg)
  }, [units, selectedOrg,])

  return (
    <div>
        <Table columns={columns} dataSource={selectedUnits?.children || []} pagination={{
          hideOnSinglePage: true,
        }} size="small"/>
    </div>
  )
}
