
export const mapStateToProps = (state: any) => ({
  user: state.mainReducer.user || {},
  units: state.mainReducer.units || [],
  rooms: state.mainReducer.rooms || [],
  selectedUnit: state.mainReducer.unit || null,
  actionLoading: state.mainReducer.fnLoading || state.mainReducer.puLoading || state.mainReducer.switchLoading,
  roomNotes: state.mainReducer.roomNotes || {},
  notesLoading: state.mainReducer.notesLoading || null,
  unitLoading: state.mainReducer.unitLoading || null,
  notifHash: state.mainReducer.notifHash || {},
  notifLoading: state.mainReducer.notifLoading || false,
  viewHash: state.mainReducer.viewHash || {},
  staffNames: state.mainReducer.staffNames || [],
  staffNamesLoading: state.mainReducer.staffNamesLoading || false,
  slackUpdateLoading: state.mainReducer.slackUpdateLoading || false,
})
