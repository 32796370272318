import moment from 'moment'
import { mapStatus, secondsUntilOffline, filteredPhrases, internalUsers } from '../rules'
import colors from '../styles/colors'
import { Room } from '../state/types'
import { isEmpty } from 'lodash'
import { getCounterSince } from './helpers'
const { REACT_APP_API, } = process.env
export const returnAlertColor = alert => {
  if (alert === 'out_of_chair') return colors.mediumPurple
  else if (alert === 'fall') return colors.lightRed
  else if (alert === 'moving_in_bed') return colors.yellow
  else return colors.lightOrange
}
export const abbreviateAlert = alert => {
  if (alert === 'out_of_chair') return 'OOC'
  else if (alert === 'fall') return 'FALL'
  else if (alert === 'moving_in_bed') return 'MIB'
  else return 'LB'
}

export const checkIfOffline = keepAlive => {
  if (!keepAlive) return true
  return moment().diff(keepAlive, 'seconds') > secondsUntilOffline
}
export const checkIfInPrivacyMode = privacy => {
  if (!privacy) return false
  return privacy > moment().toISOString()
}
export const checkForAlert = (room: Room) => {
  if (!room) return {}
  if (room.adminNotificationId && room.adminAlert) return { level: 0, alert: room.adminAlert, notifId: room.adminNotificationId, }
  if (room.notificationId && room.alert) return { level: 1, alert: room.alert, notifId: room.notificationId, }
  return {}
}
export const searchStaffName = (staffNames,staff) => {
  if (staff.length > 0) {
    const staffId = staff[0]
    const staffFound = staffNames.filter(i => i.id === staffId)
    return staffFound.length > 0 ? staffFound[0].firstName : 'no id'
  } else return 'staff in room'
}
export const adminRoomExtractor = (staffNames, room, assignments, allUsersHash) => {
  if (!room) return {}
  let status
  let icon
  let timestamp = ''
  let start
  let end
  let textColor
  const {
    mainId,
    lastPose,
    lastLoc,
    lastMode,
    lastChecked,
    patient,
    taken,
    alert,
    adminAlert,
    lastMovement,
    lastEvent,
    notificationId,
    adminNotificationId,
    count,
    fallRiskLevel,
    keepAlive,
    lastRoomMotion,
    assigned,
    staff = [],
  } = room

  const alertToDisplay = adminAlert || alert
  const notification = adminAlert && adminNotificationId ? adminNotificationId : notificationId

  if (lastPose && lastLoc) {
    if (!adminAlert) {
      const statusInfo = mapStatus(lastLoc, lastPose, lastMode, false)
      status = count < 2 ? statusInfo.text : 'Staff in room'
      // icon = count < 2 ? statusInfo.icon : null;
      timestamp = status && lastEvent ? moment(lastEvent).fromNow() : ''
    } else {
      let statusInfo = null
      switch (adminAlert) {
        case 'fall':
          statusInfo = mapStatus(lastLoc, lastPose, lastMode)
          status = statusInfo.text
          // icon = statusInfo.icon;
          timestamp = status && lastEvent ? moment.unix(adminNotificationId).fromNow() : ''
          break
        case 'hidden':
          statusInfo = mapStatus(lastLoc, lastPose, lastMode)
          status = statusInfo.text
          // icon = statusInfo.icon;
          timestamp = status && lastEvent ? moment.unix(adminNotificationId).fromNow() : ''
          break
        case 'out_of_bed':
          statusInfo = mapStatus(lastLoc, lastPose, lastMode)
          status = statusInfo.text
          // icon = statusInfo.icon;
          timestamp = status && lastEvent ? moment.unix(adminNotificationId).fromNow() : ''
          break
        default:
          statusInfo = mapStatus(lastLoc, lastPose, lastMode)
          status = statusInfo.text
          icon = statusInfo.icon
          timestamp = status && lastEvent ? moment.unix(adminNotificationId).fromNow() : ''
      }
    }
  }

  // if (alertToDisplay) {
  //   const styles = notificationStyles[alertToDisplay];
  //   start = styles.start;
  //   end = styles.end;
  //   icon = styles.activeIcon;
  //   textColor = styles.textColor || "white";
  // }

  const patientInRoom = lastLoc !== 'outside' && lastLoc !== 'none' && lastPose !== 'none'

  const timeUnchecked = count > 1 || staff.length > 0
    ? searchStaffName(staffNames,staff)
    : lastChecked && patientInRoom
      ? `${moment(lastChecked).fromNow(true)}`
      : patient
        ? 'N/A'
        : '-'

  const team = assignments && assignments[mainId] ? [assignments[mainId][0], assignments[mainId][1],] : []

  const offline = checkIfOffline(keepAlive)

  const timeSinceMovement =
      ((lastMode === 'night' && !offline) || (count < 2 && lastLoc === 'bed')) && lastMovement
        ? moment(lastMovement).fromNow(true)
        : '-'

  // const lastModeIcon = getModeIcon(lastMode, alertToDisplay, offline);

  const timeSinceRoomMove = lastRoomMotion && moment(lastRoomMotion).fromNow(true)

  return {
    status,
    team,
    assigned,
    icon,
    timestamp,
    animate: Boolean(alertToDisplay),
    timeUnchecked,
    timeSinceMovement,
    start,
    end,
    taken,
    notificationId: notification,
    fallRiskLevel,
    offline,
    timeSinceRoomMove,
    textColor,
  }
}

export const smartSort = (a, b) => {
  // Check for promoted alerts

  if (a.alert === 'fall') return -1
  if (a.alert && !b.alert) return -1
  if (b.alert && !a.alert) return 1

  // Check for admin alerts

  if (a.adminAlert === 'fall') return -1
  if (a.adminAlert && !b.adminAlert) return -1
  if (b.adminAlert && !a.adminAlert) return 1

  // Check if offline
  const aOffline = checkIfOffline(a.keepAlive)
  const bOffline = checkIfOffline(b.keepAlive)
  if (bOffline && !aOffline) return -1
  if (aOffline && !bOffline) return 1

  if (a.lastLoc === 'none' && b.lastLoc !== 'none') return 1
  else if (b.lastLoc === 'none' && a.lastLoc !== 'none') return -1

  else return 0
}

export const alphaSort = (a, b) => {
  if (a.name > b.name) return 1
  else if (b.name > a.name) return -1

  else return 0
}

export const filterBases = bases => {
  if (REACT_APP_API !== 'prod') return bases
  else {
    return bases.filter(base => !filteredPhrases.some(building => base.mainId.includes(building) || base.name.includes(building)))
  }
}

export const soundCheck = user => {
  return !internalUsers.some(u => u === user?.email)
}

export const noteCheck = (lastView, room) => {
  const { lastNote = null, } = room

  if (!lastNote) return false
  if (!lastView) return true

  else return lastNote > lastView
}
const fallRiskSort = (a,b) => {
  const fallRiskArray = ['off','low','medium','high',]
  return fallRiskArray.indexOf(a.fallRiskLevel) > fallRiskArray.indexOf(b.fallRiskLevel) ? -1 : fallRiskArray.indexOf(a.fallRiskLevel) > fallRiskArray.indexOf(b.fallRiskLevel) ? 1 : 0
}
export const fallRiskAlphaSort = rooms => {
  rooms = rooms.sort(fallRiskSort)
  let fHigh = []
  let fMedium = []
  let fLow = []
  let fOff = []
  for (const i of rooms) {
    if (i.fallRiskLevel === 'high') fHigh.push(i)
    else if (i.fallRiskLevel === 'medium') fMedium.push(i)
    else if (i.fallRiskLevel === 'low') fLow.push(i)
    else if (i.fallRiskLevel === 'off') fOff.push(i)
  }
  fHigh = fHigh.sort(alphaSort)
  fMedium = fMedium.sort(alphaSort)
  fLow = fLow.sort(alphaSort)
  fOff = fOff.sort(alphaSort)
  rooms = (fHigh.concat(fMedium)).concat(fLow.concat(fOff))
  return rooms
}
export const dataPassedIntoAmpEvent = room => {
  return { room: room, }
}
const timeSort = (a: Room, b: Room) => {
  const aTime = getCounterSince(a.adminNotificationId || a.pId)
  const bTime = getCounterSince(b.adminNotificationId || b.pId)
  return aTime > bTime ? -1 : aTime < bTime ? 1 : 0
}
const isUnkownStatus = (staffNames: [], room: Room) => {
  const { status, } = adminRoomExtractor(staffNames, room, undefined, undefined)
  return status === 'Hidden' || status === 'Out of View' || (status === 'Staff in room' && isEmpty(room.staff))
}
const categorizeRooms = (rooms: Array<Room>, staffNames: []) => {
  interface category {
    pendingAlerts: Array<Room>,
    resolvedAlerts: Array<Room>
    lowMedStatusAlerts: Array<Room>,
    offlineRooms: Array<Room>,
    noAlerts: Array<Room>,
  }
  const categories: category = {
    pendingAlerts: [],
    resolvedAlerts: [],
    lowMedStatusAlerts: [],
    offlineRooms: [],
    noAlerts: [],
  }
  for (const room of rooms) {
    if (checkIfOffline(room.keepAlive)) {
      categories.offlineRooms.push(room)
    } else if (room.adminAlert) {
      categories.pendingAlerts.push(room)
    } else if (room.alert && isEmpty(room.adminAlert)) {
      categories.resolvedAlerts.push(room)
    } else if ((room.fallRiskLevel === 'low' || room.fallRiskLevel === 'medium') && isUnkownStatus(staffNames, room)) {
      categories.lowMedStatusAlerts.push(room)
    } else {
      categories.noAlerts.push(room)
    }
  }
  return categories
}

const sortRooms = (categories: any) => {
  categories.pendingAlerts = categories.pendingAlerts.sort(timeSort)
  categories.resolvedAlerts = categories.resolvedAlerts.sort(timeSort)
  categories.noAlerts = fallRiskAlphaSort(categories.noAlerts)
  return categories
}

export const newSmartSort = (rooms: Array<Room>, staffNames: []) => {
  const categories = categorizeRooms(rooms, staffNames)
  const sortedRooms = sortRooms(categories)
  return [...sortedRooms.pendingAlerts, ...sortedRooms.resolvedAlerts, ...sortedRooms.lowMedStatusAlerts,...sortedRooms.noAlerts, ...sortedRooms.offlineRooms,]
}
