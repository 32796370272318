import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Statistic } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import CMatrix from '../../components/Cmatrix'
import OrgSelector from '../../components/OrgSelector'
import { getAnalytics, updateAnalyticsDates, setTraining, updateOrg, updateType, setOrgs } from '../../state/actions'
import { getOrgs } from '../../utility'
import { mapStateToProps } from './mapStateToProps'

function Analytics ({ store, match, loading, staff, analytics, orgs, dates, dispatch, history, selectedOrg, }: any) {
  const { getAccessTokenSilently, } = useAuth0()
  useEffect(() => {
    async function checkForOrgs () {
      if (!orgs || !orgs.length) {
        const refreshedOrgs = await getOrgs(getAccessTokenSilently)
        dispatch(setOrgs(refreshedOrgs))
      }
    }
    checkForOrgs()
  })
  const selectDate = (dates: any) => {
    dispatch(updateAnalyticsDates(dates))
  }

  const selectOrg = (key: string) => {
    dispatch(updateOrg(key))
  }

  const submit = () => {
    if (!selectedOrg || !dates) return
    dispatch(getAnalytics({ getToken: getAccessTokenSilently, org: selectedOrg, start: dates[0], end: dates[1], }))
  }

  const label = (type: string) => {
    if (!selectedOrg || !dates) return
    dispatch(updateType(type))
    history.push('/series')
  }

  const train = (alertType: string) => {
    dispatch(setTraining(alertType))
    history.push('/train')
  }

  return (
    <>
      <OrgSelector
        orgs={orgs}
        selectedOrg={selectedOrg}
        dates={dates}
        selectDate={selectDate}
        selectOrg={selectOrg}
        submit={submit}
      />
      { loading
        ? <Loading type='bars'/>
        : !analytics
            ? null
            : <>
        <div style={{ width: '50%', margin: 'auto', padding: 20, borderWidth: '1px', borderColor: 'black', }}>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Total Notifications" value={analytics.totalCount} />
            </Col>
            <Col span={12}>
              <Statistic title="Unlabeled" value={analytics.noneCount} suffix={`/${analytics.totalCount}`} />
            </Col>
          </Row>
        </div>
        <div style={{ margin: 'auto', width: '60%', }}>
      { analytics && analytics.totalFalls ? <CMatrix train={train} label={label} unlabeled={analytics.unlabeledFalls} total={analytics.totalFalls} type={'fall'} falseP={analytics.fallFalsePosPercentage} trueP={analytics.fallTruePosPercentage} trueN={analytics.fallTrueNegPercentage} falseN={analytics.fallFalseNegPercentage}/> : null}
      { analytics && analytics.totalOOB ? <CMatrix train={train} label={label} unlabeled={analytics.unlabeledOOB} total={analytics.totalOOB} type={'out_of_bed'} falseP={analytics.bedFalsePosPercentage} trueP={analytics.bedTruePosPercentage} trueN={analytics.bedTrueNegPercentage} falseN={analytics.bedFalseNegPercentage}/> : null}
      { analytics && analytics.totalOOC ? <CMatrix train={train} label={label} unlabeled={analytics.unlabeledOOC} total={analytics.totalOOC} type={'out_of_chair'} falseP={analytics.chairFalsePosPercentage} trueP={analytics.chairTruePosPercentage} trueN={analytics.chairTrueNegPercentage} falseN={analytics.chairFalseNegPercentage}/> : null}
      { analytics && analytics.totalHidden ? <CMatrix train={train} label={label} unlabeled={analytics.unlabeledHidden} total={analytics.totalHidden} type={'hidden'} falseP={analytics.hiddenFalsePosPercentage} trueP={analytics.hiddenTruePosPercentage}/> : null}
      { analytics && analytics.totalMovingBed ? <CMatrix train={train} label={label} unlabeled={analytics.unlabeledMovingBed} total={analytics.totalMovingBed} type={'moving_in_bed'} falseP={analytics.movingBedFalsePosPercentage} trueP={analytics.movingBedTruePosPercentage}/> : null}
      { staff && staff.unlabeledEntries ? <CMatrix train={train} label={label} unlabeled={staff.unlabeledEntries} total={staff.totalEntries} type={'staff_entry'} falseP={staff.staffEntryFalsePosPercentage} trueP={staff.staffEntryTruePosPercentage} trueN={staff.staffEntryTrueNegPercentage} falseN={staff.staffEntryFalseNegPercentage} /> : null}
      { staff && staff.unlabeledExits ? <CMatrix train={train} label={label} unlabeled={staff.unlabeledExits} total={staff.totalExits} type={'staff_exit'} falseP={staff.staffExitFalsePosPercentage} trueP={staff.staffExitTruePosPercentage} trueN={staff.staffExitTrueNegPercentage} falseN={staff.staffExitFalseNegPercentage}/> : <Loading type='bars'/>}
        </div>
      </>}
    </>
  )
}

export default connect(mapStateToProps)(Analytics)
