import React from 'react'
import Nav from '../NavBar'
import { useAuth0 } from '@auth0/auth0-react'
const Layout = ({ children, }) => {
  const { logout, isAuthenticated, user, } = useAuth0()

  const hasAccess = ['admin', 'monitor',].includes(
    user?.['https://inspiren/role']
  )
  return (
    <>
      <Nav
        logout={logout.bind({ returnTo: `${window.location.origin}/login`, })}
        isAuthenticated={isAuthenticated && hasAccess}
      />
      <main>{children}</main>
    </>
  )
}
export default Layout
