/* eslint-disable react/prop-types */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  auth0Audience,
  getAuth0ClientId,
  getAuth0Domain
} from './config/auth0Config'

const Auth = ({ children, }) => {
  const history = useHistory()

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={getAuth0Domain()}
      clientId={getAuth0ClientId()}
      audience={auth0Audience}
      redirectUri={`${window.location.origin}/main/table`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth
