
export const mapStateToProps = (state: any) => ({
  monitorUsers: state.mainReducer.monitorUsers || [],
  assignmentsLoading: state.mainReducer.assignmentsLoading,
  monitorAssignments: state.mainReducer.monitorAssignments || [],
  assignmentsHash: state.mainReducer.assignmentsHash || {},
  bases: state.mainReducer.bases || [],
  valleyCount: state.mainReducer.valleyCount,
  palmCount: state.mainReducer.palmCount,
  georgetownCount: state.mainReducer.georgetownCount,
  team: state.mainReducer.team || [],
  teams: state.mainReducer.teams || [],
  currentAssignments: state.mainReducer.currentAssignments || [],
  setTeamLoading: state.mainReducer.setTeamLoading,
  deleteLoading: state.mainReducer.deleteLoading,
  bedAssignmentLoading: state.mainReducer.bedAssignmentLoading,
  userAssignmentLoading: state.mainReducer.userAssignmentLoading,
  orgUsersLoading: state.mainReducer.orgUsersLoading,
})
