import { React, useState } from 'react'
import { Button, Popconfirm } from 'antd'
import { dismissZoneConfig } from '../../utility'
const NoZoneUpdateButton = ({ getToken, roomId, enable, }) => {
  const [open, setOpen,] = useState(false)
  const showPopconfirm = () => {
    setOpen(true)
  }
  const handleOk = async () => {
    await dismissZoneConfig(getToken, roomId, enable)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  return (
        <div style={{ position: 'absolute', left: '20%', top: 15, }}>
            <Popconfirm
                title="Are you sure no zone update is required?"
                open={open}
                onConfirm={handleOk}
                onCancel={handleCancel}
            ><Button type="primary" onClick={showPopconfirm}>
                    No Zone Update Required
                </Button></Popconfirm></div>
  )
}
export default NoZoneUpdateButton
