import React, { useState, useEffect } from 'react'
import { List, Card } from 'antd'
import { useDrag } from 'react-dnd'
import { useSpring, animated as a } from 'react-spring'

// const { SubMenu, } = Menu
const { Meta, } = Card

export default function RoomCard ({ item, index, }: any) {
  const [fade, updateFade,] = useState(false)
  const [origIndex, updateInd,] = useState(-1)

  useEffect(() => {
    if (origIndex > -1) return
    updateFade(false)
    updateInd(index)
  }, [])

  const { transform, opacity, } = useSpring({
    opacity: fade ? 1 : 0,
    transform: 'perspective(600px)',
    config: { mass: 1, tension: 500, friction: 80, },
  })

  const [{ isDragging, }, drag,] = useDrag(() => ({
    type: 'assigned_room',
    item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const acceptableTarget = monitor.didDrop()
      if (acceptableTarget) updateFade(true)
    },
  }))

  return (
        <List.Item>
            <a.div
              ref={drag}
              className="room-card"
              style={{
                marginRight: 0,
                cursor: 'move',
                transform,
                opacity: isDragging ? 0.5 : opacity.interpolate((o: any) => 1 - o),
              }}>
                <Card>
                    <Meta title={`Room ${item.name}`} description={item.mainId.split('-')[1]}/>
                </Card>
            </a.div>
        </List.Item>
  )
}
