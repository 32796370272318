import { Button, message, Tooltip } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import React, { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from '../../../state/hooks'
import { startTestingSessionForUnit } from '../data-access/startTestingSessionForUnit'
import { fetchUnits } from '../../../state/actions'
import { stopTestingSessionForUnit } from '../data-access/stopTestingSessionForUnit'
import { RiStopLine } from 'react-icons/ri'
import styles from './VBaseTestingSessionButton.module.scss'

interface Props {
  isActive: boolean,
  unit: string
}

export const VBaseTestingSessionButton = ({ isActive, unit, }: Props) => {
  const [starting, setStarting,] = useState(false)
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const dispatch = useAppDispatch()

  const handleStart = useCallback(async () => {
    setStarting(true)
    startTestingSessionForUnit(getToken, unit).then(() => {
      message.success('Testing session started')
      dispatch(fetchUnits({ getToken, }))
    }).catch(() => {
      message.error('Failed to start testing session')
    }).finally(() => {
      setStarting(false)
    })
  }, [dispatch, getToken, unit,])

  const handleStop = useCallback(async () => {
    setStarting(true)
    stopTestingSessionForUnit(getToken, unit).then(() => {
      message.success('Testing session stopped')
      dispatch(fetchUnits({ getToken, }))
    }).catch(() => {
      message.error('Failed to stop testing session')
    }).finally(() => {
      setStarting(false)
    })
  }, [dispatch, getToken, unit,])

  return <>
    <Tooltip title={isActive ? 'Increase Testing Time by 10 minutes' : 'Start Testing Session for 10 minutes'}>
      <Button
        className={styles.vBaseButton}
        type="default"
        icon={isActive ? <span><RiStopLine fontSize={20}/></span> : <CaretRightOutlined />}
        loading={starting}
        onClick={isActive ? handleStop : handleStart}
      >
        { isActive ? 'Stop Testing' : 'Start Testing' }
      </Button>
    </Tooltip>
  </>
}
