
export const mapStateToProps = (state: any) => ({
  orgs: state.mainReducer.orgs || [],
  user: state.mainReducer.user || {},
  units: state.mainReducer.units || [],
  rooms: state.mainReducer.rooms || [],
  selectedUnit: state.mainReducer.unit || null,
  loading: state.mainReducer.fnLoading || state.mainReducer.puLoading || false,
  roomNotes: state.mainReducer.roomNotes || {},
  notesLoading: state.mainReducer.notesLoading || null,
  unitLoading: state.mainReducer.unitLoading || null,
})
