import React from 'react'
import { Row, Col, Card, Statistic, Tooltip, Button } from 'antd'
import { mapNotifToText } from '../rules'
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'

function CMatrix ({ train, label, total = 0, type, trueP, falseP, trueN, falseN, unlabeled = 0, } : any) {
  return (
    <div style={{ textAlign: 'left', margin: 8, }}>
        <div style={{ padding: 30, paddingTop: 10, background: '#e0e0e0', }}>
            <Tooltip placement="topLeft" title={`${total} labeled, ${unlabeled} unlabeled`}>
            <div style={{ fontSize: 14, fontWeight: 600, }}>{mapNotifToText(type)}</div>
            </Tooltip>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, }}>
                <div style={{ fontSize: 12, marginRight: 8, }}>{total + unlabeled} notifications, {unlabeled} unlabeled</div>
                <Button type="primary" onClick={() => label(type)}>Train Pos</Button>
            </div>
            <div style={{ margin: 5, }}>
                <Row gutter={12}>
                    <Col span={10}>
                        <Card>
                        <Statistic
                            title="True Positive"
                            value={trueP && trueP * 100}
                            precision={2}
                            valueStyle={{ color: '#3f8600', }}
                            prefix={<CheckOutlined />}
                            suffix="%"
                        />
                        </Card>
                    </Col>
                    <Col span={10}>
                        <Card>
                        <Statistic
                            title="False Positive"
                            value={falseP && falseP * 100}
                            precision={2}
                            valueStyle={{ color: '#cf1322', }}
                            prefix={<CloseOutlined />}
                            suffix="%"
                        />
                        </Card>
                    </Col>
                </Row>
            </div>
            <div style={{ margin: 5, }}>
                <Row gutter={12} align="middle">
                    <Col span={10}>
                        <Card>
                        <Statistic
                            title="True Negative"
                            value={trueN && trueN * 100 || 'N/A'}
                            precision={trueN ? 2 : 0}
                            valueStyle={{ color: trueN ? '#3f8600' : 'black', }}
                            prefix={trueN ? <CheckOutlined /> : null}
                            suffix={trueN ? '%' : null}
                        />
                        </Card>
                    </Col>
                    <Col span={10}>
                        <Card>
                        <Statistic
                            title="False Negative"
                            value={falseN && falseN * 100 || 'N/A'}
                            precision={falseN ? 2 : 0}
                            valueStyle={{ color: falseN ? '#cf1322' : 'black', }}
                            prefix={falseN ? <CloseOutlined /> : null}
                            suffix={falseN ? '%' : null}
                        />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Button type="dashed" onClick={() => train(type)}>
                            Train Neg
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default CMatrix
