import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { List, Avatar, Row, Col, Statistic } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../../components/Loading'
import OrgSelector from '../../../components/OrgSelector'
import { useDrop } from 'react-dnd'
import RoomCard from '../RoomCard'
import { monitorAssignment, updateOrg, updateUserFields } from '../../../state/actions'
import { mapStateToProps } from './mapStateToProps'
import { userLastActiveMinutes } from '../../../rules'
function Monitors ({
  dispatch,
  monitorUsers,
  monitorAssignments,
  assignmentsHash,
  assignmentsLoading,
  chooseUser,
  selectedUser,
  renderCheck,
  bases,
  orgs,
  selectedOrg,
  valleyCount,
  georgetownCount,
  palmCount,
  gnCount,
  hmCount,
  valleyAssignments,
  georgetownAssignments,
  palmAssignments,
  gnAssignments,
  hmAssignments,
  orgUsersLoading,
}: any) {
  const { getAccessTokenSilently: getToken, } = useAuth0()

  if (!renderCheck) return null

  const unassigned = bases.filter(b => !assignmentsHash[b.mainId]).map(b => {
    b.unassigned = true
    return b
  })

  const dropCard = (room, remove): any => {
    if (!room) return
    const { mainId, } = room
    if (assignmentsHash && assignmentsHash[mainId] && !remove) return
    if ((!assignmentsHash || !assignmentsHash[mainId]) && remove) return
    dispatch(monitorAssignment({ getToken, user: selectedUser, roomId: mainId, remove, }))

    if (remove) {
      let tm = (selectedUser && selectedUser.tm) || ''
      const [,org,] = mainId.split('-')
      if (!tm) return
      tm = tm.split('-').filter(t => t.toLowerCase() !== org.toLowerCase()).join('-')
      dispatch(updateUserFields({ getToken, userId: selectedUser.mainId, updatedFields: { tm, }, }))
      chooseUser({ ...selectedUser, tm, })
    }
  }

  const [{ isOver: isOverAssigned, }, assignZone,]: any = useDrop(
    (): any => ({
      accept: 'assigned_room',
      drop: (item) => dropCard(item, false),
      canDrop: (item) => {
        return item && item.unassigned
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }), [assignmentsHash, selectedUser, unassigned.length,])

  const [{ isOver: isOverUnassign, }, unassignZone,]: any = useDrop(
    (): any => ({
      accept: 'assigned_room',
      drop: (item) => dropCard(item, true),
      canDrop: (item) => {
        return item && !item.unassigned
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }), [assignmentsHash, selectedUser, unassigned.length,])

  const renderMonitor = (item) => {
    const selected = selectedUser && selectedUser.mainId === item.mainId
    // const activeMoment = moment(item.lastActive)
    // const timestamp = item.lastActive ? activeMoment.fromNow() : 'null'
    const recentlyActive = item.lastActive && moment().diff(item.lastActive, 'minutes') < userLastActiveMinutes
    const teams = (item.tm && item.tm.split('-').map(i => i[0]).join(',')) || 'Unassigned'

    return (
      <List.Item key={item.mainId} style={{ width: 290, backgroundColor: selected ? 'lightgray' : 'transparent', }} actions={[<div key={1} className="clickable" onClick={() => chooseUser(item)}>Select</div>,]}>
        <List.Item.Meta
          avatar={<Avatar src={item && item.avatar} />}
          title={<div style={{ display: 'flex',flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}><div className="status" data-status={recentlyActive && 'active'}/><div className="standard-text">{`${item.firstName} ${item.lastName}`}</div></div>}
          description={`${teams}`}
        />
      </List.Item>
    )
  }

  const selectOrg = (key: string) => {
    dispatch(updateOrg(key))
  }

  const submit = async () => {
    const allNew = unassigned.filter(r => {
      const [,org,] = r.mainId.split('-')
      return org === selectedOrg
    })

    allNew.forEach(r => {
      dropCard(r, false)
    })

    let tm = (selectedUser && selectedUser.tm) || ''

    if (tm.includes(selectedOrg)) return
    if (tm) tm = `${tm}-${selectedOrg}`
    if (!tm) tm = selectedOrg

    dispatch(updateUserFields({ getToken, userId: selectedUser.mainId, updatedFields: { tm, }, }))
    chooseUser({ ...selectedUser, tm, })
  }

  const roomSummary = (
        <Row gutter={12} style={{ marginTop: 8, marginBottom: 8, }} justify="center">
            <Col span={3}>
              <Statistic title="Valley" value={valleyAssignments} suffix={`/ ${valleyCount}`} />
            </Col>
            <Col span={3}>
              <Statistic title="Palm" value={palmAssignments} suffix={`/ ${palmCount}`} />
            </Col>
            <Col span={3}>
              <Statistic title="Gtown" value={georgetownAssignments} suffix={`/ ${georgetownCount}`} />
            </Col>
            <Col span={3}>
              <Statistic title="Gen" value={gnAssignments} suffix={`/ ${gnCount}`} />
            </Col>
            <Col span={3}>
              <Statistic title="Homestay" value={hmAssignments} suffix={`/ ${hmCount}`} />
            </Col>
          </Row>
  )

  return (
          <div className="page-container-row">
            {
              orgUsersLoading
                ? <Loading type='bars'/>
                : <>
                            <div style={{ width: 290, }}>
              <List
                style={{ padding: 16, height: '100%', margin: 0, }}
                itemLayout="horizontal"
                dataSource={monitorUsers}
                renderItem={renderMonitor}
              />
            </div>
            <div style={{ flexDirection: 'column', width: '100%', padding: 8, }}>
            { !selectedUser
              ? null
              : assignmentsLoading
                ? <Loading type='bars'/>
                : <>
              <div className="page-header">{`${selectedUser.firstName} ${selectedUser.lastName}`}</div>
              <div style={{ position: 'absolute', alignItems: 'center', justifyContent: 'center', right: 16, top: 62, }}>
                <div>Assign Org</div>
                <OrgSelector
                  orgs={orgs}
                  selectOrg={selectOrg}
                  selectedOrg={selectedOrg}
                  submit={submit}
                />
              </div>
              <div className="page-subtitle">{'Live Basestation Coverage'}</div>
              {roomSummary}
              <div ref={assignZone} style={{ paddingTop: 12, backgroundColor: isOverAssigned ? '#a5d6a7' : 'transparent', }}>
                <List
                  grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 4,
                    column: 3,
                  }}
                  dataSource={monitorAssignments}
                  size={'large'}
                  renderItem={(item: any, index) => (
                    <RoomCard key={item.mainId} item={item} index={index} />
                  )} />
              </div>
            <div className="page-header">{'Unassigned'}</div>
            <div className="page-subtitle">{'Not covered by this user'}</div>
            <div ref={unassignZone} style={{ paddingTop: 12, backgroundColor: isOverUnassign ? '#f48fb1' : 'transparent', }}>
              <List
              grid={{
                gutter: 8,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 3,
                xxl: 4,
                column: 3,
              }}
              dataSource={unassigned}
              size={'large'}
              renderItem={(item: any) => (
                <RoomCard key={item.mainId} item={item} />
              )} />
            </div>
            </>
            }
            </div>
                </>
            }

          </div>)
}

export default connect(mapStateToProps)(Monitors)
