import { Api } from './client'
import { Room } from '../state/types'
import { auth0Audience } from '../config/auth0Config'

export const getJwtToken = async getToken => {
  return await getToken({ audience: auth0Audience, })
}

export const getOrgs = async getToken => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get('/v1/reporting/allOrgs', {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getNotifications = async (getToken, room, start, end) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/notifications/${room}/${start}/${end}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getEvents = async (getToken, room, id, bound = 180) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/timeline/${room}/${id}/${bound}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}
export const getStaffNames = async (getToken, org) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`v1/users/allBeaconUsers/${org}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const labelNotification = async (getToken, room, id, label, email) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put('/v1/reporting/notification/label',
    {
      room,
      id,
      label,
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

  return res && res.data
}

export const labelEvent = async (getToken, room, time, label, email) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put('/v1/reporting/event/label',
    {
      room,
      time,
      label,
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

  return res && res.data
}

export const getAnalytics = async (getToken, org, start, end) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/analytics/${org}/${start}/${end}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getReport = async (getToken, org, start, end) => {
  try {
    const jwt = await getToken({ audience: auth0Audience, })
    const res = await Api.get(`/v1/reporting/generateReport/${org}/${start}/${end}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res && res.data
  } catch (err) {
    return err
  }
}

export const addNotifDesc = async (getToken, room, id, comment, staff_entry) => {
  try {
    const jwt = await getToken({ audience: auth0Audience, })
    const res = await Api.put('/v1/reporting/updateDesc',
      {
        room,
        id,
        comment,
        staff_entry,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
    return res && res.data
  } catch (err) {
    return err
  }
}

export const getStaffAnalytics = async (getToken, org, start, end) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/analytics/staff/${org}/${start}/${end}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getTraining = async (getToken, org, type, start, end, page = 0) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/train/${org}/${type}/${start}/${end}/${page}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getSeries = async (getToken, org, type, start, end) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/notifications/type/${org}/${type}/${start}/${end}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getStaffSeries = async (getToken, org, type, start, end) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/reporting/events/staff/${org}/${type}/${start}/${end}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getUser = async (getToken, email, fname, lname) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/users/${email}/${fname}/${lname}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const getUnits = async getToken => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get('/v1/users/org/units', {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}
export const getRoomUnit = async (getToken, floor, name) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/units/${floor}/${name}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}
interface GetUnitResponse {
  assignments: Record<string, any>
  rooms: Room[]
}

export const getUnit = async (getToken, floor, name) => {
  if (!floor || !name) return
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get<GetUnitResponse>(`/v1/monitor/rooms/live/${floor}/${name}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  const data = res.data
  return {
    assignments: data.assignments,
    rooms: data.rooms.map(room => ({ ...room, testingSessionEndsAt: room.testingSessionEndsAt ? new Date(room.testingSessionEndsAt) : null, })),
  }
}

export const resolveNotification = async (getToken, roomId, user, notificationId) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put(`/v1/notifications/${notificationId}/resolve`,
    {
      user,
      roomId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const rejectNotification = async (getToken, roomId, user, notificationId) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put(`/v1/notifications/${notificationId}/reject`,
    {
      user,
      roomId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const promoteNotification = async (getToken, roomId, user, notificationId) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put('/v1/notifications/promote',
    {
      user,
      roomId,
      notificationId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
  return res && res.data
}

export const reportFN = async (getToken, room, flag) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.post(
    '/v1/monitor/manualEvent',
    {
      room,
      flag,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
  return res && res.data
}

export const reportPU = async (getToken, roomId, message, enable) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.post(
    '/v1/monitor/manualUpdate',
    {
      roomId,
      message,
      enable,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const addNote = async (getToken, roomId, note) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.post(
    '/v1/rooms/notes',
    {
      roomId,
      note,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getNotes = async (getToken, roomId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/rooms/notes/${roomId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getBases = async getToken => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    '/v1/reporting/bases',
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getBeacons = async getToken => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    '/v1/reporting/beacons',
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getOrgUsers = async (getToken, org) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/reporting/users/${org}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const assignBeacon = async (getToken, beacon, assignedTo, firstName = '') => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.post(
    '/v1/admin/assignBeacon',
    {
      beacon,
      assignedTo,
      firstName,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getNotif = async (getToken, notificationId, roomId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/notifications/${roomId}/${notificationId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}
export const getNotifAllImages = async (getToken, notificationId, roomId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/notifications/images/${roomId}/${notificationId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getCustomAssignments = async (getToken, userId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/monitor/teams/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const assignMonitor = async (getToken, roomId, userId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.post(
    '/v1/monitor/assign',
    {
      userId,
      roomId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const unassignMonitor = async (getToken, roomId, userId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.put(
    '/v1/monitor/unassign',
    {
      userId,
      roomId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getMonitorAssignments = async (getToken, userId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/admin/monitorAssignments/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const updateActivity = async (getToken, userId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.put(
    '/v1/users/activity',
    {
      userId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const addFieldsToUser = async (getToken, userId, updatedFields) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.put(
    `/v1/users/${userId}`,
    {
      updatedFields,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const updateRoom = async (getToken, roomId, params) => {
  try {
    const jwt = await getToken()
    const newRoom = await Api.put(
      `/v1/rooms/${roomId}`,
      {
        newRoom: {
          ...params,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
    return newRoom
  } catch (err) {
    console.error('ERR updating room')
  }
}

export const createUser = async (getToken, user) => {
  try {
    const jwt = await getToken({ audience: auth0Audience, })

    const res = await Api.post(
      '/v1/admin/newUser',
      {
        ...user,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )

    return res && res.data
  } catch (err) {
    return err
  }
}

export const createBeacon = async (getToken, user) => {
  try {
    const jwt = await getToken({ audience: auth0Audience, })

    const res = await Api.post(
      '/v1/admin/newBeacon',
      {
        ...user,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )

    return res && res.data
  } catch (err) {
    return err
  }
}

export const requestImages = async (getToken, floor, name) => {
  if (!floor || !name) return
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/monitor/images/${floor}/${name}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return res && res.data
}

export const requestImagesCustom = async (getToken, userId) => {
  const jwt = await getToken({ audience: auth0Audience, })

  const res = await Api.get(
    `/v1/monitor/teams/images/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const updateConfig = async (getToken, roomId, points, lastVersion, other, enable) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put('/v1/monitor/regions/config',
    {
      roomId,
      points,
      lastVersion,
      other,
      enable,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getConfig = async (getToken, roomId) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/monitor/regions/config/${roomId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const deleteRegion = async (getToken, roomId, region, lastVersion) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.delete('/v1/monitor/regions/config',
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        roomId,
        region,
        lastVersion,
      },
    }
  )

  return res && res.data
}

export const createTeam = async (getToken, name) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.post('/v1/admin/team',
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}
export const postBedToTeam = async (getToken, bed, team) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.post('/v1/admin/bulkAssignRoomToTeam',
    {
      team,
      bed,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}
export const deleteBedFromTeam = async (getToken, bed, team) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.delete('/v1/admin/deleteRoomFromTeam',
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        team,
        bed,
      },
    }
  )

  return res && res.data
}
export const deleteUserFromTeam = async (getToken, user, team) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.delete('/v1/admin/deleteUserFromTeam',
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        team,
        user,
      },
    }
  )

  return res && res.data
}
export const postUserToTeam = async (getToken, user, team) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.post('/v1/admin/bulkAssignUserToTeam',
    {
      team,
      user,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}
export const deleteSelectedTeam = async (getToken, name) => {
  try {
    const jwt = await getToken({ audience: auth0Audience, })
    const res = await Api.delete('v1/admin/team/delete',
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        data: {
          name,
        },
      }
    )
    return res && res.data
  } catch (err) {
    console.error('ERROR DELETING SELECTED TEAM', err)
  }
}
export const getTeams = async getToken => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get('/v1/monitor/teams',
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
  return res && res.data
}
export const getSelectedTeam = async (getToken, team) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/admin/teamAssignments/${team}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}

export const getRoomTeamAssignments = async (getToken, roomIds) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.get(`/v1/admin/roomTeamAssignments/${roomIds}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}
export const updateSlackChannel = async (getToken, basestation, mute) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put(
    '/v1/admin/muteBasestation',
    {
      basestation,
      mute,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
  return res && res.data
}
export const dismissZoneConfig = async (getToken, roomId, enable) => {
  const jwt = await getToken({ audience: auth0Audience, })
  const res = await Api.put('/v1/monitor/regions/config/dismiss',
    {
      roomId,
      enable,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return res && res.data
}
