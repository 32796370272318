import React from 'react'
import { useSpring, animated as a } from 'react-spring'

const AlertBorder = ({ animate, children, flipped, alert, room, transform, opacity, }) => {
  const { x, } = useSpring({
    x: animate ? 1 : 0,
    config: { duration: 3000, },
    loop: animate,
  })
  return (
    <a.div
      style={{
        opacity: alert ? '' : opacity.to((o) => 1 - o),
        transform: alert || room?.enable?.active
          ? x
            .to({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1,],
              output: [1, 0.97, 0.93, 1.07, 0.93, 1.07, 1.03, 1,],
            })
            .to(x => `scale(${x})`)
          : transform,
        border: room?.enable?.active ? '2px solid #10CAF0' : '',
      }}
    >
      {
        children
      }
    </a.div>
  )
}
export default AlertBorder
