import { getJwtToken } from '../../../utility'
import { Api } from '../../../utility/client'

export async function stopTestingSessionForUnit (getToken, unitId: string) {
  const jwt = await getJwtToken(getToken)

  await Api.post(
    '/v1/vbase/stop',
    { unitId, },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
}
