import React, { useState } from 'react'
import moment from 'moment'
import { Timeline, Card, Image, PageHeader, Button, Popconfirm } from 'antd'
import {
  DatabaseOutlined,
  WarningTwoTone,
  CheckSquareTwoTone,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons'
import 'react-vertical-timeline-component/style.min.css'
import Loading from './Loading'
import { mapEventToText, mapLabelToText, mapNotifToText } from '../rules'
import { formatTimeString } from '../utility/helpers'

const { Meta, } = Card
const warningIcon = <WarningTwoTone style={{ fontSize: '24px', }} twoToneColor={'red'}/>
const eventIcon = <CheckSquareTwoTone style={{ fontSize: '24px', }} twoToneColor={'lightblue'}/>
const playIcon = <PlayCircleOutlined style={{ fontSize: '38px', }} twoToneColor={'black'}/>
const pauseIcon = <PauseCircleOutlined style={{ fontSize: '38px', }} twoToneColor={'black'}/>

let timeouts: any = []

function Review ({ notification, timeline, renderCount, label, loading, skip, allPics = [], } : any) {
  const [playing, setPlaying,] = useState(false)
  const [ind, updateInd,] = useState(0)
  const flipSpeed = 300

  const rawTime = notification && notification.id

  const time = rawTime && moment(rawTime).format('dddd, MMMM Do YYYY, h:mm:ss a')

  const currentPic = allPics && allPics.length ? allPics[ind] : null

  const switchPic = async (delay: number, reset = false) => {
    timeouts.push(setTimeout(
      () => {
        if (reset) {
          updateInd(0)
          setPlaying(false)
        } else updateInd(delay / flipSpeed)
      }, delay))
  }

  const startPlaying = () => {
    if (playing) {
      setPlaying(false)
      for (let i = 0; i < timeouts.length; i++) {
        clearTimeout(timeouts[i])
      }
      timeouts = []
      updateInd(0)
    } else {
      setPlaying(true)

      allPics.forEach((p, i) => {
        if (i === allPics.length - 1) {
          switchPic(i * flipSpeed, true)
        } else {
          switchPic(i * flipSpeed)
        }
      })
    }
  }

  // useEffect(() => {
  //     function initialize(){
  //         if(allPics && allPics.length) {
  //             allPics.forEach(p => {
  //             //     const img = new (Image as any)({
  //             //         src: p.imgUrl
  //             //     })
  //             })
  //         }
  //     }
  //     initialize()
  // }, [allPics])

  if (!timeline || loading) return <Loading type='bars'/>

  let beforePics = []
  // let afterPics = []

  if (allPics && allPics.length && timeline.length) {
    beforePics = allPics.filter((p: any) => p.time < timeline[0].id)
    // afterPics = allPics.filter((p: any) => p.time > timeline[timeline.length - 1].id)
  }

  const notificationTime = notification && notification.id || null

  const processEvent = (event: any, index: number) => {
    const text = event.type !== 'image' ? mapEventToText(event) : 'Image'
    const diff = notificationTime && notificationTime - event.time || 0
    const isNotification = diff === 0

    const icon = isNotification ? warningIcon : eventIcon

    const timestamp = formatTimeString(diff)

    return (
            <Timeline.Item dot={icon}>
                {/* <div style={{ textAlign: 'left', width: '500px' }}>
                    <div>
                        <div style={{ width: 250 }}>{text}</div>
                        <div style={{ width: 250 }}>{timestamp}</div>
                    </div>
                    <img style={{ width: 250 }} src={'https://via.placeholder.com/200'}/>
                </div> */}
                <div style={{ textAlign: 'left', width: '500px', }}>
                    <Card
                        hoverable
                        style={{ width: 250, }}
                        cover={<Image alt="example" src={event.imgUrl || 'https://via.placeholder.com/800'} />}
                    >
                        <Meta title={text} description={timestamp} />
                    </Card>
                </div>
            </Timeline.Item>
    )
  }

  return (
        <>
            <div className="Playback">
                <h3>Playback <h5>({allPics.length} images)</h5></h3>
                <Image src={currentPic && currentPic.imgUrl || ''} width={250}/>
                <div style={{ marginTop: 10, }} onClick={startPlaying}>
                    { playing ? pauseIcon : playIcon}
                </div>
            </div>
            <PageHeader
                className="site-page-header"
                style={{ width: '100%', marginBottom: 30, }}
                title={`${mapNotifToText(notification.type) || notification.type} in ${notification.room.split('-').slice(5).join()} at ${notification.room.split('-')[1]}`}
                subTitle={`Occured ${time}, Label: ${mapLabelToText(notification.label) || 'None'}`}
                extra={[
                    <Popconfirm placement="topLeft" title={'Are you sure you want to label as a True Positive?'} okText="Yes" cancelText="No" onConfirm={() => label(notification.room, notification.id, 'truePositive')} key={0}>
                        <Button type="primary">True Positive</Button>
                    </Popconfirm>,
                    <Popconfirm placement="topLeft" title={'Are you sure you want to label as a False Positive?'} okText="Yes" cancelText="No" onConfirm={() => label(notification.room, notification.id, 'falsePositive')} key={1}>
                        <Button type="primary" danger>False Positive</Button>
                    </Popconfirm>,
                    <Popconfirm placement="topLeft" title={'Are you sure you want to label as a True Negative?'} okText="Yes" cancelText="No" onConfirm={() => label(notification.room, notification.id, 'trueNegative')} key={2}>
                        <Button>True Negative</Button>
                    </Popconfirm>,
                    skip && <Button type="dashed" onClick={skip}>Skip</Button>,
                ]}
            />
            <Timeline mode="alternate">
                {beforePics.length && <Timeline.Item dot={DatabaseOutlined}></Timeline.Item>}
                { timeline.map(processEvent) }
            </Timeline>
        </>
  )
}

export default Review
