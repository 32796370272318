import React, { useState } from 'react'
import { Button, Modal, Input, Table, Popconfirm, message, Space } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { UsergroupAddOutlined, TeamOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import Team from '../../../components/team/Team'
import { setTeam, createTeam, deleteTeam } from '../../../state/actions'
// import TeamLoading from '../../../components/loading/teamLoading'
import Loading from '../../../components/Loading'
import Highlighter from 'react-highlight-words'
// this array is the 2 step navigation
const steps = ['allTeams','singleTeam',]
const Teams = ({ team, teams, dispatch, beds, users, renderCheck, currentAssignments, setTeamLoading, deleteLoading, bedAssignmentLoading, userAssignmentLoading, orgUsersLoading, updateUsers, }) => {
  // creating a state for the selected team to be deleted
  const [deleteTeamselected,setTeamDelete,] = useState({})
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const [sorters, updateSorters,] = useState(null)
  const [filters, updateFilters,] = useState(null)
  const [searchText, setSearchText,] = useState('')
  const [searchedColumn, setSearchedColumn,] = useState('')
  let searchInput
  // states for modal visibility
  const [isModalVisible, setIsModalVisible,] = useState(false)
  // state to determine step in navigation
  const [step,setStep,] = useState(steps[0])
  // handler methods for moving to next and prev step

  const nextStep = async (text) => {
    dispatch(setTeam({
      team: text,
      getToken,
    }))
    setStep(steps[1])
  }
  const prevStep = () => {
    setStep(steps[0])
  }
  // states for user input text in modal
  const [inputText,setText,] = useState('')
  // create team modal handlers
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    dispatch(createTeam({
      team: inputText.toLowerCase(),
      getToken,
    }))
    setIsModalVisible(false)
    setText('')
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    setText('')
  }
  const handleInputChange = (e) => {
    setText(e.target.value)
  }
  function confirm (record) {
    setTeamDelete(record)
    dispatch(deleteTeam({
      team: record.mainId,
      getToken,
    }))
    message.success(`${record.mainId} deleted`)
  }
  function cancel (record) {
    message.error(`${record.mainId} was not deleted`)
  }
  const updateTable = (pagination, filters, sorters) => {
    updateSorters(sorters)
    updateFilters(filters)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true, })
    setSearchText('')
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8, }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value,] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90, }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: (text) => {
      if (!text) return ''
      const showDeleteText = text === deleteTeamselected.mainId && deleteLoading
      if (searchedColumn === dataIndex) {
        return showDeleteText ? <div>{text.split('-')[1]}</div> : <div className='team-link' onClick={() => nextStep(text)}><Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0, }} searchWords={[searchText,]} autoEscape textToHighlight={text.split('-')[1] ? text.split('-')[1].toString() : ''} /></div>
      } else {
        return showDeleteText ? <div>{text.split('-')[1]}</div> : <div className='team-link' onClick={() => nextStep(text)}>{text.split('-')[1]}</div>
      }
    },
  })
  // column
  const columns = [
    {
      title: 'Team Name',
      dataIndex: 'mainId',
      key: 'mainId',
      width: 450,
      sorter: (a,b) => a.mainId > b.mainId ? 1 : b.mainId > a.mainId ? -1 : 0,
      sortOrder: sorters && sorters.columnKey === 'mainId' && sorters.order || null,
      filteredValue: filters && filters.mainId || null,
      onFilter: (value, record) => !value ? record.mainId : record.mainId.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('mainId'),
    },
    {
      title: 'Number of Beds',
      dataIndex: 'numBeds',
      key: 'numBeds',
      width: 200,
      sorter: (a,b) => a.numBeds > b.numBeds ? 1 : b.numBeds > a.numBeds ? -1 : 0,
      sortOrder: sorters && sorters.columnKey === 'numBeds' && sorters.order || null,
    },
    {
      title: 'Number of Users',
      dataIndex: 'numUsers',
      key: 'numUsers',
      width: 200,
      sorter: (a,b) => a.numUsers > b.numUsers ? 1 : b.numUsers > a.numUsers ? -1 : 0,
      sortOrder: sorters && sorters.columnKey === 'numUsers' && sorters.order || null,
    },
    {
      title: 'Organizations Represented',
      dataIndex: 'orgs',
      key: 'orgs',
      render: (text) => {
        if (!text) {
          return ''
        }
        const textArray = []
        for (const t of text) {
          textArray.push(t)
          textArray.push(', ')
        }
        textArray.pop()
        return <div>{textArray}</div>
      },
    },
    {
      title: '',
      key: 'operation',
      width: 75,
      render: (value,record) => (
        <div className='team-popconfirm'>
          <Popconfirm
    title={`Are you sure you want to delete ${record.mainId}`}
    onConfirm={() => confirm(record)}
    onCancel={() => cancel(record)}
    okText="Yes"
    cancelText="No"
    disabled={deleteLoading}
  >
    <a href="#" className='team-delete-link'>
      {
        record.mainId === deleteTeamselected.mainId && deleteLoading ? <Loading type='spin' height='30' width='30'/> : <DeleteOutlined/>
      }
      </a>
  </Popconfirm>
        </div>
      ),
    },
  ]
  if (!renderCheck) return null
  return (
        <div className="page-container-col">
          {
            orgUsersLoading
              ? <Loading type='bars' />
              : <div>
          {
            step === 'allTeams'
              ? <div>
            <div>
            <h1>Teams</h1>
        </div>
        <div className='teams-button-container'>
            <Button type="primary" icon={<UsergroupAddOutlined />} onClick={showModal}>Create</Button>
        </div>
        <Modal title="Create New Team" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <div>
                <Input placeholder="Team Name ..." prefix={<TeamOutlined />} onChange={handleInputChange} value={inputText} autoFocus={true}/>
            </div>
        </Modal>
        <div className='teams-table'>
            <Table dataSource={teams || [] } columns={columns || []} size='large' onChange={updateTable}/>
        </div>
            </div>
              : <div>
                <Team setTeamLoading={setTeamLoading} prevStep={prevStep} team={team} beds={beds} users={users} dispatch={dispatch} currentAssignments={currentAssignments}
                bedAssignmentLoading={bedAssignmentLoading} userAssignmentLoading={userAssignmentLoading} updateUsers={updateUsers}
                />
        </div>

          }
          </div>
        }
        </div>
  )
}
export default Teams
