import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { connect } from 'react-redux'
import Login from './Login'
import Home from './screens/Home'
import Series from './screens/Series'
import Analytics from './screens/Analytics'
import Train from './screens/Train'
import Report from './screens/Report'
import Monitor from './screens/Monitor'
import Basestations from './screens/Basestations'
import Notification from './screens/Notification'
import Admin from './screens/Admin'
import ProtectRoute from './HOC/protectedRoute'
import Loading from './components/Loading'
import { fetchUser } from './state/actions'
import { setAmpUserId } from './utility/analytics'
import './App.css'

function App ({ dispatch, appUser, }: any) {
  const { isAuthenticated, isLoading, getAccessTokenSilently: getToken, user, } = useAuth0()
  useEffect(() => {
    if (user && user?.email) {
      dispatch(fetchUser({ getToken, email: user?.email, fname: user?.given_name, lname: user?.family_name, }))
      setAmpUserId(user?.email)
    }
  }, [isAuthenticated,])

  if (isLoading) return <Loading type='bars'/>

  const monitorOrAdmin = appUser && appUser.role && ['monitor', 'admin', 'super-user',].includes(appUser.role)
  const superUserOrAdmin = appUser && appUser.role && ['super-user', 'admin',].includes(appUser.role)

  return (
    <div className="App">
      <Switch>
        <Route path="/login" component={Login} />
        { !isAuthenticated ? <Redirect to="/login" /> : null }
        <ProtectRoute path="/main" component={Home} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/analytics" component={Analytics} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/train" component={Train} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/series" component={Series} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/report" component={Report} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/basestations" component={Basestations} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/admin" component={Admin} otherCheck={superUserOrAdmin}/>
        <ProtectRoute path="/monitor" component={Monitor} otherCheck={monitorOrAdmin} />
        <ProtectRoute path="/notification" component={Notification} otherCheck={monitorOrAdmin} />
        <Redirect to='/main/table' />
      </Switch>
    </div>
  )
}

export default connect((state: any) => ({
  appUser: state.mainReducer.user,
}))(App)
