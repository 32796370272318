import React from 'react'
import { Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import colors from '../styles/colors'

function Loading ({ title = 'select me', options, onSelect, }: { title?: string, options: any, onSelect: any }) {
  const menu = (
    <Menu>
        {options.map((o) => {
          return <Menu.Item key={o} onClick={(e) => onSelect(e.key)}>{o}</Menu.Item>
        })}
    </Menu>
  )
  return (
    <Dropdown
        overlay={menu}
        overlayStyle={{ color: colors.lightGray, }}
        placement="bottom"
        // onVisibleChange={this.handleVisibleChange}
        // visible={this.state.visible}
      >
        <div id="clickable">
          {title} <DownOutlined />
        </div>
      </Dropdown>
  )
}

export default Loading
