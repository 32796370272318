import React, { useState, useEffect, useMemo } from 'react'
import {
  SyncOutlined,
  SwapOutlined,
  ReadOutlined,
  InfoCircleOutlined,
  RightOutlined,
  LeftOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  PlusOutlined,
  MinusOutlined,
  RadiusSettingOutlined
} from '@ant-design/icons'
import { Image, Popover, Popconfirm } from 'antd'
import { useSpring, animated as a } from 'react-spring'
import { mapFallRiskText, mapNotifToText } from '../../../rules'
import useInteraction from '../../../hooks/useInteraction'
import {
  adminRoomExtractor,
  checkIfOffline,
  checkForAlert,
  soundCheck,
  noteCheck,
  checkIfInPrivacyMode
} from '../../../utility/general'
import {
  extractImgTimestamp,
  convertIsoToUnix,
  unixDiff,
  getCounterSince
} from '../../../utility/helpers'
import ConfirmButton from '../../ConfirmButton'
import colors from '../../../styles/colors'
import {
  Room,
  User,
  Notification,
  CardSizes,
  ManualStates,
  augiEnable
} from '../../../state/types'
import { sendAmpEvent } from '../../../utility/analytics'
import CreateManualEvent from '../CreateManualEvent'
import Loading from '../../Loading'
import AlertBorder from '../../Layout/AlertBorder'
import { isNewAlert } from '../../../screens/Monitor/helpers'
import HoverButtons from '../HoverButtons'
import { RoomDisabledView } from './RoomDisabledView'
import { RoomOfflineView } from './RoomOfflineView'
import { RoomPrivacyModeOnView } from './RoomPrivacyModeOnView'
import { SlackNotificationsConfirmButton } from './SlackNotificationsConfirmButton'
type FeedProps = {
  room: Room;
  user: User;
  currentRoom: Room | null;
  notif: Notification;
  promote: (roomId: string, notifId: number) => void;
  resolve: (roomId: string, notifId: number) => void;
  reject: (roomId: string, notifId: number) => void;
  getNotif: (roomId: string, notifId: number) => void;
  falseNeg: (roomId: string, type: string) => void;
  report: (roomId: string, newState: ManualStates, enable: augiEnable) => void;
  openNotes: () => void;
  openCanvas: () => void;
  togglePic: (showPic: boolean, playbackRoom: Room) => void;
  togglePlaybackPic: (showPic: boolean) => void;
  updateRoom: (room: Room | null) => void;
  toggleHide: (show: boolean) => void;
  assignMonitor: (mainId: string, custom?: boolean) => void;
  playSound: (soundPath: string) => void;
  lastNoteView: string | null;
  hide: boolean;
  custom: boolean;
  notifLoading: boolean;
  cardSize: CardSizes;
};

export const Feed = ({
  room,
  user,
  currentRoom,
  promote,
  resolve,
  reject,
  report,
  falseNeg,
  openNotes,
  openCanvas,
  togglePic,
  togglePlaybackPic,
  updateRoom,
  toggleHide,
  assignMonitor,
  getNotif,
  playSound,
  hide = false,
  notif,
  notifLoading,
  loading = null,
  lastNoteView = null,
  cardSize = 'medium',
  custom = false,
  staffNames,
  slackUpdateLoading,
}: FeedProps) => {
  const offline = checkIfOffline(room.keepAlive)
  const privacy = checkIfInPrivacyMode(room.priv)
  const { alert, level, notifId, } = checkForAlert(room)
  const timeSinceAlert = getCounterSince(room?.pId)
  const timeSinceAdminAlert = getCounterSince(notifId)
  const { timeSinceMovement, timeUnchecked, status, } = adminRoomExtractor(
    staffNames,
    room
  )
  const { mainId, fallRiskLevel, lastMode, } = room
  const [, org,] = mainId ? mainId.split('-') : []
  const [visible, setVisible,] = useState(false)
  const substring = org === 'GENERATIONS' ? 'GN' : org.substring(0, 2)

  const newNote = noteCheck(lastNoteView, room)
  const interacted = useInteraction()
  const frText = mapFallRiskText(fallRiskLevel, cardSize)
  // New Alert Animation
  const [lastAlert, setAlert,] = useState<string | null>(null)
  const [animate, toggle,] = useState(true)
  const [flipped, flip,] = useState(false)
  const [notifInd, updateInd,] = useState(4)

  const isAdmin = useMemo(() => user?.role === 'admin', [user?.role,])

  const flipCard = (dir) => {
    flip(dir)
    sendAmpEvent('flip card', { room: room.mainId, })
  }
  // Adjusted width / heights based on card size
  let imageHeight = 275
  let flippedImgWidth = '45%'
  switch (cardSize) {
    case 'extra-small':
      imageHeight = 205
      flippedImgWidth = '50%'
      break
    case 'small':
      flippedImgWidth = '55%'
      break
    case 'large':
      flippedImgWidth = '55%'
      imageHeight = 300
      break
    default:
      break
  }
  // const flippedImgWidth = cardSize === 'large' || cardSize.includes('small') ? '55%' : '45%'
  // const buttonWidth = cardSize === 'small' ? 60 : 40

  const arrowClick = (dir) => {
    if (!notif || !notif.allImages || !notif.allImages.length) return
    let nextInd

    switch (dir) {
      case 'start':
        nextInd = 0
        break
      case 'end':
        nextInd = notif.allImages.length - 1
        break
      case 'forward':
        nextInd = Math.min(notif.allImages.length - 1, notifInd + 1)
        break
      case 'backward':
        nextInd = Math.max(0, notifInd - 1)
        break
      default:
        nextInd = notifInd
        break
    }

    return updateInd(nextInd)
  }

  const updateNotif = () => {
    getNotif(room.mainId, notifId)
  }

  const openNotif = (vis) => {
    if (vis) {
      updateNotif()
      updateInd(4)
    }
  }
  useEffect(() => {
    async function effect () {
      if (alert && !lastAlert) {
        toggle(!animate)
        flip(false)
        setAlert(alert)
        if (notifId) {
          getNotif(room.mainId, notifId)
        }
        // play sound if a new alert comes through in a room
        if (isNewAlert(user, interacted, alert, room)) {
          playSound(`${window.origin}/alarming.mp3`)
        }

        // if (currentRoom && room.mainId !== currentRoom.mainId) {
        //   setTimeout(() => {
        //     toggleHide(false)
        //   }, 1000)
        // }
        updateRoom(null)
        return
      }

      // play sound if a fall comes in, even if there is an existing alert
      if (
        alert === 'fall' &&
        lastAlert !== 'fall' &&
        soundCheck(user) &&
        interacted
      ) {
        playSound(`${window.origin}/alarming.mp3`)
        setAlert(alert)
        toggle(!animate)
      }

      if (alert && alert !== 'fall' && lastAlert === 'fall') {
        setAlert(alert)
      }

      if (lastAlert && !alert) {
        setAlert(null)
      }
      if (currentRoom && room.mainId === currentRoom.mainId) {
        updateRoom(room)
      }
    }
    effect()
  }, [room,])

  // Flip card Animation

  const { transform, opacity, } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80, },
  })

  const generatePrompt = (alert, title) => {
    const standardPrompt = `Are you sure you want to ${title} this notification?`
    if (alert === 'fall') {
      return `***THIS IS A FALL***: ${standardPrompt}`
    }
    return standardPrompt
  }

  const level0Options = (
    <>
      <ConfirmButton
        prompt={generatePrompt(alert, 'Promote')}
        title={'Promote'}
        okText={alert === 'fall' ? 'Promote Fall' : 'OK'}
        onPress={() => promote(room.mainId, room.adminNotificationId)}
        textColor="white"
        style={{ marginRight: 4, }}
        loading={loading && loading === `promote-${mainId}`}
        size={cardSize}
      />
      <ConfirmButton
        prompt={generatePrompt(alert, 'Reject')}
        title={'Reject'}
        onPress={() => reject(room.mainId, room.adminNotificationId)}
        textColor="white"
        danger={true}
        confirm={false}
        loading={loading && loading === `resolve-${mainId}`}
        size={cardSize}
      />
    </>
  )

  const level1Options = (
    <ConfirmButton
      prompt={'Are you sure you want to Resolve this notification?'}
      title={'Resolve'}
      onPress={() => resolve(room.mainId, room.notificationId)}
      textColor="white"
      loading={loading && loading === `resolve-${mainId}`}
      size={cardSize}
    />
  )

  const options = alert && level === 1 ? level1Options : level0Options
  const disabledContent = <RoomDisabledView
    cardSize={cardSize}
    showAdminActions={isAdmin}
    room={room}
  />

  const offlineItem = <RoomOfflineView cardSize={cardSize} showAdminActions={isAdmin} room={room}/>

  const privacyModeOn = <RoomPrivacyModeOnView cardSize={cardSize} room={room}/>
  const op = opacity.get()

  // const stayVisible = currentRoom && currentRoom.mainId === room.mainId

  // Report false negative (Fall, OB, LB)
  // Report patient update (Room, Bed, Staff, Empty)

  const calcNotifContent = () => {
    const imgs = notif && notif.allImages
    if (!notif || !imgs) {
      return (
        <>
          <Image
            width={200}
            height={200}
            src={room.live && room.live}
            preview={{
              src: room.live && room.live,
              rootClassName: 'preview-img-caption',
            }}
            onClick={() => toggleHide(false)}
          />
        </>
      )
    }
    const { id, } = notif
    const img = imgs[notifInd]
    const source = img && img.url
    const refTimestamp = id && convertIsoToUnix(id)
    const imgTimestamp = source && extractImgTimestamp(source)
    // saving this here for the zone sweep update
    // {room?.mute ? <Badge className="grid-time-unchecked-label-icon" count={<ClearOutlined/>}/> : null}
    const desc = imgTimestamp && unixDiff(refTimestamp, imgTimestamp)
    return (
      <div className="noSelect">
        <div>
          <b>{mapNotifToText(alert)}</b> at {new Date(id).toLocaleTimeString()}
        </div>
        <div className="feed-notification-description">{desc}</div>
        <SyncOutlined
          className="clickable"
          spin={notifLoading}
          style={{ position: 'absolute', top: 16, right: 4, }}
          onClick={updateNotif}
        />
        <Image
          preview={{
            visible,
            rootClassName: 'preview-img-caption',
          }}
          width={250}
          height={250}
          src={source && source}
          onClick={() => {
            setVisible(true)
            togglePic(true, room)
            togglePlaybackPic(true)
          }}
        />
        <div
          style={{
            display: 'none',
          }}
        >
          <Image.PreviewGroup
            preview={{
              visible,
              onVisibleChange: (vis) => {
                setVisible(vis)
                togglePic(vis, room)
                togglePlaybackPic(vis)
              },
              countRender: (current) => {
                // have to update desc locally because preview is another component, both components can not update at the same time
                const { id, } = notif
                const img2 = imgs[current - 1]
                const source2 = img2 && img2.url
                const refTimestamp2 = id && convertIsoToUnix(id)
                const imgTimestamp2 = source2 && extractImgTimestamp(source2)
                const desc =
                  imgTimestamp2 && unixDiff(refTimestamp2, imgTimestamp2)
                return 'Room ' + room.name + ' - ' + desc
              },
              rootClassName: 'preview-img-caption',
            }}
          >
            {notif.allImages.map((i) => {
              return <Image src={i.url} key={i.url} />
            })}
          </Image.PreviewGroup>
        </div>
        <div className="feed-notification-playback-arrow-container">
          {(!!notifInd && (
            <div>
              <DoubleLeftOutlined
                className="clickable"
                onClick={() => arrowClick('start')}
                style={{ marginRight: 4, }}
              />
              <LeftOutlined
                className="clickable"
                onClick={() => arrowClick('backward')}
              />
            </div>
          )) || <div />}
          {notifInd < imgs.length - 1 && (
            <div>
              <RightOutlined
                className="clickable"
                onClick={() => arrowClick('forward')}
                style={{ marginRight: 4, }}
              />
              <DoubleRightOutlined
                size={29}
                className="clickable"
                onClick={() => arrowClick('end')}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  const image = (
    <div>
      <AlertBorder
        animate={animate}
        room={room}
        alert={alert}
        flipped={flipped}
        transform={transform}
        opacity={opacity}
      >
        <Image
          width={op === 0 || !flipped ? '100%' : 0}
          height={op === 0 || !flipped ? imageHeight : 0}
          src={room.live && room.live}
          onClick={() => toggleHide(false)}
          preview={{
            visible: undefined,
            rootClassName: 'preview-img-caption',
            onVisibleChange: (vis) => {
              togglePic(vis, room)
            },
          }}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: 18,
            marginTop: 4,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              marginRight: 4,
              fontSize: cardSize === 'extra-small' ? 12 : 14,
            }}
          >
            <b>Status:</b> {status}
          </div>

          {frText && (
            <div
              className="bottom-left"
              id="fall-risk"
              data-size={cardSize}
              data-label={frText}
            >
              {frText}
            </div>
          )}
          {newNote && (
            <div
              className="clickable bottom-left"
              id="note-icon"
              data-size={cardSize}
              onClick={openNotes}
            >
              <ReadOutlined />
            </div>
          )}
        </div>
      </AlertBorder>
      <a.div
        style={{
          pointerEvents: flipped ? 'auto' : 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 3,
          opacity,
          transform: transform.to((t) => `${t} rotateX(180deg)`),
        }}
      >
        <div
          style={{
            zIndex: flipped ? 4 : -1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <div
            className="clickable"
            style={{ position: 'absolute', top: 2, right: 4, }}
          >
            <Popconfirm
              placement="right"
              title={`Do you want to ${
                custom ? 'remove' : 'add'
              } custom assignment?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => assignMonitor(mainId, custom)}
            >
              {custom ? <MinusOutlined /> : <PlusOutlined />}
            </Popconfirm>
          </div>
          <Image
            width={op === 1 || flipped ? flippedImgWidth : 0}
            style={{ maxWidth: 300, }}
            height={op === 1 || flipped ? '40%' : 0}
            src={room.live && room.live}
            onClick={() => toggleHide(false)}
          />
          <div className="start-column" data-size={cardSize}>
            <div className="standard-text" data-size={cardSize}>
              <b>RM:</b> {room && room.name}
            </div>
            <div className="standard-text" data-size={cardSize}>
              <b>Status:</b> {status}
            </div>
            <div className="standard-text no-es" data-size={cardSize}>
              <b>Movement:</b> {timeSinceMovement}
            </div>
            {frText && (
              <div id="fall-risk" data-size={cardSize} data-label={frText}>
                {frText}
              </div>
            )}
            <div className="row-container"></div>
          </div>
          <div>
            {/* { frText && <div className="standard-text switch" data-size={cardSize}>
                <div className="switch-text">HFR</div>
                <Switch
                  loading={loading && loading === `switch-${mainId}`}
                  size="small" checked={fallRiskLevel === 'medium' || fallRiskLevel === 'high'}
                  onClick={(checked) => toggleFr(room.mainId, checked)}
                />
              </div> } */}
          </div>
        </div>
        <CreateManualEvent
          falseNeg={falseNeg}
          loading={loading}
          room={room}
          cardSize={cardSize}
          mainId={room.mainId}
          report={report}
          flipped={flipped}
        />
        <div
          className="standard-text icon clickable"
          data-size={cardSize}
          onClick={openNotes}
        >
          <ReadOutlined /> Notes
        </div>
        <div
          className="standard-text icon clickable"
          data-size={cardSize}
          onClick={() => {
            openCanvas()
          }}
          style={{ color: room?.enable?.zoneUpdate ? '#10CAF0' : 'black', }}
        >
          <RadiusSettingOutlined /> Zone Update{' '}
          {room?.enable?.zoneUpdate ? 'Required' : ''}
        </div>
        <div className="standard-text icon clickable" data-size={cardSize}>
          {slackUpdateLoading
            ? (
              <Loading
                type="spin"
                height="16"
                width="16"
              />
              )
            : user?.role === 'admin'
              ? (
                <div className="row-container">
                  <SlackNotificationsConfirmButton room={room} iconSize="small"/>
                  <div> Mute Basestation</div>
                </div>
                )
              : null}
        </div>
      </a.div>
      {!alert && (
        <SwapOutlined
          className="clickable bottom-right"
          id="swap-icon"
          data-size={cardSize}
          onClick={() => flipCard(!flipped)}
        />
      )}
    </div>
  )
  const imageWithAlert = (
    <AlertBorder
      animate={animate}
      room={room}
      alert={alert}
      flipped={flipped}
      transform={transform}
      opacity={opacity}
    >
      {image}
      <div className="start-column">
        <div style={{ fontSize: 22, }}>{mapNotifToText(alert)} Alert</div>
        <div className="options-container">
          {alert !== 'early_warning' && alert !== 'urgent'
            ? (
              <div style={{ marginTop: 4, }}>{options && options}</div>
              )
            : null}
        </div>
        <div className="time-format-padding">
          {alert && level === 1
            ? (
              <div> {'' + timeSinceAlert + ' ago'}</div>
              )
            : (
              <div>{'' + timeSinceAdminAlert + ' ago'}</div>
              )}
        </div>
      </div>
      <Popover
        placement="bottomLeft"
        className="info"
        content={calcNotifContent()}
        trigger="click"
        onVisibleChange={openNotif}
      >
        <InfoCircleOutlined className="clickable" size={20} />
      </Popover>
    </AlertBorder>
  )
  // put usememo one for alert as well
  const content = room?.disable
    ? (
        disabledContent
      )
    : offline
      ? (
          offlineItem
        )
      : privacy
        ? (
            privacyModeOn
          )
        : alert
          ? (
              imageWithAlert
            )
          : !flipped && !visible
              ? (
                <Popover
                  content={
                    <HoverButtons
                      room={room}
                      openCanvas={openCanvas}
                      report={report}
                      loading={loading}
                      cardSize={cardSize}
                      colors={colors}
                    />
                  }
                  placement="bottom"
                  title={`Room ${room?.name}`}
                  color={room?.enable?.active ? '#10CAF0' : 'white'}
                >
                  {image}
                </Popover>
                )
              : (
                  image
                )
  return (
    <div
      id={alert}
      className="grid-item"
      data-status={offline ? 'offline' : alert && 'alert'}
      data-size={cardSize}
    >
      <div className="grid-label" data-status={lastMode}>
        {substring} {room && room.name}
      </div>
      {flipped
        ? null
        : <>
          <div className="grid-time-unchecked-label" data-status={lastMode}>
            {timeUnchecked}
          </div>
        </>}
      {content}
    </div>
  )
}
