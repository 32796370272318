import { call, put, delay, race, takeEvery, takeLatest, take } from 'redux-saga/effects'
import {
  getAnalytics,
  getTraining,
  labelEvent,
  getSeries,
  getStaffSeries,
  getEvents,
  labelNotification,
  getStaffAnalytics,
  getReport,
  addNotifDesc,
  getUser,
  getUnits,
  getUnit,
  promoteNotification,
  resolveNotification,
  reportFN,
  reportPU,
  addNote,
  getNotes,
  getBases,
  getBeacons,
  getOrgUsers,
  getOrgs,
  assignBeacon,
  getNotif,
  getNotifAllImages,
  getCustomAssignments,
  assignMonitor,
  unassignMonitor,
  getMonitorAssignments,
  updateActivity,
  addFieldsToUser,
  updateRoom,
  createUser,
  createBeacon,
  requestImages,
  requestImagesCustom,
  getNotifications,
  createTeam,
  getTeams,
  deleteSelectedTeam,
  getSelectedTeam,
  postBedToTeam,
  postUserToTeam,
  deleteBedFromTeam,
  deleteUserFromTeam,
  getRoomTeamAssignments,
  getStaffNames,
  updateSlackChannel,
  getRoomUnit, rejectNotification
} from '../utility'
import { sendAmpEvent } from '../utility/analytics'
import { getTimelineBounds, mapNotifToText } from '../rules'
import { filterBases } from '../utility/general'
import { convertUnixToIso, convertIsoToUnix, extractUnitFromRoom, isAfterExp } from '../utility/helpers'

export function * retroSagas () {
  yield takeLatest('UPDATE_ANALYTICS', queryAnalytics)
  yield takeLatest('UPDATE_TRAINING', queryTraining)
  yield takeEvery('LABEL_EVENT', labelPotential)
  yield takeLatest('UPDATE_SERIES', querySeries)
  yield takeEvery('LABEL_NOTIFICATION', labelActual)
  yield takeLatest('PREFETCH_TIMELINE', prefetchTimeline)
  yield takeLatest('UPDATE_REPORT', queryReport)
  yield takeLatest('UPDATE_NOTIFS', queryHomeTab)
  yield takeLatest('UPDATE_NOTIF_DESC', updateNotifDesc)
  yield takeLatest('UPDATE_TIMELINE', fetchTimeline)
  yield takeLatest('UPDATE_USER', fetchUser)
  yield takeLatest('UPDATE_UNITS', fetchUnits)
  yield takeLatest('UPDATE_UNIT', fetchUnit)
  yield takeLatest('PROMOTE_NOTIF', promoteNotif)
  yield takeLatest('RESOLVE_NOTIF', resolveNotif)
  yield takeLatest('REJECT_NOTIF', rejectNotif)
  yield takeLatest('FALSE_NEG', reportFalseNegative)
  yield takeLatest('PATIENT_UPDATE', reportPatientUpdate)
  yield takeLatest('SUBMIT_NOTE', submitNote)
  yield takeLatest('GET_NOTES', fetchNotes)
  yield takeLatest('UPDATE_BASES', fetchAllBases)
  yield takeLatest('UPDATE_ORG_USERS', fetchOrgUsers)
  yield takeLatest('UPDATE_INTERNAL_USERS', fetchMonitors)
  yield takeLatest('UPDATE_BEACON_ASSIGNMENT', updateBeaconAssignment)
  yield takeEvery('UPDATE_NOTIF', fetchNotification)
  yield takeEvery('VIEW_NOTE', viewNote)
  yield takeEvery('UPDATE_MONITOR_ASSIGNMENT', updateMonitorAssignment)
  yield takeEvery('GET_MONITOR_ASSIGNMENTS', fetchMonitorAssignments)
  yield takeEvery('UPDATE_USER_FIELDS', updateUser)
  yield takeEvery('UPDATE_FALL_RISK', updateFallRisk)
  yield takeEvery('BATCH_UPDATE_USERS', batchUpdateUsers)
  yield takeEvery('BATCH_UPDATE_BEACONS', batchUpdateBeacons)
  yield takeEvery('CREATE_TEAM', addTeam)
  yield takeLatest('SET_TEAMS', getAllTeams)
  yield takeEvery('DELETE_TEAM', deleteTeam)
  yield takeLatest('SET_TEAM', fetchTeam)
  yield takeEvery('ASSIGN_BED', assignTeamBed)
  yield takeEvery('ASSIGN_USER', assignTeamUser)
  yield takeEvery('UNASSIGN_BED', unassignTeamBed)
  yield takeEvery('UNASSIGN_USER', unassignTeamUser)
  yield takeEvery('GET_ROOM_ROW_TEAMS', fetchAssignedTeams)
  yield takeLatest('UPDATE_SLACK', updateSlack)
  yield takeLatest('UPDATE_ROOM', updateOrgRoomUnit)
  yield call(pollUnitWatcher)
}

export function * resetTableFilters (tableName = 'REPORT') {
  // when the user submits for new report set pagination back to default values
  yield put({ type: `SET_${tableName}_PAGINATION`, payload: { current: 1, pageSize: 20, }, })

  // when the user submits for new report set filters back to default values
  yield put({
    type: `SET_${tableName}_FILTERING`,
    payload: {
      name: null,
      type: null,
      resolvedByName: null,
      labeledByName: null,
      fr: null,
      resolvedBy: null,
      label: null,
      level: null,
    },
  })

  // when the user submits for new report set sorting back to default
  yield put({
    type: `SET_${tableName}_SORTING`,
    payload: {
      columnKey: 'name',
      field: 'name',
    },
  })
}

export function * queryAnalytics (action) {
  try {
    let { org, start, end, getToken, } = action.payload
    yield put({ type: 'ANALYTICS_LOADING', })
    start = start.unix()
    end = end.unix()
    const payload = yield call(getAnalytics, getToken, org, start, end)
    yield put({ type: 'ANALYTICS_QUERY_SUCCEEDED', payload, })

    const staffPayload = yield call(getStaffAnalytics, getToken, org, start, end)
    yield put({ type: 'STAFF_ANALYTICS_QUERY_SUCCEEDED', payload: staffPayload, })
    sendAmpEvent('Analytics Query', { start, end, org, })
  } catch (e) {
    yield put({ type: 'ANALYTICS_QUERY_FAILED', message: e.message, })
  }
}

export function * queryTraining (action) {
  try {
    let { start, org, end, type, getToken, page, loading, } = action.payload
    if (loading) yield put({ type: 'TRAINING_LOADING', })
    start = start.unix()
    end = end.unix()
    const payload = yield call(getTraining, getToken, org, type, start, end, page)
    yield put({ type: 'TRAINING_QUERY_SUCCEEDED', payload, })
    sendAmpEvent('Training Query', { start, end, org, type, })
  } catch (e) {
    yield put({ type: 'TRAINING_QUERY_FAILED', message: e.message, })
  }
}

export function * labelPotential (action) {
  try {
    const { room, getToken, time, label, email, } = action.payload
    // yield put({type: "TRAINING_LOADING"});
    const payload = yield call(labelEvent, getToken, room, time, label, email)
    yield put({ type: 'LABEL_EVENT_SUCCEEDED', payload, })
    sendAmpEvent('Label Potential Notification', { room, id: time, label, })
  } catch (e) {
    yield put({ type: 'LABEL_EVENT_FAILED', message: e.message, })
  }
}

export function * labelActual (action) {
  try {
    const { room, getToken, id, label, email, prefetch, series, } = action.payload
    const event = series[0]
    const dynamicQuery = event.newLoc.includes('staff') || event.oldLoc.includes('staff') ? labelEvent : labelNotification

    const payload = yield call(dynamicQuery, getToken, room, id, label, email)
    yield put({ type: 'UPDATE_NOTIFICATION', payload, })
    yield put({ type: 'SWAP_TIMELINE', })

    if (prefetch) {
      for (let i = 2; i < 12; i++) {
        if (series && series[i]) {
          yield call(prefetchTimeline, { payload: { nextEvent: series[i], getToken, }, })
        }
      }
    }

    sendAmpEvent('label', { room, id, label, })
  } catch (e) {
    yield put({ type: 'LABEL_EVENT_FAILED', message: e.message, })
  }
}

export function * querySeries (action) {
  const { getToken, start, end, org, type, } = action.payload
  const dynamicQuery = type.includes('staff') ? getStaffSeries : getSeries

  const series = yield call(dynamicQuery, getToken, org, type, start, end)

  yield put({ type: 'SET_SERIES_NOTIFS', payload: series, })

  const first = series[0] && series[0]

  yield put({ type: 'UPDATE_NOTIFICATION', payload: first, })

  const bounds = getTimelineBounds(first && first.type)

  const timeline = yield call(getEvents, getToken, first.room, first.id, bounds)

  yield put({ type: 'SET_TIMELINE', payload: timeline, })
  yield put({ type: 'TIMELINE_LOADING', payload: false, })

  // const second = series[1] && series[1]
  yield put({ type: 'PREFETCHING_TIMELINE', payload: true, })
  for (let i = 0; i < 10; i++) {
    if (series && series[i]) {
      yield call(prefetchTimeline, { payload: { nextEvent: series[i], getToken, }, })
    }
  }
  yield put({ type: 'PREFETCHING_TIMELINE', payload: false, })
  sendAmpEvent('View Timeline Series', { start, end, org, type, })
}

export function * prefetchTimeline (action) {
  const { nextEvent, getToken, } = action.payload
  if (nextEvent) {
    const { id, room, type, } = nextEvent
    const bounds = getTimelineBounds(type)
    const nextTimeline = yield call(getEvents, getToken, room, id, bounds)
    yield put({ type: 'SET_NEXT_TIMELINE', payload: { timeline: nextTimeline, id, room, }, })
  }
}

export function * queryReport (action) {
  try {
    let { org, start, end, getToken, } = action.payload
    yield put({ type: 'REPORT_LOADING', })
    start = start.unix()
    end = end.unix()
    const rawPayload = yield call(getReport, getToken, org, start, end)
    const units = yield call(getUnits,getToken, org)
    const notifications = rawPayload.notifications.filter(n => {
      const { floor, name, } = extractUnitFromRoom(n?.room)
      const unit = units.find(unit => floor === unit.floor && name === unit.name)
      if (isAfterExp(n.id, unit.eventExp)) return false
      if (isAfterExp(n.id, unit.imageExp)) n.disable = true
      return true
    }).map((n) => {
      n.type = mapNotifToText(n.type)
      const moteTime = new Date(n.promotedOn)
      const rTime = new Date(n.resolvedAt)
      const ttrp = Math.round((rTime - moteTime) / 1000)
      n.ttrp = ttrp
      return n
    })
    const payload = { notifications, roomHash: rawPayload.roomHash, }
    yield put({ type: 'REPORT_QUERY_SUCCEEDED', payload, })
    yield resetTableFilters('REPORT')

    sendAmpEvent('Query Report', { start, end, org, })
  } catch (e) {
    console.error('err', e)
    yield put({ type: 'REPORT_QUERY_FAILED', message: e.message, })
  }
}

export function * queryHomeTab (action) {
  try {
    const { room, start, end, getToken, } = action.payload
    /** TODO: Implement loading state side effect here */
    yield put({ type: 'HOME_LOADING', payload: true, })
    const notifications = yield call(getNotifications, getToken, room, start, end)
    const payload = notifications
    yield put({ type: 'UPDATE_NOTIFICATIONS', payload, })

    /** TODO: Implement loading state side effect here */
    // yield put({ type: 'HOME_QUERY_SUCCEEDED', payload, })

    yield resetTableFilters('HOME')

    sendAmpEvent('Query Home Tab', { start, end, room, })
  } catch (e) {
    console.error('err', e)
    const payload = {
      homeLoading: false,
      homeQueryError: e.message,
    }
    /** TODO: Implement error handling in reducer */
    yield put({ type: 'HOME_QUERY_FAILED', payload, })
  }
}

export function * updateNotifDesc (action) {
  try {
    const { room, id, comment, staff_entry, getToken, } = action.payload
    yield call(addNotifDesc, getToken, room, id, comment, staff_entry)

    const payload = { room, id, comment, staff_entry, }
    yield put({ type: 'NOTIF_DESC_UPDATED', payload, })
    sendAmpEvent('Update Notif Desc', { room, id, staff_entry, length: comment.length, })
  } catch (e) {
    console.error('err', e)
    // yield put({type: "REPORT_QUERY_FAILED", message: e.message});
  }
}

export function * fetchTimeline (action) {
  try {
    const { notif, getToken, } = action.payload

    yield put({ type: 'UPDATE_NOTIFICATION', payload: notif, })

    yield put({ type: 'TIMELINE_LOADING', payload: true, })
    const { id, type, room, } = notif
    const bounds = getTimelineBounds(type)

    const payload = yield call(getEvents, getToken, room, convertIsoToUnix(id), bounds)
    yield put({ type: 'SET_TIMELINE', payload, })
    yield put({ type: 'TIMELINE_LOADING', payload: false, })
    sendAmpEvent('View Timeline', { notif, })
  } catch (e) {
    console.error('err', e)
  }
}

export function * fetchUser (action) {
  try {
    const { email, fname, lname, getToken, } = action.payload
    const payload = yield call(getUser, getToken, email, fname, lname)
    yield put({ type: 'SET_USER', payload, })
  } catch (e) {
    console.error('ERR FETCHING USER', e)
  }
}

export function * fetchUnits (action) {
  try {
    const { getToken, } = action.payload
    const units = yield call(getUnits, getToken)

    const addressHash = {}

    units.forEach(u => {
      const newUnit = { ...u, label: u.displayName || u.name, value: `${u.floor}-${u.name}`, }
      if (!addressHash[u.address]) {
        addressHash[u.address] = [newUnit,]
      } else {
        addressHash[u.address].push(newUnit)
      }
    })

    const transformedUnits = []
    for (const key in addressHash) {
      transformedUnits.push({ label: addressHash[key][0].floor.split('-')[0] + ' ' + key, value: key, children: addressHash[key], })
    }

    yield put({ type: 'SET_UNITS', payload: transformedUnits, })
    yield call(updateActivity, getToken)
  } catch (e) {
    console.error('ERR FETCHING UNITS', e)
  }
}

export function * fetchUnit (action) {
  try {
    yield put({ type: 'STOP_POLLING_UNIT', })
    yield put({ type: 'STOP_POLLING_IMAGES', })
    yield put({ type: 'UNIT_LOADING', payload: true, })
    const { getToken, unit, user, } = action.payload
    /** Different route for custom units */

    if (unit && unit[0] === 'custom') {
      const { rooms, assignments, } = yield call(getCustomAssignments, getToken, user.mainId)
      yield put({ type: 'REFRESH_UNIT', payload: { rooms, assignments, }, })
      yield put({ type: 'SET_UNIT', payload: { unit, }, })
      yield put({ type: 'POLL_UNIT', payload: { getToken, user, custom: true, }, })
      return
    } else {
      const unitProps = unit[1].split('-')
      const floor = unitProps.slice(0, -1).join('-')
      const name = unitProps[unitProps.length - 1]
      const { rooms, assignments, } = yield call(getUnit, getToken, floor, name)
      yield put({ type: 'REFRESH_UNIT', payload: { rooms, assignments, }, })
      yield put({ type: 'SET_UNIT', payload: { unit, }, })
      yield put({ type: 'POLL_UNIT', payload: { getToken, floor, name, }, })
      yield put({ type: 'REQ_UNIT_IMAGES', payload: { getToken, floor, name, }, })
      yield put({ type: 'STAFF_NAMES_LOADING', payload: true, })
      const res = yield call(getStaffNames, getToken, unit[1].split('-')[0])
      yield put({ type: 'SET_STAFF_NAMES', payload: res, })
      yield put({ type: 'STAFF_NAMES_LOADING', payload: false, })
    }
  } catch (e) {
    console.error('ERR FETCHING UNIT', e)
  }
}

export function * pollUnitWorker (action) {
  while (true) {
    try {
      const { getToken, floor, name, custom = false, user, } = action.payload
      if (custom) {
        const { rooms, assignments, } = yield call(getCustomAssignments, getToken, user.mainId)
        yield put({ type: 'REFRESH_UNIT', payload: { rooms, assignments, }, })
      } else {
        const { rooms: rawRooms, assignments, } = yield call(getUnit, getToken, floor, name)
        const rooms = rawRooms
        yield put({ type: 'REFRESH_UNIT', payload: { rooms, assignments, }, })
      }

      yield delay(1000)
    } catch (e) {
      console.error('ERR POLLING UNIT - WORKER', e)
    }
  }
}

export function * imageReqWorker (action) {
  while (true) {
    try {
      const { getToken, floor, name, custom, user, } = action.payload

      if (custom) {
        yield call(requestImagesCustom, getToken, user.mainId)
      } else {
        yield call(requestImages, getToken, floor, name)
      }

      yield delay(300000)
    } catch (e) {
      console.error('ERR IMAGE REQ WORKER', e)
    }
  }
}

export function * pollUnitWatcher () {
  while (true) {
    try {
      const action = yield take('POLL_UNIT')
      yield race([
        call(pollUnitWorker, action),
        call(imageReqWorker, action),
        take('STOP_POLLING_UNIT'),
      ])
    } catch (e) {
      console.error('ERR POLLING UNIT - WATCHER', e)
    }
  }
}

export function * promoteNotif (action) {
  try {
    const { notificationId, user, roomId, getToken, } = action.payload
    const notif = convertUnixToIso(notificationId)
    yield put({ type: 'PU_LOADING', payload: `promote-${roomId}`, })
    yield call(promoteNotification, getToken, roomId, user, notif)
    yield put({ type: 'PU_LOADING', payload: false, })
    // yield put({ type: "SET_USER", payload })
    sendAmpEvent('Promote Notif', { notif, room: roomId, })
    yield call(updateActivity, getToken)
    return
  } catch (e) {
    console.error('ERR PROMOTING NOTIF', e)
  }
}

export function * resolveNotif (action) {
  try {
    const { notificationId, user, roomId, getToken, } = action.payload
    const notif = convertUnixToIso(notificationId)
    yield put({ type: 'PU_LOADING', payload: `resolve-${roomId}`, })
    yield call(resolveNotification, getToken, roomId, user, notif)
    // yield put({ type: "SET_USER", payload })
    yield put({ type: 'PU_LOADING', payload: false, })
    sendAmpEvent('Resolve Notif', { notif, room: roomId, })
    yield call(updateActivity, getToken)
    return
  } catch (e) {
    console.error('ERR PROMOTING NOTIF', e)
  }
}

export function * rejectNotif (action) {
  try {
    const { notificationId, user, roomId, getToken, } = action.payload
    const notif = convertUnixToIso(notificationId)
    yield put({ type: 'PU_LOADING', payload: `resolve-${roomId}`, })
    yield call(rejectNotification, getToken, roomId, user, notif)
    // yield put({ type: "SET_USER", payload })
    yield put({ type: 'PU_LOADING', payload: false, })
    sendAmpEvent('Reject Notif', { notif, room: roomId, })
    yield call(updateActivity, getToken)
    return
  } catch (e) {
    console.error('ERR PROMOTING NOTIF', e)
  }
}

export function * reportFalseNegative (action) {
  try {
    const { roomId, getToken, flag, } = action.payload
    yield put({ type: 'FN_LOADING', payload: `${flag}-${roomId}`, })
    yield call(reportFN, getToken, roomId, flag)
    yield put({ type: 'FN_LOADING', payload: false, })
    sendAmpEvent('Report False Neg', { flag, room: roomId, })
    yield call(updateActivity, getToken)
    return
  } catch (e) {
    console.error('ERR REPORTING FALSE NEG', e)
  }
}

export function * reportPatientUpdate (action) {
  try {
    const { roomId, getToken, message, enable, } = action.payload
    yield put({ type: 'PU_LOADING', payload: message, })
    yield call(reportPU, getToken, roomId, message, enable)
    yield put({ type: 'PU_LOADING', payload: false, })
    sendAmpEvent('Manual Patient Update', { room: roomId, update: message, })
    yield call(updateActivity, getToken)
    return
  } catch (e) {
    console.error('ERR REPORTING PATIENT UPDATE', e)
  }
}

export function * submitNote (action) {
  try {
    const { roomId, getToken, note, } = action.payload
    yield put({ type: 'SUBMIT_NOTE_LOADING', payload: { loading: true, }, })
    yield put({ type: 'REFRESH_NOTES', payload: { roomId, newNote: { text: note, loading: true, }, }, })
    const newNote = yield call(addNote, getToken, roomId, note)
    yield put({ type: 'HYDRATE_NOTES', payload: { roomId, newNote, loading: false, }, })
    yield put({ type: 'SUBMIT_NOTE_LOADING', payload: { loading: false, roomId, }, })
    sendAmpEvent('Submit note', { room: roomId, length: note.length, })
    yield call(updateActivity, getToken)
    return
  } catch (e) {
    console.error('ERR SUBMITTING NOTE', e)
  }
}

export function * fetchNotes (action) {
  try {
    const { roomId, getToken, } = action.payload
    yield put({ type: 'GET_NOTES_LOADING', payload: roomId, })
    const notes = yield call(getNotes, getToken, roomId)

    yield put({ type: 'UPDATE_NOTES', payload: { notes, roomId, }, })
    yield put({ type: 'GET_NOTES_LOADING', payload: false, })
    sendAmpEvent('View Notes', { room: roomId, })
    return
  } catch (e) {
    console.error('ERR GETTING NOTES', e)
  }
}
export function * updateOrgRoomUnit (action) {
  try {
    const { getToken, room, } = action.payload
    const { floor, name, } = extractUnitFromRoom(room[1])
    const unit = yield call(getRoomUnit, getToken, floor, name)
    yield put({ type: 'UPDATED_ROOM', payload: { room, unit, }, })
    return
  } catch (e) {
    console.error('ERR UPDATING ROOM', e)
  }
}
export function * fetchAllBases (action) {
  try {
    const { getToken, } = action.payload
    yield put({ type: 'GET_BASES_LOADING', })
    const rawBases = yield call(getBases, getToken)
    const bases = filterBases(rawBases)

    const beacons = yield call(getBeacons, getToken)

    yield put({ type: 'SET_BASES', payload: { bases, beacons, }, })
    // yield put({ type: "GET_NOTES_LOADING", payload: false })

    const orgs = yield call(getOrgs, getToken)

    yield put({ type: 'SET_ORGS', payload: orgs, })
    sendAmpEvent('View Basestations')

    return
  } catch (e) {
    console.error('ERR GETTING BEACONS', e)
  }
}

export function * fetchOrgUsers (action) {
  try {
    const { getToken, org, } = action.payload
    yield put({ type: 'ORG_USERS_LOADING', })
    const orgUsers = yield call(getOrgUsers, getToken, org)

    yield put({ type: 'SET_ORG_USERS', payload: { orgUsers, }, })

    return
  } catch (e) {
    console.error('ERR GETTING ORG USERS', e)
  }
}

export function * updateBeaconAssignment (action) {
  try {
    const { getToken, assignedTo, beacon, firstName, } = action.payload
    // yield put({ type: "ORG_USERS_LOADING" })
    yield call(assignBeacon, getToken, beacon, assignedTo, firstName)

    yield put({ type: 'ASSIGN_BEACON', payload: { beacon, assignedTo, firstName, }, })
    sendAmpEvent('Update Beacon Assignment', { beacon, assignedTo, })
    return
  } catch (e) {
    console.error('ERR ASSIGNING BEACON', e)
  }
}

export function * fetchNotification (action) {
  try {
    const { getToken, notifId, roomId, } = action.payload
    yield put({ type: 'NOTIFICATION_HASH_LOADING', payload: true, })
    const isoId = convertUnixToIso(notifId)
    const notif = yield call(getNotif, getToken, isoId, roomId)
    const notifAllImages = yield call(getNotifAllImages, getToken, isoId, roomId)
    yield put({
      type: 'UPDATE_NOTIFICATION_HASH',
      payload: {
        ...notif,
        allImages: notifAllImages,
      },
    })
    return
  } catch (e) {
    console.error('ERR GETTING NOTIFICATION', e)
  }
}

export function * viewNote (action) {
  try {
    const { room, } = action.payload

    const now = new Date()
    const ts = now.toISOString()

    yield put({ type: 'UPDATE_VIEWS', payload: { room, ts, }, })
    return
  } catch (e) {
    console.error('ERR UPDATING VIEWS', e)
  }
}

export function * updateMonitorAssignment (action) {
  try {
    const { getToken, roomId, user, remove = false, admin = true, } = action.payload
    // yield put({ type: "ORG_USERS_LOADING" })
    const apiCall = remove ? unassignMonitor : assignMonitor
    yield call(apiCall, getToken, roomId, user.mainId)

    if (admin) {
      // update state
      yield put({ type: 'ADMIN_UPDATE_MONITOR_ASSIGNMENTS', payload: { roomId, remove, }, })
    }

    sendAmpEvent('Update Monitor Assignment', { roomId, user, })
    return
  } catch (e) {
    console.error('ERR ASSIGNING MONITOR', e)
  }
}

export function * fetchMonitors (action) {
  try {
    const { getToken, } = action.payload
    yield put({ type: 'ORG_USERS_LOADING', })
    const mwUsers = yield call(getOrgUsers, getToken, 'MW')
    const palmUsers = yield call(getOrgUsers, getToken, 'PALM')

    const monitorUsers = []

    mwUsers.forEach(u => {
      if (u.role && u.role === 'monitor') {
        monitorUsers.push(u)
      }
    })

    palmUsers.forEach(u => {
      if (u.role && u.role === 'monitor') {
        monitorUsers.push(u)
      }
    })

    yield put({ type: 'SET_MONITOR_USERS', payload: { monitorUsers, }, })

    return
  } catch (e) {
    console.error('ERR GETTING ORG USERS', e)
  }
}

export function * fetchMonitorAssignments (action) {
  try {
    yield put({ type: 'ASSIGNMENTS_LOADING', })
    const { getToken, userId, } = action.payload
    const monitorAssignments = yield call(getMonitorAssignments, getToken, userId)

    yield put({ type: 'SET_MONITOR_ASSIGNMENTS', payload: { monitorAssignments, }, })

    return
  } catch (e) {
    console.error('ERR GETTING MONITOR ASSIGNMENTS', e)
  }
}

export function * updateUser (action) {
  try {
    const { getToken, userId, updatedFields, } = action.payload
    const newUser = yield call(addFieldsToUser, getToken, userId, updatedFields)

    yield put({ type: 'UPDATE_MONITOR_USER', payload: { newUser, }, })

    return
  } catch (e) {
    console.error('ERR UPDATING USER', e)
  }
}

export function * updateFallRisk (action) {
  try {
    const { getToken, roomId, nxtState, } = action.payload
    yield put({ type: 'SWITCH_LOADING', payload: `switch-${roomId}`, })

    const fallRiskLevel = nxtState ? 'medium' : 'low'
    yield call(updateRoom, getToken, roomId, { fallRiskLevel, })

    yield put({ type: 'TOGGLE_FR', payload: { roomId, fallRiskLevel, }, })

    yield put({ type: 'SWITCH_LOADING', payload: false, })

    sendAmpEvent('Update Fall Risk', { roomId, nxtState, })

    yield delay(10000)

    yield put({ type: 'CLEAR_MANUAL_FR', payload: { roomId, }, })
    return
  } catch (e) {
    console.error('ERR UPDATING FALL RISK', e)
  }
}

export function * batchUpdateUsers (action) {
  try {
    const { getToken, users, } = action.payload

    for (const user of users) {
      yield delay(500)
      yield call(createUser, getToken, user)
      continue
    }

    if (users && users[0] && users[0].org) {
      const orgUsers = yield call(getOrgUsers, getToken, users[0].org)

      yield put({ type: 'SET_ORG_USERS', payload: { orgUsers, }, })
    }
    return
  } catch (e) {
    console.error('ERR UPDATING FALL RISK', e)
  }
}

export function * batchUpdateBeacons (action) {
  try {
    const { getToken, beacons, } = action.payload

    for (const beacon of beacons) {
      yield delay(500)
      yield call(createBeacon, getToken, beacon)
      continue
    }

    // if (users && users[0] && users[0].org) {
    //   const orgUsers = yield call(getOrgUsers, getToken, users[0].org)

    // yield put({ type: 'SET_ORG_USERS', payload: { orgUsers, }, })
    // }
    return
  } catch (e) {
    console.error('ERR UPDATING FALL RISK', e)
  }
}

export function * addTeam (action) {
  try {
    const { getToken, team, } = action.payload
    const newTeam = yield call(createTeam, getToken, team)
    yield put({ type: 'ADD_TEAM', payload: newTeam, })
    return
  } catch (e) {
    console.error('ERR CREATING NEW TEAM', e)
  }
}
export function * assignTeamBed (action) {
  try {
    const { getToken, bed, team, } = action.payload
    yield put({ type: 'BED_ASSIGNMENT_LOADING', payload: true, })
    const assignment = yield call(postBedToTeam, getToken, bed, team)
    yield put({ type: 'BED_ASSIGNED', payload: assignment, })
    yield put({ type: 'BED_ASSIGNMENT_LOADING', payload: false, })
    return
  } catch (err) {
    console.error('ERR ASSIGNING NEW TEAM BED', err)
  }
}
export function * unassignTeamBed (action) {
  try {
    const { getToken, bed, team, } = action.payload
    yield put({ type: 'BED_ASSIGNMENT_LOADING', payload: true, })
    const assignment = yield call(deleteBedFromTeam, getToken, bed, team)
    yield put({ type: 'BED_UNASSIGNED', payload: assignment, })
    yield put({ type: 'BED_ASSIGNMENT_LOADING', payload: false, })
    return
  } catch (err) {
    console.error('ERR UNASSIGNING NEW TEAM BED', err)
  }
}
export function * unassignTeamUser (action) {
  try {
    const { getToken, user, team, } = action.payload
    yield put({ type: 'USER_ASSIGNMENT_LOADING', payload: true, })
    const assignment = yield call(deleteUserFromTeam, getToken, user, team)
    yield put({ type: 'USER_UNASSIGNED', payload: assignment, })
    yield put({ type: 'USER_ASSIGNMENT_LOADING', payload: false, })
    return
  } catch (err) {
    console.error('ERR UNASSIGNING NEW TEAM USER', err)
  }
}
export function * assignTeamUser (action) {
  try {
    const { getToken, user, team, } = action.payload
    yield put({ type: 'USER_ASSIGNMENT_LOADING', payload: true, })
    const assignment = yield call(postUserToTeam, getToken, user, team)
    yield put({ type: 'USER_ASSIGNED', payload: assignment, })
    yield put({ type: 'USER_ASSIGNMENT_LOADING', payload: false, })
  } catch (err) {
    console.error('ERR ASSIGNING NEW TEAM USER', err)
  }
}
export function * deleteTeam (action) {
  try {
    const { getToken, team, } = action.payload
    yield put({ type: 'DELETE_TEAM_LOADING', payload: true, })
    yield call(deleteSelectedTeam, getToken, team)
    yield put({ type: 'SET_TEAM_DELETE', payload: team, })
    yield put({ type: 'DELETE_TEAM_LOADING', payload: false, })
    return
  } catch (err) {
    console.error('ERR DELETING TEAM', err)
  }
}
export function * getAllTeams (action) {
  try {
    const { getToken, } = action.payload
    const rawPayload = yield call(getTeams, getToken)
    yield put({ type: 'SET_ALL_TEAMS', payload: { rawPayload, }, })
    return
  } catch (e) {
    console.error('ERROR GETTING ALL TEAMS', e)
  }
}
export function * fetchTeam (action) {
  try {
    const { getToken, team, } = action.payload
    yield put({ type: 'SET_TEAM_SELECTED_LOADING', payload: true, })
    const rawPayload = yield call(getSelectedTeam, getToken, team)
    yield put({ type: 'SET_TEAM_SELECTED', payload: { rawPayload, }, })
    yield put({ type: 'SET_TEAM_SELECTED_LOADING', payload: false, })
    return
  } catch (err) {
    console.error('ERROR FETCHING SELECTED TEAM', err)
  }
}
export function * fetchAssignedTeams (action) {
  try {
    const { getToken, rooms, } = action.payload
    const rawPayload = yield call(getRoomTeamAssignments, getToken, rooms)
    yield put({ type: 'ROOM_ROWS_ASSIGNED_TEAMS', payload: { rawPayload, }, })

    return
  } catch (err) {
    console.error('ERROR GETTING THE ASSIGN TEAMS TO ROOMS', err)
  }
}
export function * updateSlack (action) {
  try {
    const { getToken, basestation, mute, } = action.payload
    yield put({ type: 'SLACK_UPDATE_LOADING', payload: true, })
    const rawPayload = yield call(updateSlackChannel, getToken, basestation, mute)
    // eslint-disable-next-line no-console
    console.log(rawPayload)
    yield put({ type: 'SLACK_UPDATE_LOADING', payload: false, })
    return
  } catch (err) {
    console.error('ERROR UPDATING SLACK CHANNEL', err)
  }
}
export default retroSagas
