
export const mapStateToProps = (state: any) => ({
  orgs: state.mainReducer.orgs || [],
  org: state.mainReducer.selectedOrg,
  selectedRoom: state.mainReducer.selectedRoom,
  dates: state.mainReducer.analyticsDates,
  notifications: state.mainReducer.notifications,
  notification: state.mainReducer.selectedNotification,
  timeline: state.mainReducer.timeline,
  tloading: state.mainReducer.timelineLoading,
  pfloading: state.mainReducer.prefetchingTimeline,
  type: state.mainReducer.selectedType,
  series: state.mainReducer.series,
  tlhash: state.mainReducer.timelineHash,
  pics: state.mainReducer.timelinePics,
})
