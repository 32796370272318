import moment from 'moment'
import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import rootReducer from './reducers'
const { REACT_APP_STORAGE, } = process.env

const sagaMiddleware = createSagaMiddleware()

const dateTransform = createTransform(null,
  (outboundState) => {
    return { ...outboundState, analyticsDates: outboundState.analyticsDates.map(d => moment(d)), rooms: [], }
  },
  { whitelist: ['mainReducer',], })

const persistConfig = {
  key: REACT_APP_STORAGE,
  storage,
  blacklist: ['analyticsDates', 'rooms',],
  transforms: [dateTransform,],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware))
export const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
