import React, { useState } from 'react'
import { Tabs, Breadcrumb } from 'antd'
import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import Beds from './beds/Beds'
import Users from './users/Users'
const { TabPane, } = Tabs

const Team = ({ prevStep, team, beds, users, dispatch, currentAssignments, setTeamLoading, userAssignmentLoading, bedAssignmentLoading, updateUsers, }) => {
  const [localKey, setLocalKey,] = useState(1)
  function callback (key) {
    setLocalKey(parseInt(key))
    // eslint-disable-next-line
    if (localKey === 2) {
      updateUsers()
    }
  }
  return (
    <div>
        <Breadcrumb className='team-bread-crumb'>
          <Breadcrumb.Item className='team-link' onClick={prevStep}>
            <TeamOutlined />
            <span>Teams</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <UserOutlined />
            <span>{team.name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Beds" key="1">
          <Beds setTeamLoading={setTeamLoading} team={team} beds={beds} dispatch={dispatch} currentAssignments={currentAssignments} bedAssignmentLoading={bedAssignmentLoading}/>
        </TabPane>
        <TabPane tab="Users" key="2">
          <Users users={users} team={team} dispatch={dispatch} userAssignmentLoading={userAssignmentLoading}/>
        </TabPane>
      </Tabs>

    </div>
  )
}
export default Team
