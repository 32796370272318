import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './state'
import Auth from './Auth'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as serviceWorker from './serviceWorker'
import { initAmp } from './utility/analytics'
import Layout from './components/Layout/Layout'

initAmp()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DndProvider backend={HTML5Backend}>
              <Layout>
                <App />
              </Layout>
            </DndProvider>
          </PersistGate>
        </Provider>
      </Auth>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
