import { checkIfOffline } from '../../utility/general'
import { convertUnixToIso, extractImgTimestamp, compareOrgRooms } from '../../utility/helpers'
import mainState from '../defaultState'

export default function mainReducer (state = mainState, action) {
  switch (action.type) {
    case 'SET_ORGS': {
      const sortedOrgs = action.payload.map(i => {
        i.children.sort(compareOrgRooms)
        return i
      })
      return {
        ...state,
        orgs: sortedOrgs,
      }
    }

    case 'ADD_TEAM': {
      return {
        ...state,
        teams: [...state.teams, action.payload,],
      }
    }
    case 'BED_ASSIGNED': {
      return {
        ...state,
        team: {
          ...state.team,
          teamBedAssignments: action.payload.newAssignment.concat(...state.team.teamBedAssignments),
          beds: state.team.beds.filter(i => {
            let count = 0
            for (const a of action.payload.newAssignment) {
              if (i.secondaryId !== a.secondaryId) {
                count++
              }
            }
            if (count === action.payload.newAssignment.length) return i
            return false
          }),
        },
        teams: state.teams.map(i => {
          if (i.mainId === action.payload.team) {
            i.numBeds = action.payload.updatedTeam.numBeds
            i.orgs = action.payload.updatedTeam.orgs
          }
          return i
        }),
      }
    }
    case 'BED_UNASSIGNED': {
      const deletePayload = action.payload.newAssignment
      return {
        ...state,
        team: {
          ...state.team,
          teamBedAssignments: state.team?.teamBedAssignments?.filter(i => {
            let count = 0
            for (const d of deletePayload) {
              if (i.secondaryId !== d.secondaryId) {
                count++
              }
            }
            if (count === deletePayload.length) return i
            return false
          }
          ),
          beds: state.team.beds.concat(action.payload.newAssignment),
        },
        teams: state.teams.map(i => {
          if (i.mainId === action.payload.team) {
            i.numBeds = action.payload.updatedTeam.numBeds
            i.orgs = action.payload.updatedTeam.orgs
          }
          return i
        }),
      }
    }
    case 'USER_UNASSIGNED': {
      const deletePayload = action.payload.newAssignment
      return {
        ...state,
        team: {
          ...state.team,
          teamUserAssignments: state.team.teamUserAssignments.filter(i => {
            let count = 0

            for (const d of deletePayload) {
              if (i.secondaryId !== d.secondaryId) {
                count++
              }
            }
            if (count === deletePayload.length) return i
            return false
          }
          ),
          users: action.payload.newAssignment.concat(...state.team.users),
        },
        teams: state.teams.map(i => {
          if (i.mainId === action.payload.team) {
            i.numUsers = action.payload.updatedTeam.numUsers
          }
          return i
        }),
      }
    }
    case 'USER_ASSIGNED': {
      return {
        ...state,
        team: {
          ...state.team,
          teamUserAssignments: action.payload.newAssignment.concat(...state.team.teamUserAssignments),
          users: state.team.users.filter(i => {
            let count = 0
            for (const a of action.payload.newAssignment) {
              if (i.mainId !== a.secondaryId) {
                count++
              }
            }
            if (count === action.payload.newAssignment.length) return i
            return false
          }),
        },
        teams: state.teams.map(i => {
          if (i.mainId === action.payload.team) {
            i.numUsers = action.payload.updatedTeam.numUsers
          }
          return i
        }),
      }
    }
    case 'SET_TEAM_DELETE': {
      return {
        ...state,
        teams: state.teams.filter(i => i.mainId !== action.payload),
      }
    }
    case 'ROOM_ROWS_ASSIGNED_TEAMS': {
      return {
        ...state,
        team: {
          ...state.team,
          beds: state.team.beds.map(i => {
            if (!action.payload.rawPayload.assignments?.length) {
              i.orgs = []
            } else {
              let found = false
              for (const j of action.payload.rawPayload?.assignments) {
                if (i.secondaryId === j.secondaryId) {
                  i.orgs = j.orgs
                  found = true
                }
                if (!found) {
                  i.orgs = []
                }
              }
            }
            return i
          }),
        },
      }
    }
    case 'SET_TEAM_SELECTED': {
      const { rawPayload, } = action.payload
      return {
        ...state,
        team: {
          ...rawPayload,
          beds: state.bases.filter(i => {
            let count = 0
            for (const b of rawPayload.teamBedAssignments) {
              if (i.mainId !== b.secondaryId) {
                count++
              }
            }
            if (count === rawPayload.teamBedAssignments.length) return i
            return false
          }),
          users: state.monitorUsers.filter(i => {
            let count = 0
            for (const b of rawPayload.teamUserAssignments) {
              if (i.mainId !== b.secondaryId) {
                count++
              }
            }
            if (count === rawPayload.teamUserAssignments.length) return i
            return false
          }),
        } || {},
      }
    }
    case 'DELETE_TEAM_LOADING': {
      return {
        ...state,
        deleteLoading: action.payload,
      }
    }
    case 'BED_ASSIGNMENT_LOADING': {
      return {
        ...state,
        bedAssignmentLoading: action.payload,
      }
    }
    case 'USER_ASSIGNMENT_LOADING': {
      return {
        ...state,
        userAssignmentLoading: action.payload,
      }
    }
    case 'SET_REPORT_PAGINATION': {
      return {
        ...state,
        reportPagination: action.payload,
      }
    }
    case 'SET_REPORT_FILTERING': {
      return {
        ...state,
        reportFiltering: action.payload,
      }
    }
    case 'SET_REPORT_SORTING': {
      return {
        ...state,
        reportSorting: action.payload,
      }
    }
    case 'SET_HOME_SORTING': {
      return {
        ...state,
        homeSorting: action.payload,
      }
    }
    case 'SET_HOME_FILTERING': {
      return {
        ...state,
        homeFiltering: action.payload,
      }
    }
    case 'SET_HOME_PAGINATION': {
      return {
        ...state,
        homePagination: action.payload,
      }
    }
    case 'ANALYTICS_DATES': {
      return {
        ...state,
        analyticsDates: action.payload,
      }
    }
    case 'ANALYTICS_QUERY_SUCCEEDED': {
      return {
        ...state,
        analytics: action.payload,
        analyticsLoading: false,
        staffAnalytics: null,
      }
    }
    case 'STAFF_ANALYTICS_QUERY_SUCCEEDED': {
      return {
        ...state,
        staffAnalytics: action.payload,
      }
    }
    case 'ANALYTICS_LOADING': {
      return {
        ...state,
        analyticsLoading: true,
      }
    }
    case 'SET_TEAM_SELECTED_LOADING': {
      return {
        ...state,
        setTeamLoading: action.payload,
      }
    }
    case 'TRAINING_LOADING': {
      return {
        ...state,
        trainingLoading: true,
      }
    }
    case 'SET_TRAINING': {
      const newType = state.trainType !== action.payload
      return {
        ...state,
        trainType: action.payload,
        train: newType ? [] : state.train,
      }
    }
    case 'TRAINING_QUERY_SUCCEEDED': {
      return {
        ...state,
        trainingLoading: false,
        train: state.train && state.train.concat(action.payload.events) || action.payload.events,
        trainingComplete: action.payload && action.payload.events && action.payload.events.length === 0,
      }
    }
    case 'LABEL_EVENT_SUCCEEDED': {
      const { room, time, } = action.payload
      const newTrain = state.train.filter(t => t.time !== time || t.room !== room)
      return {
        ...state,
        train: newTrain,
      }
    }
    case 'UPDATED_ROOM': {
      const { room, unit, } = action.payload
      return {
        ...state,
        selectedRoom: room,
        selectedOrg: room && room[0],
        homeUnit: unit,
      }
    }
    case 'UPDATE_NOTIFICATION': {
      return {
        ...state,
        selectedNotification: action.payload,
      }
    }
    case 'UPDATE_NOTIFICATIONS': {
      return {
        ...state,
        notifications: action.payload || [],
        homeLoading: false,
        homeQueryError: null,
      }
    }
    case 'HOME_QUERY_FAILED': {
      const { homeLoading, homeQueryError, } = action.payload
      return {
        ...state,
        homeLoading: homeLoading,
        homeQueryError: homeQueryError || 'ERROR QUERYING FOR DATA',
      }
    }
    case 'HOME_LOADING': {
      return {
        ...state,
        homeLoading: action.payload || false,
      }
    }
    case 'UPDATE_ORG': {
      return {
        ...state,
        selectedOrg: action.payload,
      }
    }
    case 'SET_TIMELINE': {
      return {
        ...state,
        timeline: action.payload.events,
        timelinePics: action.payload.pics,
      }
    }
    case 'TIMELINE_LOADING': {
      return {
        ...state,
        timelineLoading: action.payload,
      }
    }
    case 'UPDATE_TYPE': {
      return {
        ...state,
        selectedType: action.payload,
        timelineLoading: true,
      }
    }
    case 'SET_SERIES_NOTIFS': {
      return {
        ...state,
        series: action.payload,
      }
    }
    case 'PREFETCHING_TIMELINE': {
      return {
        ...state,
        prefetchingTimeline: action.payload.events,
        prefetchingPics: action.payload.pics,
      }
    }
    case 'SET_NEXT_TIMELINE': {
      const nextTLHash = state.timelineHash ? state.timelineHash : {}
      const { timeline, id, room: r, } = action.payload
      const key = `${r}-${id}`
      nextTLHash[key] = timeline
      return {
        ...state,
        timelineHash: nextTLHash,
      }
    }
    case 'SWAP_TIMELINE': {
      const nextSeries = state.series
      nextSeries.shift()
      const nextNotif = nextSeries.length && nextSeries[0]
      const lookUp = nextNotif ? state.timelineHash[`${nextNotif.room}-${nextNotif.id}`] : null
      const nextTimeline = lookUp || { events: [], pics: [], }
      return {
        ...state,
        timeline: nextTimeline.events,
        timelinePics: nextTimeline.pics,
        series: nextSeries,
        selectedNotification: nextNotif || state.selectedNotification,
      }
    }
    case 'REPORT_LOADING': {
      return {
        ...state,
        reportingLoading: true,
      }
    }
    case 'REPORT_QUERY_SUCCEEDED': {
      return {
        ...state,
        reportingLoading: false,
        reportData: action.payload,
        reportingQueryError: false,
      }
    }
    case 'REPORT_QUERY_FAILED': {
      return {
        ...state,
        reportingLoading: false,
        reportingQueryError: true,
      }
    }
    case 'NOTIF_DESC_UPDATED': {
      const { id: notifId, comment, staff_entry, room: notifRoom, } = action.payload
      const newReport = state.reportData
      const newNotifications = state.reportData.notifications && state.reportData.notifications.map(n => {
        if (convertUnixToIso(n.id) === notifId && n.room === notifRoom) {
          n.comment = comment
          n.staff_entry = staff_entry
          return n
        }
        return n
      })
      newReport.notifications = newNotifications
      return {
        ...state,
        reportData: newReport,
      }
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'SET_UNITS': {
      return {
        ...state,
        units: action.payload,
      }
    }
    case 'REFRESH_UNIT': {
      return {
        ...state,
        rooms: action.payload.rooms,
        assignments: action.payload.assignments,
      }
    }
    case 'STAFF_NAMES_LOADING': {
      return {
        ...state,
        staffNamesLoading: action.payload,
      }
    }
    case 'SLACK_UPDATE_LOADING': {
      return {
        ...state,
        slackUpdateLoading: action.payload || false,
      }
    }
    case 'UNIT_LOADING': {
      return {
        ...state,
        unitLoading: true,
      }
    }
    case 'SET_STAFF_NAMES': {
      return {
        ...state,
        staffNames: action.payload || [],
      }
    }
    case 'SET_UNIT': {
      return {
        ...state,
        unit: action.payload.unit,
        unitLoading: false,
      }
    }
    case 'FN_LOADING': {
      return {
        ...state,
        fnLoading: action.payload,
      }
    }
    case 'PU_LOADING': {
      return {
        ...state,
        puLoading: action.payload,
      }
    }
    case 'SWITCH_LOADING': {
      return {
        ...state,
        switchLoading: action.payload,
      }
    }
    case 'GET_NOTES_LOADING': {
      return {
        ...state,
        notesLoading: action.payload,
      }
    }
    case 'UPDATE_NOTES': {
      const { roomId, notes, } = action.payload
      const newNotes = state.roomNotes ? state.roomNotes : {}

      newNotes[roomId] = notes

      return {
        ...state,
        roomNotes: newNotes,
      }
    }
    case 'REFRESH_NOTES': {
      const { roomId: noteRoom, newNote, } = action.payload
      const noteArr = state.roomNotes && state.roomNotes[noteRoom]

      if (!noteArr) {
        return {
          ...state,
        }
      }

      noteArr.push(newNote)

      const updatedNotes = state.roomNotes
      updatedNotes[noteRoom] = noteArr

      return {
        ...state,
        roomNotes: updatedNotes,
      }
    }
    case 'HYDRATE_NOTES': {
      const { roomId: newNoteRoom, newNote: hydratedNote, } = action.payload
      const arr = state.roomNotes && state.roomNotes[newNoteRoom]

      const hydratedArr = arr.map(n => {
        if (n.loading && n.text === hydratedNote.text) {
          return hydratedNote
        } else return n
      })

      const hydratedRN = state.roomNotes
      hydratedRN[newNoteRoom] = hydratedArr

      return {
        ...state,
        roomNotes: hydratedRN,
      }
    }
    case 'SET_BASES': {
      const { bases, beacons, } = action.payload
      const sortedBases = bases && bases.sort((a, b) => {
        const d1 = new Date(a.keepAlive)
        const d2 = new Date(b.keepAlive)
        if (d1 > d2) return 1
        if (d1 < d2) return -1
        return 0
      }).map(b => {
        b.offline = checkIfOffline(b.keepAlive)
        return b
      })

      const palmCount = bases.filter(b => b.mainId.includes('-PALM-')).length
      const georgetownCount = bases.filter(b => b.mainId.includes('-GEORGETOWN-')).length
      const valleyCount = bases.filter(b => b.mainId.includes('-VALLEY-')).length
      const gnCount = bases.filter(b => b.mainId.includes('-GENERATIONS-')).length
      const hmCount = bases.filter(b => b.mainId.includes('-HOMESTAY-')).length

      const sortedBeacons = beacons && beacons.sort((a, b) => {
        const d1 = new Date(a.lastSeen)
        const d2 = new Date(b.lastSeen)
        if (!a.lastSeen) return 1
        if (!b.lastSeen) return -1
        if (d1 > d2) return -1
        if (d1 < d2) return 1
        return 0
      }).map(b => {
        const [,, email,] = b.assignedTo ? b.assignedTo.split('-') : []
        b.email = email
        return b
      })

      return {
        ...state,
        bases: sortedBases,
        beacons: sortedBeacons,
        georgetownCount,
        palmCount,
        valleyCount,
        gnCount,
        hmCount,
      }
    }
    case 'ORG_USERS_LOADING': {
      return {
        ...state,
        orgUsersLoading: true,
      }
    }
    case 'SET_ORG_USERS': {
      const { orgUsers, } = action.payload

      return {
        ...state,
        orgUsers,
        orgUsersLoading: false,
      }
    }
    case 'SET_ALL_TEAMS': {
      const { rawPayload, } = action.payload
      return {
        ...state,
        teams: rawPayload,
      }
    }
    case 'SET_MONITOR_USERS': {
      const { monitorUsers, } = action.payload

      const sortedMonitors = monitorUsers.sort((a, b) => {
        if (!b.lastActive) return -1
        if (!a.lastActive) return 1
        const d1 = new Date(a.lastActive)
        const d2 = new Date(b.lastActive)
        if (d1 > d2) return -1
        if (d1 < d2) return 1
        return 0
      })

      return {
        ...state,
        monitorUsers: sortedMonitors,
        orgUsersLoading: false,
      }
    }

    case 'ASSIGNMENTS_LOADING': {
      return {
        ...state,
        assignmentsLoading: true,
      }
    }

    case 'SET_MONITOR_ASSIGNMENTS': {
      const { monitorAssignments, } = action.payload
      const offlineAssignments = monitorAssignments.map(b => {
        b.offline = checkIfOffline(b.keepAlive)
        return b
      }).sort((a, b) => {
        if (a.main > b.mainId) return 1
        if (b.main > a.mainId) return -1
        else return 1
      })

      const palmAssignments = monitorAssignments.filter(b => b.mainId.includes('-PALM-')).length
      const georgetownAssignments = monitorAssignments.filter(b => b.mainId.includes('-GEORGETOWN-')).length
      const valleyAssignments = monitorAssignments.filter(b => b.mainId.includes('-VALLEY-')).length
      const gnAssignments = monitorAssignments.filter(b => b.mainId.includes('-GENERATIONS-')).length
      const hmAssignments = monitorAssignments.filter(b => b.mainId.includes('-HOMESTAY-')).length

      const assignmentsHash = {}

      monitorAssignments.forEach(a => {
        assignmentsHash[a.mainId] = true
      })

      return {
        ...state,
        monitorAssignments: offlineAssignments,
        assignmentsLoading: false,
        assignmentsHash,
        palmAssignments,
        georgetownAssignments,
        valleyAssignments,
        gnAssignments,
        hmAssignments,
      }
    }

    case 'ASSIGN_BEACON': {
      const { beacon, assignedTo, } = action.payload

      const newBeacons = state.beacons && state.beacons.map(b => {
        if (b.id === beacon) {
          const [,, email,] = assignedTo.split('-')
          return {
            ...b,
            assignedTo,
            email,
          }
        } else return b
      })

      return {
        ...state,
        beacons: newBeacons,
      }
    }
    case 'UPDATE_NOTIFICATION_HASH': {
      const notif = action.payload

      if (notif && notif.allImages && notif.allImages.length) {
        notif.allImages.sort((a, b) => {
          const aTime = extractImgTimestamp(a.url)
          const bTime = extractImgTimestamp(b.url)

          if (aTime > bTime) return 1
          if (aTime < bTime) return -1
          return 0
        })
      }

      const newNotifHash = state.notifHash || {}
      newNotifHash[notif.room] = notif

      return {
        ...state,
        notifHash: newNotifHash,
        notifLoading: false,
      }
    }
    case 'NOTIFICATION_HASH_LOADING': {
      return {
        ...state,
        notifLoading: true,
      }
    }

    case 'UPDATE_VIEWS': {
      const { room, ts, } = action.payload
      const viewHash = state.viewHash || {}
      viewHash[room] = ts
      return {
        ...state,
        viewHash,
      }
    }

    case 'ADMIN_UPDATE_MONITOR_ASSIGNMENTS': {
      const { roomId, remove = false, } = action.payload
      const bases = state.bases

      const room = bases.filter(b => b.mainId === roomId)[0]
      const assignmentsHash = state.assignmentsHash || {}
      let monitorAssignments = state.monitorAssignments || []

      let newValleyAssignments = state.valleyAssignments
      let newPalmAssignments = state.palmAssignments
      let newGeorgetownAssignments = state.georgetownAssignments
      let newGNAssignments = state.gnAssignments
      let newHMAssignments = state.hmAssignments

      if (room) {
        if (!remove) {
          assignmentsHash[roomId] = true
          monitorAssignments.push(room)
        } else {
          assignmentsHash[roomId] = false
          monitorAssignments = monitorAssignments.filter(a => a.mainId !== roomId)
        }
      }
      const [,org,] = roomId.split('-')

      switch (org) {
        case 'VALLEY':
          if (remove) newValleyAssignments--
          else newValleyAssignments++
          break
        case 'PALM':
          if (remove) newPalmAssignments--
          else newPalmAssignments++
          break
        case 'GEORGETOWN':
          if (remove) newGeorgetownAssignments--
          else newGeorgetownAssignments++
          break
        case 'GENERATIONS':
          if (remove) newGNAssignments--
          else newGNAssignments++
          break
        case 'HOMESTAY':
          if (remove) newHMAssignments--
          else newHMAssignments++
          break
      }

      return {
        ...state,
        assignmentsHash,
        monitorAssignments,
        triggerRefresh: !state.triggerRefresh,
        georgetownAssignments: newGeorgetownAssignments,
        palmAssignments: newPalmAssignments,
        valleyAssignments: newValleyAssignments,
        gnAssignments: newGNAssignments,
        hmAssignments: newHMAssignments,
      }
    }

    case 'UPDATE_MONITOR_USER': {
      const { newUser, } = action.payload

      const monitorUsers = state.monitorUsers

      const updatedMonitors = monitorUsers.map(u => {
        if (u.mainId === newUser.mainId) {
          return newUser
        }
        return u
      })

      return {
        ...state,
        monitorUsers: updatedMonitors,
      }
    }

    case 'TOGGLE_FR': {
      const { roomId, fallRiskLevel, } = action.payload
      const newRooms = state.rooms && state.rooms.map(r => {
        if (r.mainId === roomId) {
          r.fallRiskLevel = fallRiskLevel
          return r
        }
        return r
      })

      const manualFR = state.manualFR || {}
      manualFR[roomId] = fallRiskLevel

      return {
        ...state,
        rooms: newRooms,
        manualFR,
      }
    }

    case 'CLEAR_MANUAL_FR': {
      const { roomId, } = action.payload
      const manualFR = state.manualFR || {}

      manualFR[roomId] = null

      return {
        ...state,
        manualFR,
      }
    }

    default:
      return state
  }
}
