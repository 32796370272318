import { Button, message, Tooltip } from 'antd'
import React, { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { RiAddLine } from 'react-icons/ri'
import { fetchUnits } from '../../../state/actions'
import { useAppDispatch } from '../../../state/hooks'
import { bumpUpTestingSessionTime } from '../data-access/bumpUpTestingSessionTime'

interface Props {
  unit: string
}

export const VBaseBumpUpTestingButton = ({ unit, }: Props) => {
  const [processing, setProcessing,] = useState(false)
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const dispatch = useAppDispatch()

  const handleTestingSessionStop = useCallback(async () => {
    setProcessing(true)
    bumpUpTestingSessionTime(getToken, unit).then(() => {
      setProcessing(false)
      message.success('Testing session stopped')
      dispatch(fetchUnits({ getToken, }))
    }).catch(err => {
      console.error(err)
      message.error('Failed to bump up testing session')
    })
  }, [unit, getToken, dispatch,])

  return <Tooltip title="Bump Up Testing session">
    <Button
      style={{ marginLeft: 8, marginRight: 8, }}
      type="default"
      icon={<span><RiAddLine fontSize={24}/></span>}
      loading={processing}
      onClick={handleTestingSessionStop}
    />
  </Tooltip>
}
