
export const mapStateToProps = (state: any) => ({
  orgs: state.mainReducer.orgs || [],
  dates: state.mainReducer.analyticsDates || [],
  type: state.mainReducer.trainType,
  loading: state.mainReducer.trainingLoading,
  train: state.mainReducer.train || [],
  complete: state.mainReducer.trainingComplete,
  selectedOrg: state.mainReducer.selectedOrg,
})
