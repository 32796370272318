import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import NotifForm from '../../components/NotifForm'
import Timeline from '../../components/Timeline'
import Loading from '../../components/Loading'
import { getOrgs, labelNotification } from '../../utility'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { setOrgs, updateRoom, updateAnalyticsDates, updateNotification, updateTimeline, stopPollingUnit, getHomeNotifications } from '../../state/actions'
import { mapStateToProps } from './mapStateToProps'

function Home ({ dispatch, selectedRoom, dateRange, orgs, notifications, notification, timeline, match, tloading, pics = [], homePagination, homeFiltering, homeSorting, homeLoading, homeUnit, homeQueryError, }: any) {
  const { getAccessTokenSilently, user, } = useAuth0()

  const { path, } = useRouteMatch()

  const [initialized, updateInitialized,] = useState<boolean>(false)

  // const [state, updateState] = useState<any>({ orgs: [], notifications: [], selectedRoom: null, selectedNotification: null })

  const reviewNotification = async (room: string, notif: any) => {
    dispatch(updateTimeline({
      getToken: getAccessTokenSilently,
      notif,
    }))
    // dispatch(updateNotification(notification))
    // const { id, type } = notification

    // const bounds = getTimelineBounds(type)

    // dispatch(setTimelineLoading(true))
    // const events = await getEvents(getAccessTokenSilently, room[1], id, bounds)
    // dispatch(setTimeline(events))
    // dispatch(setTimelineLoading(false))
  }

  const requestNotifications = async (room: any, dateRange: any) => {
    const start = dateRange[0].unix()
    const end = dateRange[1].unix()
    dispatch(getHomeNotifications({ getToken: getAccessTokenSilently, start, end, room, }))
  }

  const selectRoom = (room: string) => {
    dispatch(updateRoom({ getToken: getAccessTokenSilently, room, }))
  }

  const updateDateRange = (range: any) => {
    dispatch(updateAnalyticsDates(range))
  }

  const label = async (room: string, id: number, label: string) => {
    const updatedNotification = await labelNotification(getAccessTokenSilently, room, id, label, user?.email)

    dispatch(updateNotification(updatedNotification))
  }

  useEffect(() => {
    async function initialize () {
      if (orgs) updateInitialized(true)
      const refreshedOrgs = await getOrgs(getAccessTokenSilently)
      dispatch(setOrgs(refreshedOrgs))
      updateInitialized(true)
      dispatch(stopPollingUnit())
    }
    initialize()
  }, [])

  if (!initialized) return <Loading type='bars'/>

  return (
    <>
      <div className="Home-container">
        <Switch>
          <Route
            exact
            path={path + '/table'}
            render={props => (
              <NotifForm
                {...props}
                orgs={orgs}
                homeQueryError={homeQueryError}
                selectedRoom={selectedRoom}
                dateRange={dateRange}
                requestNotifications={requestNotifications}
                notifications={notifications}
                reviewNotification={reviewNotification}
                selectRoom={selectRoom}
                updateDateRange={updateDateRange}
                dispatch={dispatch}
                homePagination={homePagination}
                homeFiltering={homeFiltering}
                homeSorting={homeSorting}
                loading={homeLoading}
                homeUnit={homeUnit}
              />
            )}
          />
          <Route
            exact
            path={path + '/review'}
            render={props => (
              <Timeline
                {...props}
                timeline={timeline}
                notification={notification}
                label={label}
                loading={tloading}
                allPics={pics}
              />
            )}
          />
          <Redirect to={path + '/table'} />
        </Switch>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(Home)
