import React, { useState } from 'react'
import { Table, Button, Space, Input } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'
import { assignUser, unassignUser } from '../../../state/actions'
import Highlighter from 'react-highlight-words'
import { userLastActiveMinutes } from '../../../rules'
const Users = ({ users, team, dispatch, userAssignmentLoading, }) => {
  const { getAccessTokenSilently: getToken, } = useAuth0()
  // handler for unassigning
  const handleUnassignClick = () => {
    dispatch(unassignUser({
      team: team.name,
      user: selectedRows,
      getToken,
    }))
    setSelectedRows([])
  }
  // handler for assigning
  const handleAssignClick = () => {
    dispatch(assignUser({
      team: team.name,
      user: assignRows,
      getToken,
    }))
    setAssignRows([])
  }
  let searchInput
  const [searchText, setSearchText,] = useState('')
  const [searchedColumn, setSearchedColumn,] = useState('')
  const [assignedFilters, setAssignedFilters,] = useState(null)
  const [assignedSorter, setAssignedSorter,] = useState(null)
  const [UnassignedFilters, setUnassignedFilters,] = useState(null)
  const [UnassignedSorter, setUnAssignedSorter,] = useState(null)
  // state for unassign selected rows
  const [selectedRows, setSelectedRows,] = useState([])
  // state for unassign selected disables button if no rows are selected
  const selected = selectedRows.length > 0
  // state for assign selected rows
  const [assignRows, setAssignRows,] = useState([])
  // state for assign selected disables button if no rows are selected
  const assigned = assignRows.length > 0
  const updateTable = (pagination, filters, sorters) => {
    setAssignedSorter(sorters)
    setAssignedFilters(filters)
  }
  const UnassignedUpdateTable = (pagination, filters, sorters) => {
    setUnAssignedSorter(sorters)
    setUnassignedFilters(filters)
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters({ confirm: true, })
    setSearchText('')
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8, }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value,] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90, }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: text => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0, }} searchWords={[searchText,]} autoEscape textToHighlight={text ? text.toString() : ''} />) : (text),
  })
  const assignedColumns = [
    {
      title: '',
      dataIndex: 'secondaryId',
      key: 'secondaryId',
      width: 10,
      render: (text) => {
        let recentlyActive
        if (!text) return ''
        users.map((i) => {
          if (i.secondaryId === text) {
            recentlyActive = i.lastActive && moment().diff(i.lastActive, 'minutes') < userLastActiveMinutes
            return recentlyActive
          }
          return false
        })
        return <div className='status' data-status={recentlyActive && 'active'}></div>
      },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a,b) => a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0,
      sortOrder: assignedSorter && assignedSorter.columnKey === 'firstName' && assignedSorter.order || null,
      width: 400,
      filteredValue: assignedFilters && assignedFilters.firstName || null,
      onFilter: (value, record) => !value ? record.firstName : record.firstName.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a,b) => a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0,
      sortOrder: assignedSorter && assignedSorter.columnKey === 'lastName' && assignedSorter.order || null,
      width: 400,
      filteredValue: assignedFilters && assignedFilters.lastName || null,
      onFilter: (value, record) => !value ? record.lastName : record.lastName.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 400,
      sorter: (a,b) => a.email > b.email ? 1 : b.email > a.email ? -1 : 0,
      sortOrder: assignedSorter && assignedSorter.columnKey === 'email' && assignedSorter.order || null,
      filteredValue: assignedFilters && assignedFilters.email || null,
      onFilter: (value, record) => !value ? record.email : record.email.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text) => {
        if (!text) {
          return ''
        } else {
          return <div>{text}</div>
        }
      },
    },
  ]
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 400,
      sorter: (a,b) => a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0,
      sortOrder: UnassignedSorter && UnassignedSorter.columnKey === 'firstName' && UnassignedSorter.order || null,
      filteredValue: UnassignedFilters && UnassignedFilters.firstName || null,
      onFilter: (value, record) => !value ? record.firstName : record.firstName.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 400,
      sorter: (a,b) => a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0,
      sortOrder: UnassignedSorter && UnassignedSorter.columnKey === 'lastName' && UnassignedSorter.order || null,
      filteredValue: UnassignedFilters && UnassignedFilters.lastName || null,
      onFilter: (value, record) => !value ? record.lastName : record.lastName.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 400,
      sorter: (a,b) => a.email > b.email ? 1 : b.email > a.email ? -1 : 0,
      sortOrder: UnassignedSorter && UnassignedSorter.columnKey === 'email' && UnassignedSorter.order || null,
      filteredValue: UnassignedFilters && UnassignedFilters.email || null,
      onFilter: (value, record) => !value ? record.email : record.email.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text) => {
        if (!text) {
          return ''
        } else {
          return <div>{text}</div>
        }
      },
    },
  ]
  return (
      <div>
          <div className='teams-table'>
          <h2>Assigned Users</h2>
            <div
            className='team-table-container'
            >
              <Button type='primary' disabled={!selected} onClick={handleUnassignClick} loading={userAssignmentLoading} icon={<MinusCircleOutlined />}>Unassign</Button>
            </div>
              <Table onChange={updateTable} rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRows,
                onChange: (keys) => {
                  setSelectedRows(keys)
                },

              }} dataSource={team.teamUserAssignments || []} columns={assignedColumns || []} size='large' rowKey="secondaryId"
              className='team-table-margin'
              />

          </div>
          <div className='teams-table'>
              <h2>Unassigned Users</h2>
                <div
                className='team-table-container'
              >
                  <Button type='primary' disabled={!assigned} onClick={handleAssignClick} loading={userAssignmentLoading} icon={<PlusCircleOutlined />}>Assign</Button>
                </div>
              <Table onChange={UnassignedUpdateTable} rowSelection={{
                type: 'checkbox',
                selectedRowKeys: assignRows,
                onChange: (keys) => {
                  setAssignRows(keys)
                },
              }}
              dataSource={team.users || []} columns={columns || []} size='large' rowKey={record => record}
              className='team-table-margin'
              />
          </div>
        </div>

  )
}
export default Users
