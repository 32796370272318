import { getJwtToken } from '../../../utility'
import { Api } from '../../../utility/client'

export const runRoutine = async (getToken, unitId: string, routine: string, runInLoop: boolean, roomId?: string) => {
  const jwt = await getJwtToken(getToken)

  await Api.post(
    '/v1/vbase/run',
    {
      unitId,
      routine,
      runInLoop,
      roomId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
}
