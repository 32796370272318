export const mapStateToProps = (state: any) => ({
  monitorUsers: state.mainReducer.monitorUsers || [],
  assignmentsLoading: state.mainReducer.assignmentsLoading,
  monitorAssignments: state.mainReducer.monitorAssignments || [],
  assignmentsHash: state.mainReducer.assignmentsHash || {},
  bases: state.mainReducer.bases || [],
  valleyCount: state.mainReducer.valleyCount,
  palmCount: state.mainReducer.palmCount,
  georgetownCount: state.mainReducer.georgetownCount,
  gnCount: state.mainReducer.gnCount,
  hmCount: state.mainReducer.hmCount,
  valleyAssignments: state.mainReducer.valleyAssignments,
  palmAssignments: state.mainReducer.palmAssignments,
  georgetownAssignments: state.mainReducer.georgetownAssignments,
  gnAssignments: state.mainReducer.gnAssignments,
  hmAssignments: state.mainReducer.hmAssignments,
  triggerRefresh: state.mainReducer.triggerRefresh,
  orgs: state.mainReducer.orgs,
  selectedOrg: state.mainReducer.selectedOrg,
  orgUsersLoading: state.mainReducer.orgUsersLoading,
})
