import React from 'react'
import { Comment } from 'antd'
import moment from 'moment'
const Notes = ({ notes, }) => {
  if (!notes.length) return <p>There are no notes</p>

  return notes.map((n) => (
    <Comment
      key={n.text.slice(0, 20)}
      author={n.fullName}
      content={<p>{n.text}</p>}
      avatar={n.avatar}
      datetime={moment(n.id).format('LLLL')}
    />
  ))
}
export default Notes
