import React, { useEffect, useState } from 'react'
import { Table, Button, Space, Input } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { assignBed, getRoomRowTeams, unassignBed } from '../../../state/actions'
import Highlighter from 'react-highlight-words'

const Beds = ({ team, beds, dispatch, currentAssignments, setTeamLoading, bedAssignmentLoading, }) => {
  const { getAccessTokenSilently: getToken, } = useAuth0()
  // handler for unassigning
  const handleUnassignClick = () => {
    dispatch(unassignBed({
      team: team.name,
      bed: selectedRows,
      getToken,
    }))
    setSelectedRows([])
  }
  // handler for assigning
  const handleAssignClick = () => {
    // dispatch this state to the action
    dispatch(assignBed({
      team: team.name,
      bed: assignRows,
      getToken,
    }))
    setAssignRows([])
  }
  let searchInput
  const [searchText, setSearchText,] = useState('')
  const [searchedColumn, setSearchedColumn,] = useState('')
  const [assignedFilters, setAssignedFilters,] = useState(null)
  const [assignedSorter, setAssignedSorter,] = useState(null)
  const [UnassignedFilters, setUnassignedFilters,] = useState(null)
  const [UnassignedSorter, setUnAssignedSorter,] = useState(null)
  // state for unassign selected rows
  const [selectedRows, setSelectedRows,] = useState([])
  // state for unassign selected disables button if no rows are selected
  const selected = selectedRows.length > 0
  // state for assign selected rows
  const [assignRows, setAssignRows,] = useState([])
  // state for assign selected disables button if no rows are selected
  const assigned = assignRows.length > 0

  const handlePaginate = async (pageInfo, currentDataSource) => {
    const { current, pageSize, } = pageInfo

    const startInd = (current - 1) * pageSize
    const endInd = startInd + pageSize
    if (currentDataSource) {
      const viewableRooms = currentDataSource.slice(startInd, endInd)
      const viewableIds = viewableRooms.map(r => r.secondaryId)
      // Build async action to query for room assignments for visible rooms
      // dispatch()
      dispatch(getRoomRowTeams({
        getToken,
        rooms: viewableIds,
      }))
    }
  }

  const handleChange = (pageInfo, filterInfo, sortInfo, dataSource) => {
    UnassignedUpdateTable(pageInfo, filterInfo, sortInfo)
    const { currentDataSource, } = dataSource
    handlePaginate(pageInfo, currentDataSource)
    // if (action === 'sort') {
    //   console.log('sorting')
    // }
    // if (action === 'filter') {
    //   console.log('filter')
    // }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters({ confirm: true, })
    setSearchText('')
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8, }}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value,] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90, }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: (text) => {
      if (!text) {
        return 'None'
      }
      const highlight = dataIndex === 'secondaryId'
      const name = text.split('-')[1]
      if (searchedColumn === dataIndex) {
        return <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0, }} searchWords={[searchText,]} autoEscape textToHighlight={!highlight ? text ? text.toString() : '' : name ? name.toString() : '' } />
      } else {
        return !highlight ? text : name
      }
    },
  })
  // columns
  const columns = [
    {
      title: 'Organization',
      dataIndex: 'secondaryId',
      key: 'secondaryId',
      width: 600,
      sorter: (a,b) => a.secondaryId > b.secondaryId ? 1 : b.secondaryId > a.secondaryId ? -1 : 0,
      sortOrder: UnassignedSorter && UnassignedSorter.columnKey === 'secondaryId' && UnassignedSorter.order || null,
      filteredValue: UnassignedFilters && UnassignedFilters.secondaryId || null,
      onFilter: (value, record) => !value ? record.secondaryId : record.secondaryId.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('secondaryId'),
    },
    {
      title: 'Bed Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      sorter: (a,b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      sortOrder: UnassignedSorter && UnassignedSorter.columnKey === 'name' && UnassignedSorter.order || null,
      filteredValue: UnassignedFilters && UnassignedFilters.name || null,
      onFilter: (value, record) => !value ? record.name : record.name.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Current Assignments',
      dataIndex: 'orgs',
      key: 'orgs',
      render: (text) => {
        if (!text) {
          return ''
        }
        const textArray = []
        for (const t of text) {
          textArray.push(t.split('-')[1])
          textArray.push(', ')
        }
        textArray.pop()
        return <div>{textArray.length ? textArray : 'Unassigned'}</div>
      },
    },
  ]
  const assignedColumn = [
    {
      title: 'Organization',
      dataIndex: 'secondaryId',
      key: 'secondaryId',
      width: 600,
      sorter: (a,b) => a.secondaryId > b.secondaryId ? 1 : b.secondaryId > a.secondaryId ? -1 : 0,
      sortOrder: assignedSorter && assignedSorter.columnKey === 'secondaryId' && assignedSorter.order || null,
      filteredValue: assignedFilters && assignedFilters.secondaryId || null,
      onFilter: (value, record) => !value ? record.secondaryId : record.secondaryId.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('secondaryId'),
    },
    {
      title: 'Bed Name',
      dataIndex: 'name',
      key: 'name',
      width: 600,
      sorter: (a,b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      sortOrder: assignedSorter && assignedSorter.columnKey === 'name' && assignedSorter.order || null,
      filteredValue: assignedFilters && assignedFilters.name || null,
      onFilter: (value, record) => !value ? record.name : record.name.toLowerCase().includes(value.toString().toLowerCase()),
      ...getColumnSearchProps('name'),
    },
  ]
  const updateTable = (pagination, filters, sorters) => {
    setAssignedSorter(sorters)
    setAssignedFilters(filters)
  }
  const UnassignedUpdateTable = (pagination, filters, sorters) => {
    setUnAssignedSorter(sorters)
    setUnassignedFilters(filters)
  }
  useEffect(() => {
    if (!setTeamLoading) {
      const pageInfo = {
        current: 1,
        pageSize: 10,
      }
      const dataSource = {
        action: 'paginate',
        currentDataSource: team.beds,
      }
      handleChange(pageInfo, {}, {}, dataSource)
    }
  },[setTeamLoading,])
  return (
      <div>
          <div className='teams-table'>
          <h2>Assigned Beds</h2>
            <div
            className='team-table-container'
            >
              <Button type='primary' disabled={!selected} onClick={handleUnassignClick} loading={bedAssignmentLoading} icon={<MinusCircleOutlined />}>Unassign</Button>
            </div>
              <Table onChange={updateTable}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRows,
                onChange: (keys) => {
                  setSelectedRows(keys)
                },
              }} dataSource={team.teamBedAssignments || []} columns={assignedColumn || []} size='large' rowKey="secondaryId"
              className='team-table-margin'/>

          </div>
          <div className='teams-table'>
              <h2>Unassigned Beds</h2>
                <div
                className='team-table-container'
              >
                  <Button type='primary' disabled={!assigned} onClick={handleAssignClick} loading={bedAssignmentLoading} icon={<PlusCircleOutlined />}>Assign</Button>
                </div>
              <Table rowSelection={{
                type: 'checkbox',
                selectedRowKeys: assignRows,
                onChange: (keys) => {
                  setAssignRows(keys)
                },
              }}
              onChange={handleChange}
              dataSource={team.beds || []} columns={columns || []} size='large' rowKey={record => record}
              className='team-table-margin'
              />
          </div>
        </div>

  )
}
export default Beds
