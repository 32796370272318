import React, { useEffect, useState, useRef, FC, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Input,
  Collapse,
  Radio,
  Button,
  Badge,
  message,
  Dropdown,
  Tooltip
} from 'antd'
import { playSound, slackMuteCancel } from './helpers'
import { UndoOutlined, DownOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Feed } from '../../components/Monitor/Feed'
import NotifOptions from '../../components/NotifOptions'
import Canvas from '../../components/Canvas'
import Loading from '../../components/Loading'
import Notes from '../../components/Monitor/Notes'
import {
  checkForAlert,
  alphaSort,
  returnAlertColor,
  abbreviateAlert,
  fallRiskAlphaSort,
  dataPassedIntoAmpEvent,
  checkIfInPrivacyMode,
  newSmartSort
} from '../../utility/general'
import { delay } from '../../utility/helpers'
import { updateConfig, getConfig, deleteRegion } from '../../utility'
import { mapNotifToText, mapFallRiskText } from '../../rules'
import {
  addNote,
  viewNote,
  getNotes,
  fetchUnits,
  promoteNotif,
  resolveNotif,
  reportFalseNeg,
  reportPatientUpdate,
  fetchNotif,
  monitorAssignment,
  updateProdAlert, rejectNotif
} from '../../state/actions'
import {
  User,
  Room,
  CardSizes,
  DrawStates,
  Notification,
  RoomSortOptions,
  RegionTypes,
  RoomNote,
  SimpleHash,
  HashOfArrays,
  HashOfObjects,
  UnitsWrapper
} from '../../state/types'
import { mapStateToProps } from './mapStateToProps'
import { CascaderValueType } from 'antd/lib/cascader'
import { sendAmpEvent } from '../../utility/analytics'
import CreateManualEvent from '../../components/Monitor/CreateManualEvent'
import ClearMenu from '../../components/Monitor/ClearMenu'
import { MonitorTopBar } from '../../components/Monitor/MonitorTopBar'
import NoZoneUpdateButton from '../../components/Monitor/NoZoneUpdate'
const { TextArea, } = Input
const { Panel, } = Collapse

type MonitorProps = {
  dispatch: any;
  user: User;
  units: UnitsWrapper[];
  rooms: Room[];
  selectedUnit: CascaderValueType;
  roomNotesHash: HashOfArrays<RoomNote>;
  notifHash: HashOfObjects<Notification>;
  noteViewHash: SimpleHash;
  notifLoading: boolean;
  notesLoading: boolean;
  unitLoading: boolean;
};

const Monitor: FC<MonitorProps> = ({
  dispatch,
  user,
  units,
  rooms = [],
  selectedUnit,
  actionLoading = null,
  roomNotesHash = {},
  notifHash = {},
  noteViewHash = {},
  notifLoading = false,
  notesLoading,
  unitLoading,
  staffNames,
  staffNamesLoading,
  slackUpdateLoading,
}) => {
  const { getAccessTokenSilently: getToken, } = useAuth0()
  // this saves the state before we move
  const [regionStart, setRegionStart,] = useState<any>(null)
  // this saves the state after we move
  const [regionEnd, setRegionEnd,] = useState<any>(null)
  // states to see if undo or redo is on
  const [undoState, setUndoState,] = useState(false)
  const [redoState, setRedoState,] = useState(false)
  const [region, setRegion,] = useState<RegionTypes>('bed')
  const [whichRegionUpdated, setWhichRegionUpdated,] = useState([])
  const [currentVersion, setVersion,] = useState<string | null>(null)
  const [allPoints, setAllPoints,] = useState<any>([])
  const [canvasRoom, toggleCanvas,] = useState<Room | null>(null)
  const [canvasOn, toggleCanvasOn,] = useState<boolean>(false)
  // const [roomsDisableState, setRoomsDisableState,] = useState<any>({})
  const [openRoom, toggle,] = useState<string>('')
  const [note, typeNote,] = useState<string>('')
  const [drawState, updateDrawState,] = useState<DrawStates>('move')
  const [room, updateRoom,] = useState<Room | null>(null)
  const [hide, toggleHide,] = useState<boolean>(false)
  const [pointsUpdated, setPointsUpdated,] = useState<boolean>(false)
  const [clear, toggleClear,] = useState<boolean>(false)
  const [playbackRoom, updatePlaybackRoom,] = useState<boolean>(false)
  const [reset, resetCounter,] = useState<number>(0)
  const [cardSize, updateCardSize,] = useState<CardSizes>('extra-small')
  const [sort, updateSort,] = useState<RoomSortOptions>('alphabetical')
  const fr = useMemo(
    () => mapFallRiskText(room?.fallRiskLevel, 'large'),
    [room?.fallRiskLevel,]
  )
  const roomsRef = useRef<Room[]>()
  const childRef = useRef()
  roomsRef.current = rooms
  const canvasRoomRef = useRef<Room | null>()
  canvasRoomRef.current = canvasRoom
  const notes =
    openRoom && roomNotesHash[openRoom] ? roomNotesHash[openRoom] : []
  const keysPressed = {}

  if (checkIfInPrivacyMode(room?.priv)) updateRoom(null)
  const togglePic = (visible, room) => {
    if (!visible) return updateRoom(null)
    updateRoom(room)
  }
  const togglePlaybackPic = (visible) => {
    if (!visible) return updatePlaybackRoom(false)
    updatePlaybackRoom(true)
  }
  const changeComment = (e: any) => {
    const { target, } = e
    const { value, } = target

    return typeNote(value)
  }
  const confirm = (basestations, mute) => {
    slackMuteHandler(basestations, mute)
    mute
      ? message.success(`${basestations} was muted`)
      : message.success(`${basestations} was unmuted`)
  }

  const slackMuteHandler = (basestation, mute) => {
    dispatch(updateProdAlert({ getToken, basestation, mute, }))
  }
  const getNotif = (roomId, notifId) => {
    dispatch(fetchNotif({ getToken, roomId, notifId, }))
  }

  switch (sort) {
    case 'smart':
      rooms = newSmartSort(rooms, staffNames)
      break
    case 'alphabetical':
      rooms = rooms.sort(alphaSort)
      break
    case 'fall risk':
      rooms = fallRiskAlphaSort(rooms)
      break
    default:
      rooms = newSmartSort(rooms, staffNames)
      break
  }

  useEffect(() => {
    dispatch(fetchUnits({ getToken, }))
    if (user?.role === 'monitor') {
      dispatch({
        type: 'SET_UNIT',
        payload: {
          unit: ['custom', null,],
        },
      })
    }
    window.addEventListener('keydown', traverseRoom)
    window.addEventListener('keyup', (e) => {
      delete keysPressed[e.code]
    })

    return () => window.removeEventListener('keydown', traverseRoom)
  }, [user.email,])

  const promote = (roomId, notificationId) => {
    return dispatch(promoteNotif({ getToken, roomId, notificationId, user, }))
  }

  const resolve = useCallback((roomId: string, notificationId: number) => {
    return dispatch(
      resolveNotif({ getToken, roomId, notificationId, user, })
    )
  }, [resolveNotif, getToken, user,])

  const reject = useCallback((roomId: string, notificationId: number) => {
    return dispatch(
      rejectNotif({ getToken, roomId, notificationId, user, })
    )
  }, [rejectNotif, getToken, user,])

  const falseNeg = (roomId, flag) => {
    return dispatch(reportFalseNeg({ getToken, roomId, flag, }))
  }

  const manualUpdate = (roomId, message, enable) => {
    return dispatch(reportPatientUpdate({ getToken, roomId, message, enable, }))
  }

  const assignMonitor = (roomId, remove = false) => {
    return dispatch(monitorAssignment({ getToken, roomId, user, remove, }))
  }

  const openNotes = (roomId) => {
    toggle(roomId)
    dispatch(getNotes({ getToken, roomId, }))
    dispatch(viewNote({ room: roomId, }))
  }

  const openCanvas = async (room) => {
    toggleCanvas(room)
    updateRoom(room)
    toggleHide(false)
    toggleCanvasOn(true)
    sendAmpEvent('Opened Canvas', dataPassedIntoAmpEvent(room?.mainId))
    canvasRoomRef.current = room
    setAllPoints([])
    const { regions: rawPoints, version, } = await getConfig(
      getToken,
      room.mainId
    )

    const currentPoints = rawPoints.map((pt) => {
      if (pt.x > 1) pt.x = 0.9999
      if (pt.y > 1) pt.y = 0.9999
      return pt
    })

    setAllPoints(currentPoints)
    setVersion(version)
    resetCounter(reset + 1)
    refreshCanvasRoom()
  }

  const addPoint = (pt) => {
    setRegionStart(allPoints)
    const newPoints = allPoints.map((pt) => {
      delete pt.last
      return pt
    })

    pt.region = region
    pt.x = Math.min(pt.x, 0.9999)
    pt.y = Math.min(pt.y, 0.9999)
    newPoints.push(pt)
    setWhichRegionUpdated([...whichRegionUpdated, pt.region,])
    setAllPoints(newPoints)
    setRegionEnd(newPoints)
    setUndoState(true)
    setPointsUpdated(true)
  }

  const moveRegion = (updatedRegPts, currentRegion) => {
    if (!currentRegion) currentRegion = region
    const newPoints = allPoints
      .filter((pt) => pt.region !== currentRegion)
      .concat(updatedRegPts)
    setWhichRegionUpdated([...whichRegionUpdated, updatedRegPts[0].region,])
    setAllPoints(newPoints)
    setPointsUpdated(true)
  }

  const clearRegion = ({ key, }) => {
    if (key === 'all') {
      setAllPoints([])
      toggleClear(!clear)
    } else {
      const newPoints = allPoints.filter((pt) => pt.region !== region)
      setAllPoints(newPoints)
      toggleClear(!clear)
    }
  }

  const updateRegion = (reg) => {
    setRegion(reg.target.value)
  }

  const updateDraw = (e) => {
    updateDrawState(e.target.value)
  }

  const closeNotes = () => {
    dispatch(viewNote({ room: openRoom, }))
    toggle('')
  }

  const submitNote = () => {
    if (!note) {
      dispatch(viewNote({ room: openRoom, }))
      toggle('')
      typeNote('')
      return
    }

    dispatch(addNote({ getToken, roomId: openRoom, note, }))
    typeNote('')
    dispatch(viewNote({ room: openRoom, }))
  }

  const submitRegions = async () => {
    try {
      if (!canvasRoom) return
      const unique = [...new Set(whichRegionUpdated),]
      const other = unique.join(' ')
      await updateConfig(
        getToken,
        canvasRoom.mainId,
        allPoints,
        currentVersion,
        other,
        canvasRoom?.enable
      )
      setWhichRegionUpdated([])
      setAllPoints([])
      window.alert(
        'Version has been updated\nUpdating canvas with new regions'
      )
      const { regions: currentPoints, version, } = await getConfig(
        getToken,
        canvasRoom.mainId
      )
      setAllPoints(currentPoints)
      setVersion(version)
      setRedoState(false)
      setUndoState(false)
      sendAmpEvent(
        'Updated Canvas',
        dataPassedIntoAmpEvent(canvasRoom?.mainId)
      )
      setPointsUpdated(false)
      await delay(5000)
      // refreshCanvasRoom()
    } catch (err) {
      window.alert('Error updating canvas, pleae close and re-open modal')
    }
  }

  const refreshCanvasRoom = async () => {
    if (!roomsRef || !canvasRoomRef || !canvasRoomRef.current) return
    const latestRoom =
      roomsRef.current &&
      roomsRef.current.filter(
        (r) => r.mainId === canvasRoomRef?.current?.mainId
      )
    if (latestRoom && latestRoom[0]) {
      toggleCanvas(latestRoom[0])
      resetCounter((reset) => reset + 1)
    }
  }
  const removeRegion = async () => {
    try {
      if (!canvasRoom) return
      await deleteRegion(getToken, canvasRoom.mainId, region, currentVersion)
      window.alert(
        `Deleted ${region}\n About to update canvas with new config`
      )
      const { regions: currentPoints, version, } = await getConfig(
        getToken,
        canvasRoom.mainId
      )
      setAllPoints(currentPoints)
      setVersion(version)
      sendAmpEvent(
        'Updated Canvas',
        dataPassedIntoAmpEvent(canvasRoom?.mainId)
      )
    } catch (err) {
      window.alert('Error updating canvas, pleae close and re-open modal')
    }
  }
  const traverseRoom = async (e: any) => {
    keysPressed[e.code] = true
    if (
      ![
        'ArrowRight',
        'ArrowLeft',
        'ControlLeft',
        'KeyB',
        'KeyC',
        'KeyE',
        'KeyD',
        'KeyM',
        'KeyU',
        'KeyR',
        'Backspace',
      ].includes(e.code)
    ) {
      return
    }
    if (
      !roomsRef ||
      !roomsRef.current ||
      !canvasRoomRef ||
      !canvasRoomRef.current
    ) {
      return
    }
    // eslint-disable-next-line dot-notation
    if (keysPressed['ControlLeft'] && e.code === 'KeyC') {
      updateRegion({
        target: {
          value: 'chair',
        },
      })
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'KeyB') {
      updateRegion({
        target: {
          value: 'bed',
        },
      })
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'KeyE') {
      updateRegion({
        target: {
          value: 'bedEdge',
        },
      })
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'KeyM') {
      updateDraw({
        target: {
          value: 'move',
        },
      })
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'KeyD') {
      updateDraw({
        target: {
          value: 'draw',
        },
      })
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'KeyU' && !undoState) {
      childRef?.current?.handleUndo()
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'KeyR' && !redoState) {
      childRef?.current?.handleRedo()
      // eslint-disable-next-line dot-notation
    } else if (keysPressed['ControlLeft'] && e.code === 'Backspace') {
      clearRegion({ key: 'all', })
    } else if (e.code === 'ArrowRight' || e.code === 'ArrowLeft') {
      updateDrawState('move')
      const roomIds = roomsRef.current.map((r) => r.mainId)
      const currIdx = roomIds.indexOf(canvasRoomRef.current.mainId)
      const forward = e.code === 'ArrowRight'
      if (currIdx > -1) {
        const tempIdx = forward ? currIdx + 1 : currIdx - 1
        const idx = forward
          ? Math.min(tempIdx, roomIds.length - 1)
          : Math.max(tempIdx, 0)
        if (idx === currIdx) return

        const newRoom = roomsRef.current[idx]
        openCanvas(newRoom)
        sendAmpEvent(
          `Traversed Canvas ${forward ? 'forward' : 'back'} to new room`,
          dataPassedIntoAmpEvent(newRoom?.mainId)
        )
        setPointsUpdated(false)
        setRedoState(false)
        setUndoState(false)
        setWhichRegionUpdated([])
      }
    }
  }

  const exitCanvas = () => {
    toggleCanvas(null)
    setAllPoints([])
    toggleHide(true)
    toggleCanvasOn(false)
    setPointsUpdated(false)
    setRedoState(false)
    setUndoState(false)
    sendAmpEvent('Canvas closed', dataPassedIntoAmpEvent(canvasRoom?.mainId))
    setWhichRegionUpdated([])
  }

  const waiting =
    (selectedUnit && !rooms.length && staffNamesLoading) || unitLoading
  const { alert, level, adminAlert, }: any = checkForAlert(room)
  return (
    <>
      <MonitorTopBar
        sort={sort}
        cardSize={cardSize}
        onCardSizeChange={updateCardSize}
        onSortChange={updateSort}
      />
      {room &&
        room.name &&
        !playbackRoom &&
        !hide &&
        !checkIfInPrivacyMode(room?.priv) && (
          <div className="monitor-room-label-num">{room.name}</div>
      )}
      {room && room.name && !hide && !checkIfInPrivacyMode(room?.priv) && (
        <div className="monitor-room-label-fr">
          {fr && (
            <div
              className="bottom-left"
              id="fall-risk"
              data-size="large"
              data-label={fr}
            >
              {fr}
            </div>
          )}
        </div>
      )}
      {room?.name &&
        !playbackRoom &&
        !hide &&
        !canvasOn &&
        !checkIfInPrivacyMode(room?.priv) && (
          <div className="monitor-room-manual-buttons">
            <CreateManualEvent
              alert={alert && alert}
              falseNeg={falseNeg}
              loading={actionLoading}
              room={room}
              cardSize={cardSize}
              mainId={room.mainId}
              report={manualUpdate}
            />
          </div>
      )}
      {room &&
        alert &&
        alert !== 'urgent' &&
        alert !== 'early_warning' &&
        adminAlert !== 'fall' &&
        !hide &&
        !canvasOn && (
          <div className="monitor-room-promote-resolve-container">
            {room?.alert
              ? (
              <Badge
                count={abbreviateAlert(room?.alert) || ''}
                color={returnAlertColor(room?.alert)}
                title={`current active alert: ${room?.alert}`}
              >
                <div className="monitor-room-promote-resolve-options">
                  <div>{mapNotifToText(alert)} Alert</div>
                  <div className="monitor-room-promote-resolve-options-container">
                    <NotifOptions
                      promote={promote}
                      resolve={resolve}
                      reject={reject}
                      room={room}
                      level={level}
                      toggleHide={toggleHide}
                      togglePlaybackPic={togglePlaybackPic}
                    />
                  </div>
                </div>
              </Badge>
                )
              : (
              <div className="monitor-room-promote-resolve-options">
                <div>{mapNotifToText(alert)} Alert</div>
                <div className="monitor-room-promote-resolve-options-container">
                  <NotifOptions
                    promote={promote}
                    resolve={resolve}
                    reject={reject}
                    room={room}
                    level={level}
                    toggleHide={toggleHide}
                    togglePlaybackPic={togglePlaybackPic}
                  />
                </div>
              </div>
                )}
          </div>
      )}
      {!waiting
        ? (
        <div className="grid-container" data-size={cardSize}>
          {rooms.map((r) => {
            const notif = notifHash[r.mainId]
            const lastNoteView = noteViewHash && noteViewHash[r.mainId]
            return (
              <Feed
                key={r.mainId}
                room={r}
                user={user}
                currentRoom={room}
                openNotes={() => openNotes(r.mainId)}
                openCanvas={() => openCanvas(r)}
                loading={actionLoading}
                promote={promote}
                resolve={resolve}
                reject={reject}
                report={manualUpdate}
                falseNeg={falseNeg}
                togglePic={togglePic}
                togglePlaybackPic={togglePlaybackPic}
                toggleHide={toggleHide}
                updateRoom={updateRoom}
                getNotif={getNotif}
                notif={notif}
                lastNoteView={lastNoteView}
                notifLoading={notifLoading}
                hide={hide}
                playSound={playSound}
                assignMonitor={assignMonitor}
                cardSize={cardSize}
                custom={
                  selectedUnit &&
                  !!selectedUnit[0] &&
                  selectedUnit[0] === 'custom'
                }
                getToken={getToken}
                staffNames={staffNames}
                slackMuteHandler={slackMuteHandler}
                slackConfirm={confirm}
                slackCancel={slackMuteCancel}
                slackUpdateLoading={slackUpdateLoading}
              />
            )
          })}
        </div>
          )
        : (
        <Loading type="bars" />
          )}
      <Modal
        title="Room Notes"
        visible={!!openRoom}
        okText={note.length ? 'Submit' : 'OK'}
        onOk={submitNote}
        onCancel={closeNotes}
      >
        <Collapse defaultActiveKey={['1',]} ghost>
          <Panel header="View Notes" key="1">
            <div style={{ margin: 4, }}>
              {notesLoading
                ? (
                <Loading
                  type="spin"
                  height="50"
                  width="50"
                />
                  )
                : (
                <Notes notes={notes} />
                  )}
            </div>
          </Panel>
          <Panel header="Add Note" key="2">
            <div style={{ margin: 4, }}>
              <p>Note</p>
              <TextArea
                rows={4}
                showCount
                maxLength={250}
                value={note}
                onChange={changeComment}
              />
            </div>
          </Panel>
        </Collapse>
      </Modal>
      <div className="canvas-wrapper">
        <Modal
          wrapClassName="canvas-wrapper"
          title={`Canvas Room: ${canvasRoom?.name}`}
          style={{
            position: 'absolute',
            left: '50%',
            marginLeft: -500,
            width: 1000,
          }}
          bodyStyle={{ width: '100%', }}
          visible={!!canvasRoom}
          okText={'Update'}
          onOk={submitRegions}
          onCancel={exitCanvas}
          footer={
            pointsUpdated
              ? (
              <div>
                <Button onClick={exitCanvas}>Cancel</Button>
                <Button type="primary" onClick={submitRegions}>
                  Update
                </Button>
              </div>
                )
              : null
          }
        >
          {room?.enable?.zoneUpdate
            ? (
            <NoZoneUpdateButton
              getToken={getToken}
              roomId={room?.mainId}
              enable={room?.enable}
            />
              )
            : null}

          <div className="region-radio-buttons"></div>
          <Radio.Group
            value={region}
            onChange={updateRegion}
            defaultValue={region}
          >
            <Tooltip title="control+B">
              <Radio.Button value={'bed'}>Bed</Radio.Button>
            </Tooltip>
            <Tooltip title="control+E">
              <Radio.Button value={'bedEdge'}>Bed Edge</Radio.Button>
            </Tooltip>
            <Tooltip title="control+C">
              <Radio.Button value={'chair'}>Chair</Radio.Button>
            </Tooltip>
            <Tooltip title="control+delete">
              <Dropdown
                overlay={
                  <ClearMenu clearRegion={clearRegion} region={region} />
                }
              >
                <Button type="primary" className="clear-button">
                  Clear
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Tooltip>
            <Tooltip title="unassigned">
              <Button danger onClick={removeRegion} className="delete-button">
                Delete
              </Button>
            </Tooltip>
            <UndoOutlined
              twoToneColor={'black'}
              style={{
                position: 'absolute',
                right: 200,
                height: 20,
                width: 20,
              }}
            />
          </Radio.Group>
          <Radio.Group
            style={{ marginLeft: 16, }}
            value={drawState}
            onChange={updateDraw}
            defaultValue={'move'}
          >
            <Tooltip title="control+M">
              <Radio value={'move'}>Move</Radio>
            </Tooltip>
            <Tooltip title="control+D">
              <Radio value={'draw'}>Draw</Radio>
            </Tooltip>
          </Radio.Group>
          <Canvas
            width={953}
            height={720}
            imageSrc={
              canvasRoomRef &&
              canvasRoomRef.current &&
              canvasRoomRef.current.live
            }
            room={
              canvasRoomRef &&
              canvasRoomRef.current &&
              canvasRoomRef.current.name
            }
            region={region}
            addPoint={addPoint}
            moveRegion={moveRegion}
            allPoints={allPoints}
            draw={drawState}
            clear={clear}
            reset={reset}
            setAllPoints={setAllPoints}
            regionStart={regionStart}
            setRegionStart={setRegionStart}
            regionEnd={regionEnd}
            setRegionEnd={setRegionEnd}
            undoState={undoState}
            redoState={redoState}
            setUndoState={setUndoState}
            setRedoState={setRedoState}
            ref={childRef}
            setWhichRegionUpdated={setWhichRegionUpdated}
            whichRegionUpdated={whichRegionUpdated}
          />
        </Modal>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(Monitor)
