import { message, Popconfirm, Tooltip } from 'antd'
import { SlackSquareOutlined } from '@ant-design/icons'
import React, { useCallback } from 'react'
import { Room } from '../../../state/types'
import { updateProdAlert } from '../../../state/actions'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from '../../../state/hooks'

interface Props {
  room: Room
  iconSize?: 'small' | 'large'
}

export const SlackNotificationsConfirmButton = ({ room, iconSize, }: Props) => {
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const dispatch = useAppDispatch()

  const handleSlackMuteToggle = useCallback(() => {
    dispatch(updateProdAlert({ getToken, basestation: room.mainId, mute: !room.mute, }))
    !room.mute
      ? message.success(`${room.mainId} was muted`)
      : message.success(`${room.mainId} was unmuted`)
  }, [room.mainId, room.mute,])

  const handleCancel = useCallback(() => {
    return message.error('action canceled')
  }, [])

  return <Popconfirm
    title={
      room?.mute
        ? 'are you sure you want to unmute this room?'
        : 'are you sure you want to mute this room?'
    }
    onConfirm={handleSlackMuteToggle}
    onCancel={handleCancel}
    okText="Yes"
    cancelText="No"
  >
    <div id="slack-icon" data-mute={room?.mute ? 'on' : null} style={{ fontSize: iconSize === 'small' ? '16px' : '32px', }}>
      <Tooltip
        title={
          room?.mute
            ? 'unmute this room on slack'
            : 'mute this room on slack'
        }
      >
        <SlackSquareOutlined/>
      </Tooltip>
    </div>{' '}
  </Popconfirm>
}
