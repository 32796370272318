import React, { useMemo } from 'react'
import moment from 'moment/moment'
import { CardSizes, Room } from '../../../state/types'
import { EyeInvisibleOutlined } from '@ant-design/icons'

interface Props {
  cardSize: CardSizes,
  room: Room
}

export const RoomPrivacyModeOnView = ({ cardSize, room, }: Props) => {
  const now = moment()
  const privFormatted = useMemo(() => {
    // has to be moment to use .diff
    const privTime = moment(room.priv)
    // get the diff in time
    const privTimeLeft = privTime.diff(now, 'seconds')
    // format the time
    return moment.utc(privTimeLeft * 1000).format('HH:mm:ss')
  }, [room.priv, now,])

  return <div className="private-container" data-size={cardSize}>
    <div className="privacy-mode-container">
      <div>
        <EyeInvisibleOutlined className="privacy-mode-eye" />
        <span className="privacy-mode-text">Privacy Mode</span>
      </div>
      <div className="privacy-mode-timer">{privFormatted}</div>
    </div>
    { room.virtual && <div className="grid-virtual-indicator">Virtual</div> }
  </div>
}
