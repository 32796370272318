import React from 'react'
import ConfirmButton from '../ConfirmButton'
import {
  SyncOutlined,
  RadiusSettingOutlined,

  CheckCircleOutlined
} from '@ant-design/icons'
const HoverButtons = ({ room, openCanvas, report, loading, cardSize, colors, }) => {
  return (
        <div>
            {room?.enable?.active
              ? (
                    <div className="section-title">
                        Update Required <SyncOutlined />
                    </div>
                )
              : null}{' '}
            {room?.enable?.active
              ? (
                    <div
                        className="section-title clickable"
                        onClick={() => {
                          openCanvas()
                        }}
                    >
                        <RadiusSettingOutlined /> Zone Update{' '}
                        {!room?.enable?.zoneUpdate
                          ? (
                                <CheckCircleOutlined className="checked" />
                            )
                          : null}
                    </div>
                )
              : null}
            <div className="section-title">
                Report Patient Update{' '}
                {room?.enable?.active && !room?.enable?.patientUpdate
                  ? (
                        <CheckCircleOutlined className="checked" />
                    )
                  : null}
            </div>
            <div className="section-title">
                <ConfirmButton
                    color={colors.lightGreen}
                    style={{ marginRight: 4, }}
                    title={'Room'}
                    prompt={"Are you sure you want to update patient to 'In Room'?"}
                    onPress={() => {
                      report(room.mainId, 'room', room?.enable)
                    }}
                    loading={loading && loading === 'room'}
                    confirm={false}
                    size={cardSize}
                    trunc={true}
                />
                <ConfirmButton
                    color={'#bcaaa4'}
                    style={{ marginRight: 4, }}
                    title={'Chair'}
                    prompt={"Are you sure you want to update patient to 'In Chair'?"}
                    onPress={() => {
                      report(room.mainId, 'chair', room?.enable)
                    }}
                    loading={loading && loading === 'chair'}
                    confirm={false}
                    size={cardSize}
                    trunc={true}
                />
                <ConfirmButton
                    color={colors.lightBlue}
                    style={{ marginRight: 4, }}
                    title={'Bed'}
                    prompt={"Are you sure you want to update patient to 'In Bed'?"}
                    onPress={() => {
                      report(room.mainId, 'bed', room?.enable)
                    }}
                    loading={loading && loading === 'bed'}
                    confirm={false}
                    size={cardSize}
                    trunc={true}
                />
                <ConfirmButton
                    color={colors.orange}
                    style={{ marginRight: 4, }}
                    title={'Staff'}
                    prompt={"Are you sure you want to update patient to 'Staff in Room'?"}
                    onPress={() => {
                      report(room.mainId, 'staff', room?.enable)
                    }}
                    loading={loading && loading === 'staff'}
                    confirm={false}
                    size={cardSize}
                    trunc={true}
                />
                <ConfirmButton
                    color={colors.lightGray}
                    style={{ marginRight: 4, }}
                    title={'Empty'}
                    prompt={"Are you sure you want to update patient to 'Out of Room'?"}
                    onPress={() => {
                      report(room.mainId, 'empty', room?.enable)
                    }}
                    loading={loading && loading === 'empty'}
                    confirm={false}
                    size={cardSize}
                    trunc={true}
                />
            </div>
        </div>
  )
}
export default HoverButtons
