import Loading from '../../Loading'
import React from 'react'
import { CardSizes, Room } from '../../../state/types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { SlackNotificationsConfirmButton } from './SlackNotificationsConfirmButton'
import { Space } from 'antd'
import { useAppSelector } from '../../../state/hooks'

interface Props {
  cardSize: CardSizes,
  showAdminActions: boolean,
  room: Room
}

export const RoomOfflineView = ({ cardSize, showAdminActions, room, }: Props) => {
  const slackLoading = useAppSelector(store => store.mainReducer.slackUpdateLoading || false)

  return <div className="offline-container" data-size={cardSize}>
    <div className="privacy-mode-text">
      {' '}
      <ExclamationCircleOutlined className="privacy-mode-eye" />
      Offline
    </div>
    <div>
      {slackLoading
        ? (
          <Loading
            type="spin"
            height="16"
            width="16"
          />
          )
        : showAdminActions
          ? (
            <Space align="center">
              <SlackNotificationsConfirmButton room={room} />
            </Space>
            )
          : null}
    </div>
    { room.virtual && <div className="grid-virtual-indicator">Virtual</div> }
  </div>
}
