import React from 'react'
import { Button, Menu, Dropdown } from 'antd'
import {
  UserOutlined,
  DownOutlined
} from '@ant-design/icons'

function OrgDropdown ({ selectedOrg, selectOrg, orgs, }: any) {
  if (!orgs || !orgs.length) return null
  const menu = (
        <Menu onClick={(e) => selectOrg(e.key)}>
            {orgs.map((o: any) => {
              return (
                <Menu.Item key={o.id} icon={<UserOutlined />}>
                {o.label}
                </Menu.Item>
              )
            })}
        </Menu>
  )

  return (
        <Dropdown overlay={menu}>
          <Button>
            {selectedOrg || 'Select Org'}<DownOutlined />
          </Button>
        </Dropdown>
  )
}

export default OrgDropdown
