import { combineReducers } from 'redux'
import mainReducer from './mainReducer'
import errorReducer from './errorReducer'

const rootReducer = combineReducers({
  mainReducer,
  errorReducer,
})

export default rootReducer
