import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import colors from '../styles/colors'
import classifyPt from 'robust-point-in-polygon'
import { Button, Tooltip } from 'antd'
import { UndoOutlined, RedoOutlined } from '@ant-design/icons'

const regionColors = {
  bed: {
    strokeStyle: colors.blue,
    fillStyle: colors.blue,
  },
  chair: {
    strokeStyle: colors.yellow,
    fillStyle: colors.yellow,
  },
  bedEdge: {
    strokeStyle: colors.purple,
    fillStyle: colors.purple,
  },
}

const pointRadius = 5

const Canvas = forwardRef((props, ref) => {
  const {
    regionStart,
    setRegionStart,
    setRegionEnd,
    undoState,
    redoState,
    setUndoState,
    setRedoState,
    regionEnd,
  } = props
  const backgroundRef: any = useRef(null)
  const canvasRef: any = useRef(null)
  const [lastRegion, updateLastRegion,] = useState('bed')
  const [isDragging, updateDragState,] = useState(false)
  const [isWedge, updateWedgeState,] = useState<any>(false)
  const [pointer, setPointer,] = useState(false)
  const [start, updateStart,] = useState<any>(null)
  const [end, updateEnd,] = useState<any>(null)
  const [moved, updateMoved,] = useState<any>([])
  const [localAllPts, setLocalPts,] = useState<any>([])
  const [latestRegion, updateRegion,] = useState<any>('bed')
  const [regionPop, setRegionPop,] = useState<any>('')
  const handleUndo = () => {
    const {
      allPoints,
      setAllPoints,
      setWhichRegionUpdated,
      whichRegionUpdated,
    } = props
    if (start) {
      const undoPoints = allPoints.map(pt => {
        if (pt.x === end.x && pt.y === end.y) {
          pt.x = start.x
          pt.y = start.y
        }
        return pt
      })
      setAllPoints(undoPoints)
      setLocalPts(undoPoints)
      drawRegions(undoPoints)
      const regions = whichRegionUpdated
      setRegionPop(regions.pop())
      setWhichRegionUpdated(regions)
      setRedoState(true)
      setUndoState(false)
    } else {
      setRegionEnd(allPoints)
      setAllPoints(regionStart)
      setLocalPts(regionStart)
      setRedoState(true)
      setUndoState(false)
      const regions = whichRegionUpdated
      setRegionPop(regions.pop())
      setWhichRegionUpdated(regions)
      drawRegions(regionStart)
    }
  }
  const handleRedo = () => {
    const {
      allPoints,
      setAllPoints,
      setWhichRegionUpdated,
      whichRegionUpdated,
    } = props
    if (start) {
      const redoPoints = allPoints.map(pt => {
        if (pt.x === start.x && pt.y === start.y) {
          pt.x = end.x
          pt.y = end.y
        }
        return pt
      })
      setAllPoints(redoPoints)
      setLocalPts(redoPoints)
      drawRegions(redoPoints)
      setWhichRegionUpdated([...whichRegionUpdated, regionPop,])
      setRedoState(false)
      setUndoState(true)
    } else {
      setAllPoints(regionEnd)
      setLocalPts(regionEnd)
      drawRegions(regionEnd)
      setUndoState(true)
      setWhichRegionUpdated([...whichRegionUpdated, regionPop,])
      setRedoState(false)
    }
  }
  const getRelativeVals = (canvas, e) => {
    const { width, height, } = props
    const rect = canvas.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    const relativeX = x / width
    const relativeY = y / height

    return { x, relativeX, y, relativeY, }
  }
  useImperativeHandle(ref, () => ({
    handleUndo () {
      handleUndo()
    },
    handleRedo () {
      handleRedo()
    },
  }))

  const handleClick = e => {
    const { region, draw, allPoints, } = props
    const canvas = canvasRef.current
    const canvasContext = canvas && canvas.getContext('2d')
    const rect = canvas.getBoundingClientRect()
    const { strokeStyle, } = regionColors[region]

    const { x, relativeX, y, relativeY, } = getRelativeVals(canvas, e)
    if (draw === 'move') {
      const regionInPath = isRegionInPath(relativeX, relativeY)
      const pointInPath = pointCheck({ x: relativeX, y: relativeY, }) || {
        x: null,
        y: null,
        region: null,
      }
      if (pointInPath && pointInPath.x) {
        setUndoState(true)
        updateWedgeState(true)
        updateStart({ x, y, region: pointInPath.region || region, })
        const newLocalPts = allPoints.map(pt => {
          if (pointInPath && pt.x === pointInPath.x && pt.y === pointInPath.y) {
            pt.wedge = true
            return pt
          }
          pt.wedge = false
          return pt
        })
        setLocalPts(newLocalPts)
        return
      }

      if (regionInPath) {
        setRegionStart(allPoints)
        setUndoState(true)
        updateRegion(regionInPath)
        updateDragState(true)
        updateStart({ x, y, })
      }
      return
    }
    // draw the circle
    canvasContext.beginPath()
    canvasContext.arc(x, y, pointRadius, 0, 2 * Math.PI)
    canvasContext.strokeStyle = strokeStyle
    canvasContext.stroke()

    props.addPoint(
      {
        x: (e.clientX - rect.left) / props.width,
        y: (e.clientY - rect.top) / props.height,
        last: true,
      },
      region
    )
  }

  const findAdjustment = (canvas, e) => {
    if (!start) return {}
    const rect = canvas.getBoundingClientRect()
    const { width, height, } = props
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    updateEnd({ x: x / width, y: y / height, })
    setRedoState(false)
    const adjX = start.x - x
    const adjY = start.y - y

    return { adjX, adjY, }
  }

  const convertToAbs = pt => {
    if (!pt.x) return pt
    if (pt.x > 1) return pt
    const { width, height, } = props
    return {
      x: pt.x * width,
      y: pt.y * height,
      region: pt.region,
    }
  }

  const convertToRel = pt => {
    if (!pt.x || !pt.y) return pt
    if (pt.x < 1 && pt.y < 1) return pt
    const { width, height, } = props
    return {
      x: pt.x / width,
      y: pt.y / height,
      region: pt.region,
    }
  }

  const getRelevantPts = (region = null) => {
    const { allPoints, } = props
    if (!region) region = latestRegion
    const relPts = allPoints.filter(pt => pt.region === region)
    const absRelPts = relPts.map(convertToAbs)

    const bedPts = allPoints
      .filter(pt => pt.region === 'bed')
      .map(convertToAbs)
    const bedEdgePts = allPoints
      .filter(pt => pt.region === 'bedEdge')
      .map(convertToAbs)
    const chairPts = allPoints
      .filter(pt => pt.region === 'chair')
      .map(convertToAbs)

    return { relPts, absRelPts, bedPts, bedEdgePts, chairPts, }
  }

  const strokePolygon = (ctx, pts, region = '') => {
    if (!region) region = lastRegion
    const { strokeStyle, fillStyle, } = regionColors[region]

    ctx.beginPath()
    pts.forEach(pt => {
      ctx.strokeStyle = strokeStyle
      ctx.lineTo(pt.x, pt.y)
    })
    ctx.globalAlpha = 0.3
    ctx.closePath()
    ctx.stroke()
    ctx.fillStyle = fillStyle
    ctx.globalAlpha = 0.25
    ctx.fill()
    ctx.globalAlpha = 1
    pts.forEach(pt => {
      ctx.beginPath()
      ctx.moveTo(pt.x + pointRadius, pt.y)
      ctx.fillStyle = 'None'
      ctx.arc(pt.x, pt.y, pointRadius, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.fillStyle = fillStyle
      ctx.fill()
      ctx.closePath()
    })
  }

  const calcAdjPts = (pts, adjX, adjY, relative = false) => {
    if (!relative) {
      return pts.map(pt => {
        return {
          x: pt.x - adjX,
          y: pt.y - adjY,
          region: pt.region,
        }
      })
    } else {
      const { width, height, } = props
      return pts.map(pt => {
        return {
          x: (pt.x - adjX) / width,
          y: (pt.y - adjY) / height,
          region: pt.region,
        }
      })
    }
  }

  const pointCheck = ptToCheck => {
    let { allPoints, } = props
    allPoints = allPoints.filter(
      pt =>
        pt.region === 'chair' || pt.region === 'bed' || pt.region === 'bedEdge'
    )
    ptToCheck = convertToAbs(ptToCheck)
    let found = null
    allPoints.forEach(pt => {
      const absPt = convertToAbs(pt)

      const xDiff = (absPt.x - ptToCheck.x) ** 2
      const yDiff = (absPt.y - ptToCheck.y) ** 2

      const totalDistance = Math.sqrt(xDiff + yDiff)
      if (totalDistance < 15) {
        found = pt
      }
    })

    return found
  }

  const isRegionInPath = (relativeX, relativeY) => {
    const { allPoints, } = props
    const bedPts = allPoints
      .filter(pt => pt.region === 'bed')
      .map(pt => [pt.x, pt.y,])
    const chairPts = allPoints
      .filter(pt => pt.region === 'chair')
      .map(pt => [pt.x, pt.y,])
    const bedEdgePts = allPoints
      .filter(pt => pt.region === 'bedEdge')
      .map(pt => [pt.x, pt.y,])

    const inBedPath = classifyPt(bedPts, [relativeX, relativeY,])
    const inChairPath = classifyPt(chairPts, [relativeX, relativeY,])
    const inBedEdgePath = classifyPt(bedEdgePts, [relativeX, relativeY,])

    if (inBedPath === -1) return 'bed'
    if (inChairPath === -1) return 'chair'
    if (inBedEdgePath === -1) return 'bedEdge'

    return null
  }

  const handleDrag = e => {
    const { draw, width, height, } = props
    const canvas = canvasRef.current
    const canvasContext = canvas.getContext('2d')

    if (draw === 'move' && !isDragging) {
      const { relativeX, relativeY, } = getRelativeVals(canvas, e)
      const regionInPath = isRegionInPath(relativeX, relativeY)
      const pointInPath = pointCheck({ x: relativeX, y: relativeY, })
      if ((regionInPath || pointInPath) && !pointer) {
        setPointer(true)
      } else if (!regionInPath && pointer) {
        setPointer(false)
      }
    }

    if (isWedge && !isDragging) {
      let { adjX, adjY, } = findAdjustment(canvas, e) || { adjX: 0, adjY: 0, }

      const newPts = calcAdjPts([start,], adjX, adjY, true)
      adjX = adjX && adjX / width
      adjY = adjY && adjY / height
      const newLocalPts = localAllPts.map(pt => {
        if (pt.wedge) {
          pt.x = newPts[0].x
          pt.y = newPts[0].y
          return pt
        }
        return pt
      })

      drawRegions(newLocalPts)

      return
    }

    if (!isDragging || !start) return

    const { adjX, adjY, } = findAdjustment(canvas, e)
    const { absRelPts, chairPts, bedPts, bedEdgePts, } = getRelevantPts()
    const newPts = calcAdjPts(absRelPts, adjX, adjY)

    canvasContext.clearRect(0, 0, width, height)

    strokePolygon(canvasContext, newPts, latestRegion)

    if (latestRegion !== 'chair') {
      strokePolygon(canvasContext, chairPts, 'chair')
    }
    if (latestRegion !== 'bed') {
      strokePolygon(canvasContext, bedPts, 'bed')
    }
    if (latestRegion !== 'bedEdge') {
      strokePolygon(canvasContext, bedEdgePts, 'bedEdge')
    }
  }

  const handleMouseUp = e => {
    if (!start) return
    const { moveRegion, } = props
    if (isDragging) {
      const region = latestRegion
      const canvas = canvasRef.current
      const canvasContext = canvas.getContext('2d')
      const { adjX, adjY, } = findAdjustment(canvas, e)
      const { absRelPts, } = getRelevantPts()
      const newPts = calcAdjPts(absRelPts, adjX, adjY, true).map(convertToRel)
      moveRegion(newPts, region)
      updateDragState(false)
      updateWedgeState(false)

      moved
        .filter(m => m !== region)
        .forEach(r => {
          const { absRelPts, } = getRelevantPts(r)
          const newPts = calcAdjPts(absRelPts, 0, 0)
          strokePolygon(canvasContext, newPts, r)
        })

      setPointer(false)
      updateStart(null)
      updateWedgeState(false)
    }

    if (isWedge) {
      const relLocalPts = localAllPts
        .filter(pt => pt.region === start.region)
        .map(convertToRel)
      moveRegion(relLocalPts, start.region)
      updateDragState(false)
      updateWedgeState(false)
    }
  }

  const drawRegions = points => {
    const adjPoints = points.map(convertToAbs)
    const canvas = canvasRef.current
    const canvasContext = canvas && canvas.getContext('2d')
    // this just clears the regio to draw
    canvasContext.clearRect(0, 0, props.width, props.height)

    const bedPoints = adjPoints.filter(p => p.region === 'bed')
    const bedEdgePoints = adjPoints.filter(p => p.region === 'bedEdge')
    const chairPoints = adjPoints.filter(p => p.region === 'chair')

    if (bedPoints) {
      strokePolygon(canvasContext, bedPoints, 'bed')
    }

    if (bedEdgePoints) {
      strokePolygon(canvasContext, bedEdgePoints, 'bedEdge')
    }

    if (chairPoints) {
      strokePolygon(canvasContext, chairPoints, 'chair')
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current
    const canvasContext = canvas && canvas.getContext('2d')
    const { region, } = props || {}

    if (!isDragging && region !== latestRegion) {
      updateRegion(region)
    }

    if (canvas) {
      if (lastRegion && lastRegion !== region) {
        canvasContext.beginPath()
      }
      if (!moved.includes(lastRegion)) {
        const newMoved = moved
        newMoved.push(lastRegion)
        updateMoved(newMoved)
      }
      updateLastRegion(latestRegion)
    }
  }, [props.region,])

  useEffect(() => {
    const { region, allPoints = [], } = props || {}
    // on component load read region and pooints passed in
    // if the region exists draw the points
    if (region) {
      drawRegions(allPoints)
    }
    if (!localAllPts) {
      setLocalPts(allPoints)
    }
  }, [props.region, props.allPoints.length,])

  useEffect(() => {
    const { allPoints, } = props
    const canvas = canvasRef.current
    const background = backgroundRef.current
    if (canvas) {
      const backgroundContext = background && background.getContext('2d')
      const adjPoints = allPoints.filter(pt => pt.region !== lastRegion)
      drawRegions(adjPoints)
      updateMoved([])
      if (props) {
        const img = new Image()
        img.src = props.imageSrc
        backgroundContext.drawImage(img, 0, 0)
      }
    }
  }, [props.clear,])

  useEffect(() => {
    const canvas = canvasRef.current
    const background = backgroundRef.current
    if (canvas) {
      const backgroundContext = background && background.getContext('2d')
      updateMoved([])
      if (props) {
        const img = new Image()
        img.src = props.imageSrc
        backgroundContext.drawImage(img, 0, 0)
      }
    }
  }, [props.reset, props.room,])

  return (
    <div
      style={{ position: 'relative', width: props.width, height: props.height, }}
    >
      <Tooltip title="control+U">
        <Button
          type="primary"
          className="undo-button"
          onClick={handleUndo}
          icon={<UndoOutlined />}
          disabled={!undoState}
        >
          Undo
        </Button>
      </Tooltip>
      <Tooltip title="control+R">
        <Button
          type="primary"
          className="redo-button"
          onClick={handleRedo}
          icon={<RedoOutlined />}
          disabled={!redoState}
        >
          Redo
        </Button>
      </Tooltip>
      <canvas
        ref={backgroundRef}
        {...props}
        style={{ position: 'absolute', top: 0, left: 0, }}
      />
      <canvas
        ref={canvasRef}
        {...props}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          cursor: pointer ? 'pointer' : null,
        }}
        onMouseDown={handleClick}
        onMouseMove={handleDrag}
        onMouseUp={handleMouseUp}
      />
    </div>
  )
})

export default Canvas
