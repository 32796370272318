import axios from 'axios'
const { REACT_APP_STAGE_URL, REACT_APP_PROD_URL, REACT_APP_NEXT_URL, REACT_APP_THIS_URL, REACT_APP_API, } = process.env

let baseURL = REACT_APP_PROD_URL

switch (REACT_APP_API) {
  case 'next':
    baseURL = REACT_APP_NEXT_URL
    break
  case 'this':
    baseURL = REACT_APP_THIS_URL
    break
  case 'stage':
    baseURL = REACT_APP_STAGE_URL
    break
  default:
    baseURL = REACT_APP_PROD_URL
}
// const baseURL = 'http://localhost:3000'
const Api = axios.create({
  baseURL,
  timeout: 30000,
})

export { Api }
