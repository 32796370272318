import React from 'react'
import { CSVReader } from 'react-papaparse'

function FileReader ({ handleDrop, style, staged, title, }: any) {
  return (
        <div>
        <div style={{ minWidth: 200, ...style, }}>
            <div className="page-subtitle">{title}</div>
            <CSVReader
                onDrop={handleDrop}
                addRemoveButton
                removeButtonColor='#659cef'
            >
            </CSVReader>
        </div>
        </div>
  )
}

export default FileReader
