import React from 'react'
import { Button, DatePicker, Space } from 'antd'
import OrgDropdown from './OrgDropdown'

const { RangePicker, } = DatePicker

function OrgSelector ({ selectedOrg, selectDate, dates = null, selectOrg, orgs, submit, }: any) {
  return (
    <Space direction="vertical" size={16}>
        <OrgDropdown
          selectOrg={selectOrg}
          selectedOrg={selectedOrg}
          orgs={orgs}
        />
        { dates && <RangePicker onChange={selectDate} value={dates} showTime={true}/> }
        <Button onClick={submit} type="primary" htmlType="submit" disabled={!selectedOrg}>
        Submit
        </Button>
    </Space>
  )
}

export default OrgSelector
