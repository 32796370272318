const {
  REACT_APP_API,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_CLIENT_ID_STAGE,
  REACT_APP_AUTH0_CLIENT_ID_DEV,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_DOMAIN_STAGE,
  REACT_APP_AUTH0_DOMAIN_DEV,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env

export const getAuth0Domain = () => {
  switch (REACT_APP_API) {
    case 'prod':
      return REACT_APP_AUTH0_DOMAIN
    case 'stage':
      return REACT_APP_AUTH0_DOMAIN_STAGE
    default:
      return REACT_APP_AUTH0_DOMAIN_DEV
  }
}
export const getAuth0ClientId = () => {
  switch (REACT_APP_API) {
    case 'prod':
      return REACT_APP_AUTH0_CLIENT_ID
    case 'stage':
      return REACT_APP_AUTH0_CLIENT_ID_STAGE
    default:
      return REACT_APP_AUTH0_CLIENT_ID_DEV
  }
}

export const auth0Audience =
  REACT_APP_API === 'prod' ? REACT_APP_AUTH0_AUDIENCE : 'https://inspiren-api/'
