
export const mapStateToProps = (state: any) => ({
  orgs: state.mainReducer.orgs || [],
  selectedRoom: state.mainReducer.selectedRoom || [],
  dateRange: state.mainReducer.analyticsDates,
  notifications: state.mainReducer.notifications,
  notification: state.mainReducer.selectedNotification,
  timeline: state.mainReducer.timeline,
  tloading: state.mainReducer.timelineLoading,
  pics: state.mainReducer.timelinePics,
  homePagination: state.mainReducer.homePagination,
  homeFiltering: state.mainReducer.homeFiltering,
  homeSorting: state.mainReducer.homeSorting,
  homeLoading: state.mainReducer.homeLoading,
  homeUnit: state.mainReducer.homeUnit || {},
  homeQueryError: state.mainReducer.homeQueryError,
})
