
export default function errorReducer (state = {}, action) {
  switch (action.type) {
    case 'ANALYTICS_QUERY_FAILED':
      return {
        ...state,
        analyticsErr: true,
        analyticsLoading: false,
      }
    case 'TRAINING_QUERY_FAILED':
      return {
        ...state,
        trainingErr: true,
        trainingLoading: false,
      }
    case 'REPORT_QUERY_FAILED':
      return {
        ...state,
        reportingLoading: false,
      }
    default:
      return state
  }
}
