import React from 'react'
import ConfirmButton from './ConfirmButton'
import { Room } from '../state/types'

interface OptionsParams {
  level: number
  promote: (mainId: string, adminNotificationId: number) => void
  resolve: (mainId: string, adminNotificationId: number) => void
  reject: (mainId: string, adminNotificationId: number) => void
  room: Room
  toggleHide: (isHidden: boolean) => void
  togglePlaybackPic: (isPlaybackPic: boolean) => void
}

function Options ({ level = 0, promote, resolve, reject, room, toggleHide, togglePlaybackPic, }: OptionsParams) {
  if (level === 0) {
    return (
      <>
        <ConfirmButton
        prompt={'Are you sure you want to Promote this notification?'}
        title={'Promote'}
        onPress={() => promote(room.mainId, room.adminNotificationId)}
        textColor="white"
        style={{ marginRight: 8, marginTop: 8, width: '100px', }}
        />
        <ConfirmButton
        prompt={'Are you sure you want to Reject this notification?'}
        title={'Reject'}
        style={{ marginRight: 8, marginTop: 8, width: '100px', }}
        onPress={() => {
          reject(room.mainId, room.adminNotificationId)
          toggleHide(true)
          togglePlaybackPic(false)
        }}
        textColor="white"
        danger={true}
        confirm={false}
      />
    </>)
  }

  if (level === 1) {
    return (
      <ConfirmButton
        prompt={'Are you sure you want to Resolve this notification?'}
        title={'Resolve'}
        style={{ marginRight: 8, marginTop: 8, width: '100px', }}
        onPress={() => {
          resolve(room.mainId, room.notificationId)
          toggleHide(true)
          togglePlaybackPic(false)
        }}
        textColor="white"
      />
    )
  }

  return null
}

export default Options
