import React, { useEffect, useState } from 'react'
import { differenceInSeconds } from 'date-fns'

interface Props {
  endsAt: Date
}

function secondsToTime (seconds: number) {
  const h = Math.floor(seconds / 3600).toString().padStart(2,'0')
  const m = Math.floor(seconds % 3600 / 60).toString().padStart(2,'0')
  const s = Math.floor(seconds % 60).toString().padStart(2,'0')

  return `${h}:${m}:${s}`
}

export const TestingSessionTimer = ({ endsAt, }: Props) => {
  const [timeLeft, setTimeLeft,] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      if (endsAt) {
        const seconds = differenceInSeconds(endsAt, new Date())
        if (seconds < 0) {
          setTimeLeft('')
        } else {
          setTimeLeft(secondsToTime(seconds))
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [endsAt?.toISOString(),])

  return <span>{timeLeft}</span>
}
