import { getJwtToken } from '../../../utility'
import { Api } from '../../../utility/client'

export const stopRoutine = async (getToken, unitId: string) => {
  const jwt = await getJwtToken(getToken)

  await Api.post(
    '/v1/vbase/reset',
    {
      unitId,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )
}
