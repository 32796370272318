import { Cascader, Col, Row, Space } from 'antd'
import SimpleDropdown from '../SimpleDropdown'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { fetchUnit } from '../../state/actions'
import { useAuth0 } from '@auth0/auth0-react'
import { CardSizes, RoomSortOptions } from '../../state/types'
import { some } from 'lodash'
import { VBaseTestingSessionButton } from './Feed/VBaseTestingSessionButton'
import { isAfter } from 'date-fns'
import { TestingSessionTimer } from './Feed/TestingSessionTimer'
import { VBaseBumpUpTestingButton } from './Feed/VBaseBumpUpTestingButton'
import styles from './MonitorTopBar.module.scss'
import { VBaseRunRoutineButton } from './Feed/VBaseRunRoutineButton'

interface Props {
  cardSize: CardSizes
  sort: RoomSortOptions
  onCardSizeChange: (size: CardSizes) => void
  onSortChange: (sort: RoomSortOptions) => void
}

export const MonitorTopBar = ({ cardSize, sort, onSortChange, onCardSizeChange, }: Props) => {
  const dispatch = useAppDispatch()
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const units = useAppSelector(state => state.mainReducer.units || [])
  const selectedUnit = useAppSelector(state => state.mainReducer.unit || null)
  const user = useAppSelector(state => state.mainReducer.user || {})
  const rooms = useAppSelector(state => state.mainReducer.rooms || [])
  const [isTestingSessionActive, setIsTestingSessionActive,] = useState(false)

  const handleUnitChange = useCallback((unit) => {
    if (!unit || (!unit[1] && unit[0] !== 'custom')) return
    dispatch(fetchUnit({ getToken, unit, user, }))
  }, [getToken, user,])

  const isVirtualTestingPossible = useMemo(() => {
    return selectedUnit?.[1] && some(rooms, room => room.virtual)
  }, [selectedUnit, rooms,])

  const virtualTestingSessionEndsAt = useMemo(() => {
    return selectedUnit?.[1] && rooms?.find(room => room.virtual && room.testingSessionEndsAt)?.testingSessionEndsAt
  }, [selectedUnit, rooms,])

  const activeRoutine = useMemo(() => {
    return selectedUnit?.[1] && rooms?.find(room => room.virtual && room.testingRoutine)?.testingRoutine
  }, [selectedUnit, rooms,])

  const runInLoop = useMemo(() => {
    return selectedUnit?.[1] && rooms?.find(room => room.virtual && room.testingInLoop)?.testingInLoop
  }, [selectedUnit, rooms,])

  useEffect(() => {
    if (virtualTestingSessionEndsAt && isAfter(virtualTestingSessionEndsAt, new Date())) {
      setIsTestingSessionActive(true)
    } else {
      setIsTestingSessionActive(false)
    }
  }, [virtualTestingSessionEndsAt,])

  useEffect(() => {
    if (user?.role === 'monitor' && !selectedUnit) {
      handleUnitChange(['custom', null,])
    } else if (selectedUnit) {
      handleUnitChange(selectedUnit)
    }
  }, [selectedUnit, user,])
  return <div className={styles.monitorDropdownContainer}>
    <Row>
      <Col span={8} className={styles.vbaseCol}>
        { isVirtualTestingPossible && <>
          <VBaseTestingSessionButton
            unit={selectedUnit[1]}
            isActive={isTestingSessionActive}
          />
          { isTestingSessionActive && <TestingSessionTimer endsAt={virtualTestingSessionEndsAt} /> }
        </> }
        { isTestingSessionActive && <>
          <VBaseBumpUpTestingButton unit={selectedUnit[1]} />
          <VBaseRunRoutineButton unit={selectedUnit[1]} activeRoutine={activeRoutine} runInLoop={runInLoop} />
        </> }
      </Col>
      {
        user?.role === 'monitor'
          ? null
          : <Col span={8}>
        <Cascader
          options={[...units, { label: 'Custom', value: 'custom', },]}
          value={selectedUnit}
          placeholder={'Select Unit'}
          onChange={handleUnitChange}
        />
      </Col>}
      <Col span={user?.role === 'monitor' ? 16 : 8} className={styles.sortsCol}>
        { selectedUnit && <Space size="large" align="center">
          <SimpleDropdown
            title={cardSize}
            options={['extra-small', 'small', 'medium', 'large',]}
            onSelect={onCardSizeChange}
          />
          <SimpleDropdown
            title={sort}
            options={['smart', 'alphabetical', 'fall risk',]}
            onSelect={onSortChange}
          />
        </Space> }
      </Col>
    </Row>
  </div>
}
