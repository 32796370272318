import React from 'react'
import { Button, Popconfirm } from 'antd'

function ConfirmButton ({ color = '', textColor = 'black', prompt, onPress, style = {}, danger = false, title, loading, confirm = true, size = 'default', trunc = false, okText = 'OK', }: any) {
  const adjTitle = size.includes('small') && trunc ? title.slice(0,1) : title
  size = size === 'large' ? 'default' : size
  const antdSize = size.includes('small') ? 'small' : size
  if (!confirm) {
    return (
        <Button
          type="primary"
          danger={danger}
          loading={!!loading}
          onClick={onPress}
          size={antdSize}
          style={{ ...style, backgroundColor: color, borderColor: color, color: textColor, textAlign: 'center', }}
        >{adjTitle}
        </Button>
    )
  }

  return (
        <Popconfirm
          placement="right"
          title={prompt}
          okText={okText}
          cancelText="No"
          onConfirm={onPress && onPress}
        >
            <Button
              type="primary"
              danger={danger}
              loading={!!loading}
              size={antdSize}
              style={{ ...style, backgroundColor: color, borderColor: color, color: textColor, }}>{title}</Button>
        </Popconfirm>
  )
}

export default ConfirmButton
