/* istanbul ignore file */

/** translation for raw pose and location
 * Clarify if these are the correct translations including:
 * Lying in chair
 * Lying in exit
 **/

export const filteredPhrases = ['3480mcmullen', '274mad', 'spare', 'Spare',]

export const secondsUntilOffline = 120
export const userLastActiveMinutes = 15
export const internalUsers = [
  'jeffrey@inspiren.com',
  'jon@inspiren.com',
  'jon+palm-admin@inspiren.com',
  'kyle@inspiren.com',
  'kyle+palm-admin@inspiren.com',
]
export const poseAndLocation = {
  room: {
    lying: {
      text: 'Lying on floor',
    },
    standing: {
      text: 'Standing in room',
    },
    sitting: {
      text: 'Sitting in room',
    },
    hidden: {
      text: 'Hidden',
    },
    movement: {
      text: 'Movement in room',
    },
    unknown: {
      text: 'In room',
    },
  },
  none: {
    none: {
      text: 'Out of View',
    },
  },
  bed: {
    lying: {
      text: 'In bed',
    },
    standing: {
      text: 'In bed',
    },
    hidden: {
      text: 'Hidden',
    },
    sitting: {
      text: 'In bed',
    },
    movement: {
      text: 'In bed',
    },
    unknown: {
      text: 'In bed',
    },
  },
  bed_edge: {
    lying: {
      text: 'In bed edge',
    },
    standing: {
      text: 'In bed edge',
    },
    hidden: {
      text: 'Hidden',
    },
    sitting: {
      text: 'In bed edge',
    },
    movement: {
      text: 'In bed edge',
    },
    unknown: {
      text: 'In bed edge',
    },
  },
  chair: {
    sitting: {
      text: 'In chair',
    },
    lying: {
      text: 'In chair',
    },
    standing: {
      text: 'In chair',
    },
    hidden: {
      text: 'Hidden',
    },
    unknown: {
      text: 'In chair',
    },
  },
  sofa: {
    sitting: {
      text: 'In sofa',
    },
    lying: {
      text: 'In sofa',
    },
    standing: {
      text: 'In sofa',
    },
    hidden: {
      text: 'Hidden',
    },
    unknown: {
      text: 'In sofa',
    },
  },
  exit: {
    standing: {
      text: 'Standing',
    },
    lying: {
      text: 'Fallen!',
    },
    unknown: {
      text: '',
    },
  },
  unknown: {
    lying: {},
    standing: {},
    sitting: {},
    hidden: {
      text: 'Hidden',
    },
    unknown: {
      text: '',
    },
  },
  outside: {
    lying: {
      text: 'Out of View',
    },
    standing: {
      text: 'Out of View',
    },
    sitting: {},
    hidden: {
      text: 'Hidden',
    },
    unknown: {
      text: '',
    },
  },
  staff: {
    staff: {
      text: 'Staff Enter',
    },
  },
}

export const alertBody = (type, room) => {
  switch (type) {
    case 'fall':
      return `Resident in ${room} has fallen!`
    case 'out_of_bed':
      return `Resident in ${room} has gotten out of bed`
    case 'hidden':
      return `Resident in ${room} is hidden from view`
    case 'out_of_chair':
      return `Resident in ${room} has gotten out of chair`
    default:
      return `Issue in ${room}`
  }
}

export const mapNotifType = {
  fall: 'Fall',
  out_of_bed: 'Leaving Bed',
  out_of_chair: 'Out of Chair',
  moving_in_bed: 'Moving in Bed',
  hidden: 'Hidden',
  staff_entry: 'Staff Entry',
  staff_exit: 'Staff Exit',
  early_warning: 'Staff Generated',
  urgent: 'Staff Generated',
}

export const mapNotifToText = (type) => {
  if (!type) return ''
  return mapNotifType[type]
}

export const mapLabelToText = (label) => {
  if (!label) return ''
  switch (label) {
    case 'falsePositive':
      return 'False Positive'
    case 'falseNegative':
      return 'False Negative'
    case 'truePositive':
      return 'True Positive'
    case 'trueNegative':
      return 'True Negative'
    default:
      return ''
  }
}

export const staffEventBody = (event) => {
  if (event.type === 'staff_entry') {
    return {
      text: 'Staff Entry',
    }
  } else if (event.type === 'staff_exit') {
    return {
      text: 'Staff Exit',
    }
  } else {
    return {
      text: '',
    }
  }
}

export const mapEventToText = (event) => {
  if (!event.type) return ''
  if (event.type.includes('staff_')) {
    const { text, } = staffEventBody(event)
    return text
  }

  if (!event.newLoc || !event.newPose) return ''

  else {
    const { text, } = poseAndLocation[event.newLoc][event.newPose]
    return text
  }
}

export const getTimelineBounds = (type) => {
  if (!type) return 60
  let bound = 60

  switch (type) {
    case 'fall':
      bound = 120
      break
    case 'out_of_bed':
      bound = 120
      break
    case 'out_of_chair':
      bound = 120
      break
    case 'hidden':
      bound = 120
      break
    case 'moving_in_bed':
      bound = 120
      break
    case 'staff_entry':
      bound = 120
      break
    case 'staff_exit':
      bound = 120
      break
    default:
      bound = 120
      break
  }

  return bound
}

export const mapStatus = (loc, pose, mode, masked = false) => {
  const invalidCombo = { text: '', icon: null, }
  if (mode && mode === 'night' && masked) return invalidCombo

  const hash = poseAndLocation

  if (!loc || !pose) return invalidCombo

  const location = hash[loc]
  if (!location) return invalidCombo

  const status = location[pose]
  if (!status) return invalidCombo

  return status
}

const fallRiskText = {
  'extra-small': {
    low: 'LFR',
    medium: 'HFR',
    high: 'HFR',
  },
  small: {
    low: 'LFR',
    medium: 'HFR',
    high: 'HFR',
  },
  medium: {
    low: 'LFR',
    medium: 'HFR',
    high: 'HFR',
  },
  large: {
    low: 'LFR',
    medium: 'HFR',
    high: 'HFR',
  },
}

export const mapFallRiskText = (fallRiskLevel, cardSize) => {
  if (!fallRiskLevel || fallRiskLevel === 'off') return null

  if (fallRiskText[cardSize] && fallRiskText[cardSize][fallRiskLevel]) {
    return fallRiskText[cardSize][fallRiskLevel]
  } else return 'MFR'
}
