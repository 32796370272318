export default {
  env: 'prod',
  orgs: [],
  currentAssignments: [],
  team: {
    name: 'team-inspiren',
    teamBedAssignments: [],
    teamUserAssignments: [],
    beds: [{
      secondaryId: 'Room-VALLEY-223vandien-3-Bergen3-204',
      orgs: [],
    },],
  },
  beds: [],
  users: [],
  bases: [],
  homeQueryError: null,
  reportingQueryError: false,
  monitorUsers: [],
  teams: [{
    mainId: 'team-javi',
    secondaryId: 'team-javi',
    numUsers: 1,
    numBeds: 2,
  },],
  reportFiltering: {
    name: null,
    type: null,
    resolvedByName: null,
    fr: null,
    resolvedBy: null,
    label: null,
    level: null,
  },
  reportPagination: { current: 1, pageSize: 20, },
  reportSorting: {
    columnKey: 'createdAt',
    field: 'createdAt',
  },
  homeFiltering: {
    name: null,
    type: null,
    resolvedByName: null,
    labeledByName: null,
    fr: null,
    resolvedBy: null,
    label: null,
  },
  homeSorting: {
    columnKey: 'createdAt',
    field: 'createdAt',
  },
  homePagination: {
    current: 1,
    pageSize: 20,
  },
  orgUsersLoading: false,
  assignmentsLoading: false,
  slackUpdateLoading: false,
  timelineLoading: false,
  puLoading: false,
}
