import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import Timeline from '../../components/Timeline'
import { updateSeries, labelNotification, prefetchTimeline, swapTimeline } from '../../state/actions'
import { mapStateToProps } from './mapStateToProps'

function Series ({ store, match, series, tloading, tlhash, pfloading, timeline, notification, type, org, dates, dispatch, history, selectedOrg, pics, }: any) {
  const { getAccessTokenSilently, user, } = useAuth0()
  useEffect(() => {
    async function initialize () {
      const start = dates[0].unix()
      const end = dates[1].unix()
      dispatch(updateSeries({ getToken: getAccessTokenSilently, org, start, end, type, }))
    }
    if (org && type) initialize()
  }, [type,])

  const label = async (room: string, id: number, label: string) => {
    const third = series && series.length > 3 && series[3]
    const prefetched = third ? tlhash[`${third.room}-${third.id}`] : true
    dispatch(labelNotification({ getToken: getAccessTokenSilently, room, id, label, email: user?.email, series, prefetch: !prefetched, }))
  }

  const skip = () => {
    dispatch(swapTimeline())
    const third = series && series.length > 3 && series[3]
    const prefetched = third ? tlhash[`${third.room}-${third.id}`] : true
    if (!prefetched) {
      dispatch(prefetchTimeline({ getToken: getAccessTokenSilently, nextEvent: third, }))
    }
  }

  return (
    <>
    {series && series.length ? <div style={{ fontSize: 16, }}>{series.length} unlabeled remaining</div> : null}
    {
    (tloading)
      ? <Loading type='bars'/>
      : <div className="Home-container">
        <Timeline
          timeline={timeline}
          allPics={pics}
          notification={notification}
          label={label}
          loading={tloading}
          skip={skip}
        />
      </div>
    }
    </>
  )
}

export default connect(mapStateToProps)(Series)
