
export const mapStateToProps = (state: any) => ({
  orgs: state.mainReducer.orgs || [],
  dates: state.mainReducer.analyticsDates || [],
  analytics: state.mainReducer.analytics,
  loading: state.mainReducer.analyticsLoading,
  selectedOrg: state.mainReducer.selectedOrg,
  staff: state.mainReducer.staffAnalytics,
  bases: state.mainReducer.bases || [],
  beacons: state.mainReducer.beacons || [],
  orgUsersLoading: state.mainReducer.orgUsersLoading || false,
  orgUsers: state.mainReducer.orgUsers || [],
})
