import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
function Login () {
  const { isAuthenticated, loginWithRedirect, } = useAuth0()
  useEffect(() => {
    if (!isAuthenticated) loginWithRedirect()
    else <Redirect to="/main/table" />
  }, [])
  return (
    <div className="Center-container">
      {!isAuthenticated
        ? (
        <button onClick={loginWithRedirect}>Log in</button>
          )
        : (
          <Redirect to="/main/table" />
          )}
    </div>
  )
}

export default Login
