import React from 'react'
import { Result, Button } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'

const Unauthorized = () => {
  const { logout, } = useAuth0()

  return (
    <Result
      status="warning"
      title="Unauthorized"
      extra={
        <Button
          type="primary"
          onClick={() =>
            logout({ returnTo: `${window.location.origin}/login`, })
          }
        >
          Logout
        </Button>
      }
    />
  )
}

export default Unauthorized
