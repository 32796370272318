/* eslint eqeqeq: "off" */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Table, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import OrgSelector from '../../../components/OrgSelector'
import FileReader from '../../../components/FileReader'
import Loading from '../../../components/Loading'
import { updateOrg, loadOrgUsers, batchUpdateUsers, batchUpdateBeacons } from '../../../state/actions'
import { generatePassword } from '../../../utility/helpers'
import { CSVDownloader } from 'react-papaparse'
import { mapStateToProps } from './mapStateToProps'

function Users ({
  dispatch,
  orgs,
  selectedOrg,
  orgUsers,
  orgUsersLoading,
  renderCheck,
}: any) {
  if (!renderCheck) return null
  const { getAccessTokenSilently: getToken, } = useAuth0()
  const [sorters, updateSorters,] = useState<any>(null)
  const [stagedUsers, updatedStage,] = useState<any>([])
  const [stagedBeacons, updatedBeacons,] = useState<any>([])
  const [issueCount, updateIssues,] = useState<any>(0)
  const [modal, toggle,] = useState<any>(false)
  const [beaconModal, toggleBeacons,] = useState<any>(false)
  const [submitted, updateSubmitted,] = useState<any>(false)

  const columns: ColumnsType<any> = [
    {
      title: 'First',
      width: 100,
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: 'left',
      sorter: (a, b) => a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0,
      sortOrder: sorters && sorters.columnKey === 'firstName' && sorters.order || null,
    },
    {
      title: 'Last',
      width: 200,
      dataIndex: 'lastName',
      key: 'lastName',
      fixed: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
      sorter: (a, b) => a.email > b.email ? 1 : b.email > a.email ? -1 : 0,
      sortOrder: sorters && sorters.columnKey === 'email' && sorters.order || null,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 150,
      filters: [
        { text: 'staff', value: 'staff', },
        { text: 'admin', value: 'admin', },
        { text: 'manager', value: 'manager', },
        { text: 'CNA', value: 'CNA', },
        { text: 'nurse', value: 'nurse', },
      ],
      onFilter: (value, record) => record.role.includes(value),
    },
    {
      title: 'Org',
      dataIndex: 'mainId',
      key: 'mainId',
      width: 150,
      render: (value, record) => <div>{record.mainId && record.mainId.split('-')[1]}</div>,
    },
  ]

  const selectOrg = (key: string) => {
    dispatch(updateOrg(key))
  }

  const submit = async () => {
    dispatch(loadOrgUsers({ getToken, org: selectedOrg, }))
  }

  const updateTable = (pagination: any, filters: any, sorters: any) => {
    // updateFilters(filters)
    updateSorters(sorters)
  }

  const ingestUsers = (raw) => {
    const rawData = raw.map(r => r.data)
    let issueTotal = 0
    const checked = rawData.filter((row, i) => {
      if (i < 2) return false
      if (!row || !row[2] || !row[1] || row[2] == 'Email') {
        issueTotal++
        return false
      } else return true
    })

    const org = orgs.filter(o => o.id === selectedOrg)[0]
    const allUnits = org.children.map(o => o.unit).filter(u => u)
    const unitSet = new Set(allUnits)
    const numOfUnits = unitSet.size
    const withDefaults = checked.map(u => {
      if (u && !u[1]) u[1] = selectedOrg
      if (u && !u[4]) u[4] = generatePassword()
      if (u && !u[5] && numOfUnits > 0) {
        u[5] = unitSet.values().next().value
      }
      return u
    })
    updatedStage(withDefaults)
    updateIssues(issueTotal)
    toggle(true)
  }

  const ingestBeacons = (raw) => {
    const rawData = raw.map(r => r.data).filter(r => r[0])
    const data = rawData.map(r => {
      r[0] = r[0].toLowerCase()
      return r
    })
    const issueTotal = 0

    updatedBeacons(data)
    updateIssues(issueTotal)
    toggleBeacons(true)
  }

  const parseUser = (row) => ({
    firstName: row[0],
    lastName: row[1],
    email: row[2],
    role: row[3],
    password: row[4],
    unit: row[5],
    org: selectedOrg,
  })

  const parseBeacon = (row) => ({
    beacon: `a0224eb0${row[0]}`,
  })

  const uploadUsers = () => {
    if (!stagedUsers || !stagedUsers.length) {
      toggle(false)
      return
    }

    const users = stagedUsers.map(u => parseUser(u))
    dispatch(batchUpdateUsers({ users, getToken, }))
    updateSubmitted(true)
  }

  const uploadBeacons = () => {
    if (!stagedBeacons || !stagedBeacons.length) {
      toggleBeacons(false)
      return
    }
    const beacons = stagedBeacons.map(b => parseBeacon(b))
    dispatch(batchUpdateBeacons({ beacons, getToken, }))
    updateSubmitted(true)
  }

  return (
          <div className="page-container-col">
              { selectedOrg &&
                (
                  <>
                    <FileReader
                        style={{ position: 'absolute', top: 62, right: 16, }}
                        handleDrop={ingestUsers}
                        issueCount={issueCount}
                        staged={stagedUsers}
                        title={'Users'}
                    />
                    <FileReader
                        style={{ position: 'absolute', top: 62, left: 216, }}
                        handleDrop={ingestBeacons}
                        issueCount={issueCount}
                        staged={stagedBeacons}
                        title={'Beacons'}
                    />
                  </>
                )
               }
              <div className="page-header">{'Users'}</div>
                <div style={{ alignItems: 'center', justifyContent: 'center', }}>
                    <div>Assign Org</div>
                    <OrgSelector
                    orgs={orgs}
                    selectOrg={selectOrg}
                    selectedOrg={selectedOrg}
                    submit={submit}
                    />
                </div>
                <div className="section-title">
                {
                !orgUsersLoading
                  ? <Table
                    dataSource={orgUsers}
                    columns={columns}
                    onChange={updateTable}
                    className='teams-table'
                />
                  : <Loading type='bars'/>
                }
                </div>
                <Modal title={'Upload Users'} visible={modal} onOk={uploadUsers} onCancel={() => toggle(false)}>
                    <div className="standard-text">{`${stagedUsers.length} users successfully processed`}</div>
                    <div className="standard-text">{`${issueCount} issues found`}</div>
                    <div className="section-title">{'Proceed with upload?'}</div>
                    { submitted && (
                        <CSVDownloader
                            data={stagedUsers}
                            filename={`User_Batch_${Date.now()}`}
                            type={'link'}
                        >
                            Download
                        </CSVDownloader>
                    )
                    }
                </Modal>
                <Modal title={'Upload Beacons'} visible={beaconModal} onOk={uploadBeacons} onCancel={() => toggleBeacons(false)}>
                    <div className="standard-text">{`${stagedBeacons.length} beacons successfully processed`}</div>
                    <div className="standard-text">{`${issueCount} issues found`}</div>
                    <div className="section-title">{'Proceed with upload?'}</div>
                    { submitted && (
                        <CSVDownloader
                            data={stagedBeacons}
                            filename={`Beacon_Batch_${Date.now()}`}
                            type={'link'}
                        >
                            Download
                        </CSVDownloader>
                    )
                    }
                </Modal>
          </div>)
}

export default connect(mapStateToProps)(Users)
