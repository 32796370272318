import { Howl } from 'howler'
import { message } from 'antd'
import { soundCheck } from '../../../utility/general'
export const playSound = (src) => {
  const sound = new Howl({ src, })
  sound.play()
}
export const slackMuteCancel = () => {
  message.error('action canceled')
}
export const isNewAlert = (user, interacted, alert, room) => {
  return soundCheck(user) && interacted && alert !== 'early_warning' && alert !== 'urgent' && !room?.disable
}
