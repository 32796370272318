
export function setOrgs (orgs) {
  return {
    type: 'SET_ORGS',
    payload: orgs,
  }
}
export function setTeams (teams) {
  return {
    type: 'SET_TEAMS',
    payload: teams,
  }
}
export function deleteTeam (team) {
  return {
    type: 'DELETE_TEAM',
    payload: team,
  }
}
export function addTeam (team) {
  return {
    type: 'ADD_TEAM',
    payload: team,
  }
}
export function assignBed (bed) {
  return {
    type: 'ASSIGN_BED',
    payload: bed,
  }
}
export function getRoomRowTeams (rooms) {
  return {
    type: 'GET_ROOM_ROW_TEAMS',
    payload: rooms,
  }
}
export function unassignBed (bed) {
  return {
    type: 'UNASSIGN_BED',
    payload: bed,
  }
}
export function unassignUser (user) {
  return {
    type: 'UNASSIGN_USER',
    payload: user,
  }
}
export function assignUser (user) {
  return {
    type: 'ASSIGN_USER',
    payload: user,
  }
}
export function createTeam (payload) {
  return {
    type: 'CREATE_TEAM',
    payload,
  }
}
export function setTeam (team) {
  return {
    type: 'SET_TEAM',
    payload: team,
  }
}
export function setReportPagination (pagination) {
  return {
    type: 'SET_REPORT_PAGINATION',
    payload: pagination,
  }
}
export function setHomePagination (homePagination) {
  return {
    type: 'SET_HOME_PAGINATION',
    payload: homePagination,
  }
}
export function setHomeFiltering (homeFiltering) {
  return {
    type: 'SET_HOME_FILTERING',
    payload: homeFiltering,
  }
}
export function setHomeSorting (homeSorting) {
  return {
    type: 'SET_HOME_SORTING',
    payload: homeSorting,
  }
}
export function setReportFiltering (filtering) {
  return {
    type: 'SET_REPORT_FILTERING',
    payload: filtering,
  }
}
export function setReportSorting (sorting) {
  return {
    type: 'SET_REPORT_SORTING',
    payload: sorting,
  }
}
export function updateRoom (payload) {
  return {
    type: 'UPDATE_ROOM',
    payload,
  }
}

export function updateNotification (notification) {
  return {
    type: 'UPDATE_NOTIFICATION',
    payload: notification,
  }
}

export function updateOrg (org) {
  return {
    type: 'UPDATE_ORG',
    payload: org,
  }
}

export function updateNotifications (notifications) {
  return {
    type: 'UPDATE_NOTIFICATIONS',
    payload: notifications,
  }
}

export function updateAnalyticsDates (dates) {
  return {
    type: 'ANALYTICS_DATES',
    payload: dates,
  }
}

export function getAnalytics (payload) {
  return {
    type: 'UPDATE_ANALYTICS',
    payload,
  }
}

export function setTraining (alertType) {
  return {
    type: 'SET_TRAINING',
    payload: alertType,
  }
}

export function updateTraining (payload) {
  return {
    type: 'UPDATE_TRAINING',
    payload,
  }
}

export function labelPotential (payload) {
  return {
    type: 'LABEL_EVENT',
    payload,
  }
}

export function setTimeline (payload) {
  return {
    type: 'SET_TIMELINE',
    payload,
  }
}

export function setTimelineLoading (payload) {
  return {
    type: 'TIMELINE_LOADING',
    payload,
  }
}

export function updateType (payload) {
  return {
    type: 'UPDATE_TYPE',
    payload,
  }
}

export function updateSeries (payload) {
  return {
    type: 'UPDATE_SERIES',
    payload,
  }
}

export function labelNotification (payload) {
  return {
    type: 'LABEL_NOTIFICATION',
    payload,
  }
}

export function swapTimeline (payload) {
  return {
    type: 'SWAP_TIMELINE',
    payload,
  }
}

export function prefetchTimeline (payload) {
  return {
    type: 'PREFETCH_TIMELINE',
    payload,
  }
}

export function getReport (payload) {
  return {
    type: 'UPDATE_REPORT',
    payload,
  }
}

export function getHomeNotifications (payload) {
  return {
    type: 'UPDATE_NOTIFS',
    payload,
  }
}

export function updateNotifDesc (payload) {
  return {
    type: 'UPDATE_NOTIF_DESC',
    payload,
  }
}

export function updateTimeline (payload) {
  return {
    type: 'UPDATE_TIMELINE',
    payload,
  }
}

export function fetchUser (payload) {
  return {
    type: 'UPDATE_USER',
    payload,
  }
}

export function fetchUnits (payload) {
  return {
    type: 'UPDATE_UNITS',
    payload,
  }
}

export function fetchUnit (payload) {
  return {
    type: 'UPDATE_UNIT',
    payload,
  }
}
export function updateProdAlert (payload) {
  return {
    type: 'UPDATE_SLACK',
    payload,
  }
}
export function stopPollingUnit () {
  return {
    type: 'STOP_POLLING_UNIT',
  }
}

export function promoteNotif (payload) {
  return {
    type: 'PROMOTE_NOTIF',
    payload,
  }
}

export function resolveNotif (payload) {
  return {
    type: 'RESOLVE_NOTIF',
    payload,
  }
}

export function rejectNotif (payload) {
  return {
    type: 'REJECT_NOTIF',
    payload,
  }
}

export function reportFalseNeg (payload) {
  return {
    type: 'FALSE_NEG',
    payload,
  }
}

export function reportPatientUpdate (payload) {
  return {
    type: 'PATIENT_UPDATE',
    payload,
  }
}

export function addNote (payload) {
  return {
    type: 'SUBMIT_NOTE',
    payload,
  }
}

export function getNotes (payload) {
  return {
    type: 'GET_NOTES',
    payload,
  }
}

export function updateBases (payload) {
  return {
    type: 'UPDATE_BASES',
    payload,
  }
}
export function loadOrgUsers (payload) {
  return {
    type: 'UPDATE_ORG_USERS',
    payload,
  }
}

export function updateBeaconAssignment (payload) {
  return {
    type: 'UPDATE_BEACON_ASSIGNMENT',
    payload,
  }
}

export function fetchNotif (payload) {
  return {
    type: 'UPDATE_NOTIF',
    payload,
  }
}

export function viewNote (payload) {
  return {
    type: 'VIEW_NOTE',
    payload,
  }
}

export function monitorAssignment (payload) {
  return {
    type: 'UPDATE_MONITOR_ASSIGNMENT',
    payload,
  }
}

export function loadInternalUsers (payload) {
  return {
    type: 'UPDATE_INTERNAL_USERS',
    payload,
  }
}

export function getMonitorAssignments (payload) {
  return {
    type: 'GET_MONITOR_ASSIGNMENTS',
    payload,
  }
}

export function updateUserFields (payload) {
  return {
    type: 'UPDATE_USER_FIELDS',
    payload,
  }
}

export function toggleFallRisk (payload) {
  return {
    type: 'UPDATE_FALL_RISK',
    payload,
  }
}

export function batchUpdateUsers (payload) {
  return {
    type: 'BATCH_UPDATE_USERS',
    payload,
  }
}

export function batchUpdateBeacons (payload) {
  return {
    type: 'BATCH_UPDATE_BEACONS',
    payload,
  }
}
