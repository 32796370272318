import React from 'react'
import colors from '../../styles/colors'
import ConfirmButton from '../ConfirmButton'

function CreateManualEvent ({ falseNeg, loading = null, room = {}, cardSize = 'extra-small', report, mainId = '', alert = false, flipped = false, }) {
  return (
    <div className="start-column">
      <div className="section-title">Report False Negative</div>
      <div>
        <ConfirmButton
          color={colors.lightRed}
          style={{ marginRight: 4, width: 50, }}
          title={'Fall'}
          prompt={'Are you sure you want to create false negative Fall alert?'}
          onPress={() => falseNeg(room.mainId, 'fall')}
          loading={loading && loading === `fall-${mainId}`}
          size={cardSize}
        />
        {
          !alert
            ? <ConfirmButton
              color={colors.yellow}
              style={{ marginRight: 4, width: 50, }}
              title={'MIB'}
              prompt={'Are you sure you want to create false negative LB alert?'}
              onPress={() => falseNeg(room.mainId, 'moving_in_bed')}
              loading={loading && loading === `moving_in_bed-${mainId}`}
              size={cardSize}
            />
            : null
        }

        <ConfirmButton
          color={colors.lightOrange}
          style={{ marginRight: 4, width: 50, }}
          title={'LB'}
          prompt={'Are you sure you want to create false negative OOB alert?'}
          onPress={() => falseNeg(room.mainId, 'out_of_bed')}
          loading={loading && loading === `out_of_bed-${mainId}`}
          size={cardSize}
        />
        <ConfirmButton
          color={colors.mediumPurple}
          style={{ marginRight: 4, width: 50, }}
          title={'OOC'}
          prompt={'Are you sure you want to create false negative OOC alert?'}
          onPress={() => falseNeg(room.mainId, 'out_of_chair')}
          loading={loading && loading === `out_of_chair-${mainId}`}
          size={cardSize}
        />
      </div>
      {
        flipped
          ? null
          : <div>        <div className="section-title">Report Patient Update</div>
            <div>
              <ConfirmButton
                color={colors.lightGreen}
                style={{ marginRight: 4, }}
                title={'Room'}
                prompt={"Are you sure you want to update patient to 'In Room'?"}
                onPress={() => report(room.mainId, 'room', room?.enable)}
                loading={loading && loading === 'room'}
                confirm={false}
                size={cardSize}
                trunc={true}
              />
              <ConfirmButton
                color={'#bcaaa4'}
                style={{ marginRight: 4, }}
                title={'Chair'}
                prompt={"Are you sure you want to update patient to 'In Chair'?"}
                onPress={() => report(room.mainId, 'chair', room?.enable)}
                loading={loading && loading === 'chair'}
                confirm={false}
                size={cardSize}
                trunc={true}
              />
              <ConfirmButton
                color={colors.lightBlue}
                style={{ marginRight: 4, }}
                title={'Bed'}
                prompt={"Are you sure you want to update patient to 'In Bed'?"}
                onPress={() => report(room.mainId, 'bed')}
                loading={loading && loading === 'bed'}
                confirm={false}
                size={cardSize}
                trunc={true}
              />
              <ConfirmButton
                color={colors.orange}
                style={{ marginRight: 4, }}
                title={'Staff'}
                prompt={"Are you sure you want to update patient to 'Staff in Room'?"}
                onPress={() => report(room.mainId, 'staff', room?.enable)}
                loading={loading && loading === 'staff'}
                confirm={false}
                size={cardSize}
                trunc={true}
              />
              <ConfirmButton
                color={colors.lightGray}
                style={{ marginRight: 4, }}
                title={'Empty'}
                prompt={"Are you sure you want to update patient to 'Out of Room'?"}
                onPress={() => report(room.mainId, 'empty', room?.enable)}
                loading={loading && loading === 'empty'}
                confirm={false}
                size={cardSize}
                trunc={true}
              />
            </div></div>
      }
    </div>
  )
}
export default CreateManualEvent
