const colors = {
  mediumIndigo: '#5c6bc0',
  darkGray: '#546e7a',
  lightRed: '#f48fb1',
  yellow: '#fff59d',
  yellowTransparent: '#fff59d0f',
  lightOrange: '#ffcc80',
  mediumOrange: '#f79630',
  lightGreen: '#a5d6a7',
  mediumPurple: '#cea8ff',
  lightPurple: '#a561ff',
  lightPurpleTransparent: '#a561ff0f',
  lightBlue: '#90caf9',
  lightBlueTransparent: '#90caf90f',
  orange: '#ffab91',
  lightGray: '#eeeeee',
  pureYellow: '#ffff00',
  pureYellowTransparent: '#ffff000f',
  blue: '#0000ff',
  blueTransparent: '#0000ff0f',
  purple: '#800080',
  purpleTransparent: '#8000800f',
}

export default colors
