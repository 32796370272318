import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Menu, Layout } from 'antd'
import { loadInternalUsers, getMonitorAssignments, updateBases, setTeams } from '../../state/actions'
import { User } from '../../state/types'
import { mapStateToProps } from './mapStateToProps'
import Monitors from './Monitors'
import Users from './Users'
import Teams from './Teams'
// const { SubMenu, } = Menu
const { Sider, Content, } = Layout

function Admin ({
  dispatch,
  monitorUsers,
  monitorAssignments,
  bases,
  assignmentsHash,
  team,
  teams,
  currentAssignments,
  setTeamLoading,
  deleteLoading,
  bedAssignmentLoading,
  userAssignmentLoading,
  orgUsersLoading,
}: any): React.ReactElement {
  const { getAccessTokenSilently: getToken, } = useAuth0()
  useEffect(() => {
    dispatch(loadInternalUsers({ getToken, }))
    dispatch(updateBases({ getToken, }))
    dispatch(setTeams({ getToken, }))
  }, [])
  const [selectedUser, selectUser,] = useState<User | null>(null)
  const [currentPage, selectPage,] = useState<string>('monitors')
  const [rerender, addRender,] = useState(0)
  useEffect(() => {
    addRender(rerender + 1)
  }, [monitorAssignments.length,])

  const handleClick = ({ key, }) => {
    selectPage(key)
  }

  const chooseUser = (user: User) => {
    if (!user || !user.mainId) return
    if (!selectedUser || user.mainId !== selectedUser.mainId) dispatch(getMonitorAssignments({ getToken, userId: user.mainId, }))
    selectUser(user)
  }
  const updateUsers = () => {
    dispatch(loadInternalUsers({ getToken, }))
  }
  return (
      <div style={{ marginBottom: 30, }}>
        <Layout>
        <Sider width={200} className="site-layout-background">
        <Menu
        mode="vertical"
        key={currentPage}
        title="subnav 1"
        defaultSelectedKeys={[currentPage,]}
        style={{ height: '100%', }}
        onClick={handleClick}
        >
          <Menu.Item key="monitors">Monitors</Menu.Item>
          <Menu.Item key="users">Users</Menu.Item>
          <Menu.Item key="teams">Teams</Menu.Item>
        </Menu>
        </Sider>
        <Content>
          <Monitors
            chooseUser={chooseUser}
            selectedUser={selectedUser}
            renderCheck={currentPage === 'monitors'}
          />
          <Users
            chooseUser={chooseUser}
            selectedUser={selectedUser}
            renderCheck={currentPage === 'users'}
          />
          <Teams
          renderCheck={currentPage === 'teams'}
          team={team} teams={teams} dispatch={dispatch} beds={bases} users={monitorUsers} currentAssignments={currentAssignments}
          setTeamLoading={setTeamLoading} deleteLoading={deleteLoading} bedAssignmentLoading={bedAssignmentLoading} userAssignmentLoading={userAssignmentLoading}
          orgUsersLoading={orgUsersLoading} updateUsers={updateUsers}
          />
        </Content>
        </Layout>
      </div>
  )
}

export default connect(mapStateToProps)(Admin)
